import { shape, number, string, func, arrayOf, bool, any } from "prop-types";

export const installationType = shape({
  installation_id: string.isRequired,
  zoho_id: string,
  zoho_project: string.isRequired,
  installation_date: string.isRequired,
  box_associated_date: string.isRequired,
  last_communication_timestamp: number,
  expiration_timestamp: number,
  confirmed_expiration_timestamp: number,
  weekly_activations: string,
  bms_label: string.isRequired,
  sc_label: string,
  sc_serial: string,
  gps_coordinates: shape({
    lat: number.isRequired,
    lon: number.isRequired,
  }),
  avg_power_7days: shape({
    production: number.isRequired,
    consumption: number.isRequired,
  }),
  client: shape({
    name: string.isRequired,
    zoho_id: string.isRequired,
  }).isRequired,
  contract: shape({
    code: string.isRequired,
    zoho_id: string.isRequired,
    finished_soon: bool,
    fully_paid: bool,
    finished: bool,
  }).isRequired,
  flags: arrayOf(string),
  chat_message: [],
  client_note: string,

});

export const boxType = shape({
  installation_id: string.isRequired,
  zoho_id: string,
  zoho_project: string.isRequired,
  installation_date: string.isRequired,
  box_associated_date: string.isRequired,
  last_communication_timestamp: number,
  expiration_timestamp: number,
  confirmed_expiration_timestamp: number,
  weekly_activations: string,
  bms_label: string.isRequired,
  sc_label: string,
  sc_serial: string,
  gps_coordinates: shape({
    lat: number.isRequired,
    lon: number.isRequired,
  }),
  avg_power_7days: shape({
    production: number.isRequired,
    consumption: number.isRequired,
  }),
  client: shape({
    name: string.isRequired,
    zoho_id: string.isRequired,
  }).isRequired,
  contract: shape({
    code: string.isRequired,
    zoho_id: string.isRequired,
    finished_soon: bool,
    fully_paid: bool,
    finished: bool,
  }).isRequired,
});

export const noteType = shape({
  note_id: string.isRequired,
  content: string.isRequired,
});

export const externalServiceType = shape({
  label: string.isRequired,
  color: string.isRequired,
  uri: func.isRequired,
  iframe: func,
  icon: any,
});

export const countryType = shape({
  label: string.isRequired,
  code: string.isRequired,
  flag: string.isRequired,
  aliases: arrayOf(string).isRequired,
  zoho_project: string.isRequired,
});
