import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import SearchIcon from "@material-ui/icons/Search";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import ListIcon from "@material-ui/icons/List";
import CloseIcon from "@material-ui/icons/Close";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import queryString from "query-string";
import {
  Map as LeafletMap,
  TileLayer,
  Marker,
  Circle,
  LayersControl,
  ZoomControl,
} from "react-leaflet";
import { latLngBounds, divIcon } from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { FormattedMessage as T } from "react-intl";
import { renderToString } from "react-dom/server";

import { initLeaflet, geolocationIcon } from "utils/leaflet";
import { makeCancelable } from "utils/methods";
import { boxType } from "types";
import { BoxFilter, LocalLink, CustomBadge } from "components";

import { BoxPopup, CustomPopup, BoxMarker } from "../";
import { InstallationsContext, RepositoryContext, CountryContext } from "contexts";
import CircularProgress from "@material-ui/core/CircularProgress";

initLeaflet();

const getBoxIcon = box =>
  divIcon({
    className: null,
    iconSize: [48, 48],
    iconAnchor: [24, 44],
    html: renderToString(<BoxMarker box={box} />),
  });

const styles = theme => ({
  mapWrapper: {
    height: "100%",
    flex: 1,
    display: "flex",
    position: "relative",
  },
  map: {
    flex: 1,
  },
  overMapContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    padding: "8px",
    zIndex: 1000,
    pointerEvents: "none",
  },
  overMapCard: {
    display: "inline-block",
    pointerEvents: "auto",
    width: "320px",
    maxWidth: "100%",
    position: "relative",
  },
  overMapButton: {
    pointerEvents: "auto",
    margin: "8px",
  },
  overMapContent: {
    "&:last-child": {
      paddingTop: "16px",
      paddingBottom: "16px",
    },
    marginRight: "24px",
  },
  filterCloseButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  count: {
    color: theme.palette.text.secondary,
  },
  popup: {
    margin: 0,
    padding: 0,
  },
  "@global .leaflet-popup-content": {
    margin: 0,
  },
});

const MAX_ZOOM_LEVEL = 18;
const FOCUS_ZOOM_LEVEL = 13;
const FILTER_POPUP_HEIGHT = 148;

const boxToLatLng = box => [box.gps_coordinates.lat, box.gps_coordinates.lon];

const boxesToBounds = boxes => {

  let bounds;

  if (boxes.length)
    bounds = latLngBounds(boxes.map(boxToLatLng));

  return bounds;

};

const geolocationOptions = {
  enableHighAccuracy: true,
};

const getCurrentPosition = options =>
  makeCancelable(
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    })
  );

class Map extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSearch: Boolean(props.filterInput),
      geolocation: null,
      bounds: null,
      manualMode: false,
      filteredBoxes: (this.props.filteredBoxes ? this.props.filteredBoxes : []),
      loading: true
    };
  }

  excludeBoxesWithoutLocation = (boxes) => {

    const output = [];

    boxes.map(box => {
      if (box.gps_coordinates)
        output.push(box);
    });

    return output;

  }

  componentDidMount = async () => {

    let filteredBoxes = [];

    const filter = [this.props.filterInput];
    let country = null;

    if (this.props.country && this.props.country.label) {
      country = this.props.country.code;
      filter.push(`country:${this.props.country.label}`);      
    }

    if (this.props.filterInput) {
      filteredBoxes = await this.props.applyExport(filter, 'box', 'asc');
      filteredBoxes = this.excludeBoxesWithoutLocation(filteredBoxes);
    } else {
      await this.props.loadInstallationsMap(country);
      filteredBoxes = this.props.filteredBoxes;
    }

    this.setState({filteredBoxes, loading: false});

    if (navigator.geolocation) {
      this.positionPromise = getCurrentPosition(geolocationOptions);
      this.positionPromise.promise.then(this.onGeolocationSuccess, this.onGeolocationError);
    }

    const { selectedBox } = this.props;

    if (selectedBox) {
      this.fitMapToBox(filteredBoxes.find(box => box.installation_id === selectedBox));
    } else {
      this.fitMapToInstallations(filteredBoxes);
    }

  }

  componentWillUnmount() {
    if (this.positionPromise) {
      this.positionPromise.cancel();
    }
    this.unmounted = true;
  }

  componentDidUpdate = async (prevProps) => {

    if (this.props.filterInput !== prevProps.filterInput || this.props.country !== prevProps.country) {

      this.setState({loading: true});

      let filteredBoxes = [];

      const filter = [this.props.filterInput];
      let country = null;

      if (this.props.country && this.props.country.label) {
        filter.push(`country:${this.props.country.label}`);
        country = this.props.country.code;
      }

      if (this.props.filterInput) {
        filteredBoxes = await this.props.applyExport(filter, 'box', 'asc');
        filteredBoxes = this.excludeBoxesWithoutLocation(filteredBoxes);
      } else {
        await this.props.loadInstallationsMap(country);
        filteredBoxes = this.props.filteredBoxes;
      }

      this.setState({filteredBoxes, loading: false});

      this.fitMapToInstallations(filteredBoxes);

    }

  }

  fitMapToInstallations = boxes => {
    if (!this.map)
      return null;
    
    const map = this.map.leafletElement;
    if (boxes.length && map) {
      const currentBounds = boxesToBounds(boxes);
      if (!map.getBounds().equals(currentBounds)) {
        this.moved = true;
        const currentZoom = map.getBoundsZoom(currentBounds);
        if (map.getZoom() !== currentZoom) {
          this.zoomed = true;
        }
        map.fitBounds(currentBounds, this.getBoundsOptions());
      }
    }
  };

  fitMapToBox = box => {
    if (box) {
      this.moved = true;
      this.zoomed = true;
      this.map.leafletElement.setView(boxToLatLng(box), FOCUS_ZOOM_LEVEL);
    }
  };

  onGeolocationSuccess = position => {
    const geolocation = {
      position: [position.coords.latitude, position.coords.longitude],
      accuracy: position.coords.accuracy,
    };
    this.setState({ geolocation });
  };

  onGeolocationError = error => {
    console.error(error);
  };

  centerOnGeolocation = () => {
    if (this.accuracyCircle) {
      this.map.leafletElement.fitBounds(this.accuracyCircle.leafletElement.getBounds());
    }
  };

  setSearch = newSearch => {
    const { location, history } = this.props;
    const search = queryString.parse(location.search);
    history.replace({
      search: queryString.stringify({
        ...search,
        ...newSearch,
      }),
    });
  };

  onFilterUpdate = filterInput => {
    this.setSearch({
      search: filterInput || undefined,
    });
  };

  showSearch = () => {
    if (!this.state.showSearch) {
      this.setState({
        showSearch: true,
      });
    }
  };

  onMapInput = e => {
    if (!e.hard) {
      this.userInputTriggered = true;
    }
    return true;
  };

  getNbInstallsOut = boxes => {
    if (this.map) {
      const bounds = this.map.leafletElement.getBounds();
      // return boxes.filter(box => !bounds.contains(boxToLatLng(box))).length;
      return boxes.length;
    }
    return 0;
  };

  cancelManualMode = () => {
    this.map.leafletElement.closePopup();
    this.fitMapToInstallations(this.state.filteredBoxes);
    this.setState({
      manualMode: false,
      nbInstallsOut: 0,
    });
  };

  selectBox = selectedBox => () => {
    this.setSearch({
      box: selectedBox && selectedBox.installation_id,
    });
  };

  initPopup = popup => {
    this.map.leafletElement.addLayer(popup);
  };

  closePopup = selectedBox => () => {
    setTimeout(() => {
      if (!this.unmounted) {
        if (selectedBox.installation_id === this.props.selectedBox) {
          this.selectBox()();
        }
      }
    });
  };

  setMapRef = mapRef => {
    if (!this.map) {
      this.map = mapRef;
    }
  };

  onFilterWindowClose = e => {
    this.setState({
      showSearch: false,
    });
    this.setSearch({
      search: undefined,
    });
  };

  onUserInput = inputType => () => {
    if (!this[inputType]) {
      this.setState({
        manualMode: true,
        nbInstallsOut: this.getNbInstallsOut(this.state.filteredBoxes),
      });
    } else {
      delete this[inputType];
    }
  };

  getBoundsOptions = () => {
    const { showSearch } = this.state;
    return {
      paddingTopLeft: [0, showSearch ? FILTER_POPUP_HEIGHT : 0],
    };
  };

  render() {
    const { classes, filterInput, selectedBox: selectedBoxId } = this.props;
    const { showSearch, geolocation, manualMode } = this.state;

    const filteredBoxes = this.state.filteredBoxes;

    const nbInstallsOut = this.getNbInstallsOut(filteredBoxes);

    const installationLink = encodeURI(`/${filterInput ? `?search=${filterInput}` : ""}`);
    const selectedBox =
      selectedBoxId && filteredBoxes.find(box => box.installation_id === selectedBoxId);

    return (
      <div style={{position:'relative'}} className={classes.mapWrapper}>
        <LeafletMap
          ref={(map) => { this.setMapRef(map) }}
          center={[0, 0]}
          zoom={1}
          maxZoom={MAX_ZOOM_LEVEL}
          padding={[16, 16]}
          zoomControl={false}
          boundsOptions={this.getBoundsOptions()}
          className={classes.map}
          onZoomend={this.onUserInput("zoomed")}
          onMoveend={this.onUserInput("moved")}
        >
          <LayersControl position="topright">
            <LayersControl.BaseLayer name="OpenStreetMap" checked>
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer
                attribution='&amp;copy <a href="http://www.esri.com/">Esri</a>'
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          <ZoomControl position="topright" />
          <MarkerClusterGroup maxClusterRadius={40}>
            {filteredBoxes.map(box => (
              <Marker
                key={box.installation_id}
                icon={getBoxIcon(box)}
                position={boxToLatLng(box)}
                onclick={this.selectBox(box)}
              />
            ))}
          </MarkerClusterGroup>
          {geolocation && (
            <Fragment>
              <Marker position={geolocation.position} icon={geolocationIcon} />
              <Circle
                center={geolocation.position}
                radius={geolocation.accuracy}
                stroke={false}
                fillOpacity={0.1}
                ref={elt => (this.accuracyCircle = elt)}
              />
            </Fragment>
          )}
          {selectedBox && (
            <CustomPopup
              className={classes.popup}
              position={boxToLatLng(selectedBox)}
              offset={[0, -48]}
              onInit={this.initPopup}
              onClose={this.closePopup(selectedBox)}
              autoPanPaddingTopLeft={[0, showSearch ? FILTER_POPUP_HEIGHT : 0]}
              context={this.context}
            >
              <BoxPopup box={selectedBox} />
            </CustomPopup>
          )}
        </LeafletMap>
        { this.state.loading && <div style={{position:'absolute', display:'flex', justifyContent:'center', alignItems:'center', zIndex:1000, height:'100%', width:'100%', backgroundColor:'rgba(0, 0, 0, 0.5)'}}>
          <div><CircularProgress size={100} /></div>
        </div>}
        <div className={classes.overMapContainer}>
          {showSearch ? (
            <Card className={classes.overMapCard}>
              <CardContent className={classes.overMapContent}>
                <BoxFilter defaultValue={filterInput} disabled={this.state.loading} onUpdate={this.onFilterUpdate} />
                <span className={classes.count}>
                  <T id="installationTotalCount" values={{ count: filteredBoxes.length }} />
                  <Tooltip title={<T id="showOnList" />}>
                    <IconButton component={LocalLink} to={installationLink}>
                      <ListIcon />
                    </IconButton>
                  </Tooltip>
                  {manualMode && (
                    <Tooltip title={<T id="fitMapToInstallations" />}>
                      <IconButton onClick={this.cancelManualMode}>
                        <CustomBadge
                          disabled={!nbInstallsOut}
                          badgeContent={nbInstallsOut}
                          color="secondary"
                        >
                          <AspectRatioIcon />
                        </CustomBadge>
                      </IconButton>
                    </Tooltip>
                  )}
                </span>
                <IconButton
                  onClick={this.onFilterWindowClose}
                  className={classes.filterCloseButton}
                >
                  <CloseIcon />
                </IconButton>
              </CardContent>
            </Card>
          ) : (
            <Fragment>
              <Fab
                size="small"
                color="primary"
                aria-label="show-search"
                onClick={this.showSearch}
                className={classes.overMapButton}
              >
                <SearchIcon />
              </Fab>
              {geolocation && (
                <Fab
                  size="small"
                  color="default"
                  aria-label="center-on-geolocation"
                  onClick={this.centerOnGeolocation}
                  className={classes.overMapButton}
                >
                  <MyLocationIcon />
                </Fab>
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

Map.propTypes = {
  filteredBoxes: PropTypes.arrayOf(boxType).isRequired,
  filterInput: PropTypes.string.isRequired,
  selectedBox: PropTypes.string,
};

Map.contextTypes = {
  router: PropTypes.object,
  intl: PropTypes.object,
};

const MapWrapper = props => (
  <CountryContext.Consumer>
    {country => (
      <RepositoryContext.Consumer>{({applyExport}) => (
        <InstallationsContext.Consumer>
          {({ filterInput, installationsMap, loadInstallationsMap }) => {
            const search = queryString.parse(props.location.search);
            return (
              <Map
                filterInput={search.search || filterInput}
                selectedBox={search.box}
                filteredBoxes={installationsMap}
                loadInstallationsMap={loadInstallationsMap}
                applyExport={applyExport}
                country={country}
                {...props}
              />
            );
          }}
        </InstallationsContext.Consumer>)}
      </RepositoryContext.Consumer>
    )}
  </CountryContext.Consumer>
);

export default compose(withRouter, withStyles(styles))(MapWrapper);
