import uuidv4 from "uuid/v4";

export default class ClientChat {

  constructor () {

    this._id = `urn.qotto.client_chat:${uuidv4()}`;
    this.type = 'client_chat';
    this.chat_id = `urn.qotto.chat:${uuidv4()}`;
    this.client_id = '';

  }

  pattern = () => {

    // The pattern is used for the updating action.
    // Only the fields in the following array are accepted for updating

    return [
      'chat_id',
      'client_id'
    ];

  }

  setClientId = (value) => {
    this.client_id = value;
  }

  get = () =>
    ({
      _id: this._id,
      type: this.type,
      chat_id: this.chat_id,
      client_id: this.client_id
    })

}