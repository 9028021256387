import React from "react";
import uuidv4 from "uuid/v4";
import { FormattedMessage as T } from "react-intl";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { withStyles } from "@material-ui/core/styles";

import { LoadingButton } from "components";

import { ClientCallbackRepository } from "contexts/Repository/adaptor/pouchDB/clientCallbackRepository";
import { RepositoryContext } from "contexts";

const styles = {
  form: {
    position: "relative",
    marginTop: "8px",
    marginBottom: "24px",
  },
  inputLine: {
    display: "flex",
    alignItems: "flex-end",
  },
  dateInput: {
    minWidth: "203px",
  },
  submitLine: {
    marginTop: "8px",
  },
};

class ClientCallback extends React.PureComponent {
  state = {};

  static getDerivedStateFromProps = (props, state) => {

    let defaultCallbackDate;

    if (!state.defaultCallbackDate)
      defaultCallbackDate = props.box.client_callback;
    else
      defaultCallbackDate = state.defaultCallbackDate

    if (defaultCallbackDate !== state.defaultCallbackDate) {
      return {
        defaultCallbackDate,
        callback_date: props.box.client_callback,
        loading: false,
      };
    }

    return state;

  };

  handleChange = e => {
    this.setState({
      callback_date: e.target.value,
    });
  };

  cancelChanges = () => {
    this.setState({
      callback_date: this.state.defaultCallbackDate,
    });
  };

  onSubmit = async e => {

    e.preventDefault();

    this.setState({ loading: true });

    const { client } = this.props;
    const { callback_date, defaultCallbackDate } = this.state;

    const hasChanged = callback_date !== defaultCallbackDate;

    if (!hasChanged)
      return null;

    const clientCallbackRepo = new ClientCallbackRepository(this.props.repo.instance.remote);

    const clientCallback = await clientCallbackRepo.findOne({client_id: client.zoho_id});

    if (clientCallback)
      await clientCallbackRepo.update(clientCallback._id, { callback_date });
    else
      await clientCallbackRepo.create(client.zoho_id, callback_date);

    this.setState({ loading: false, defaultCallbackDate: callback_date });

    return false;

  };

  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    let callback_date = null;
    let defaultCallbackDate = null;

    if (this.state.callback_date)
      callback_date = new Date(this.state.callback_date).toISOString().split('T')[0];

    if (this.state.defaultCallbackDate)
      defaultCallbackDate = new Date(this.state.defaultCallbackDate).toISOString().split('T')[0];

    const hasChanged = callback_date !== defaultCallbackDate;

    const now = new Date().toISOString().substr(0, 10);

    return (
      <form className={classes.form} onSubmit={this.onSubmit}>
        <div className={classes.inputLine}>
          <TextField
            label={<T id="callbackDate" />}
            name="callbackDate"
            type="date"
            inputProps={{
              min: now,
            }}
            value={callback_date || ""}
            onChange={this.handleChange}
            className={classes.dateInput}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        {hasChanged && (
          <div className={classes.submitLine}>
            <LoadingButton
              type="submit"
              size="small"
              loading={loading}
              disabled={loading}
              className={classes.saveButton}
              variant="contained"
              color="primary"
            >
              <SaveIcon /> <T id="save" />
            </LoadingButton>{" "}
            <Button size="small" disabled={loading} onClick={this.cancelChanges}>
              <T id="cancel" />
            </Button>
          </div>
        )}
      </form>
    );
  }
}

ClientCallback.propTypes = {
  client: PropTypes.shape({
    zoho_id: PropTypes.string.isRequired,
  }),
};

const ClientCallbackWrapper = props => (
  <RepositoryContext.Consumer>
    {( repo ) => (
      <ClientCallback
        repo={repo}
        {...props}
      />
    )}
  </RepositoryContext.Consumer>
);

export default withStyles(styles)(ClientCallbackWrapper);
