export class AllCustomerMetadataRepository {

  constructor(instance) {
    this.database = instance;
  }

  findById = async (customerId) => {

    const options = {
      key: customerId
    };

    return this.database.query('main/allCustomerMetadata', options);
    
  }

  findByIds = async (customerIds) => {

    const options = {
      keys: customerIds
    };

    const output = await this.database.query('main/allCustomerMetadata', options);

    return output.rows;

  }

  findOne = async (customerId) => {

    const options = {
      key: customerId
    };

    const queryResponse = await this.database.query('main/allCustomerMetadata', options);

    if (!queryResponse.rows)
      return undefined;

    return queryResponse.find(row => row.type === 'last_client_call');

  }

  findAll = async () =>
    this.fetchPageByPage();

  fetchPageByPage = async () => {

    const output = [];
    let options = { limit: 500 };

    const getData = async (resolve, reject) => {

      if ( output.length > 0 )
        options = {
          ...options,
          skip: 1,
          startkey: output[ output.length - 1 ].customer
        };

      const response = await this.database.query('main/allCustomerMetadata', options);

      if (response.rows.length > 0) {

        await Promise.all(response.rows.map(async (doc) => {
          output.push({...doc.value, _id: doc.id, customer: doc.key});
        }));

        await getData(resolve, reject);

      } else {

        return resolve(output);

      }

    }

    return new Promise((r, j) => getData(r, j));

  }

}