import React from "react";
import { LocalRedirect } from "components";

const DeviceOldIdToNewId = ({ match }) => {
  const { contract_code, map } = match.params;
  if (map) {
    return <LocalRedirect to={`/${contract_code}`} />;
  }
  return <LocalRedirect to={`/${contract_code}`} />;
};

export default DeviceOldIdToNewId;
