import React from "react";
import { withRouter } from "react-router-dom";
import { logEvent } from 'utils/googleAnalytics';

import { getFromPath } from "countries";

const CountryContext = React.createContext({});

export default CountryContext;

class Provider extends React.Component {

  state = {};

  static getDerivedStateFromProps = ({ location }, state) => {

    const country = getFromPath(location.pathname) || null;

    const previousCountry = (state.country && state.country.label) ? state.country.label : 'world';
    const currentCountry = (country && country.label) ? country.label : 'world';
    
    if (previousCountry !== currentCountry) {

      logEvent('COUNTRY', 'SWITCH COUNTRY', currentCountry);
      return { country };

    }

  };

  render() {
    return (
      <CountryContext.Provider value={this.state.country}>
        {this.props.children}
      </CountryContext.Provider>
    );
  }
}

Provider = withRouter(Provider);

export { Provider };
