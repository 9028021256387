import ClientCallback from "contexts/Repository/entity/client_callback";

export class ClientCallbackRepository {

  constructor(instance) {
    this.database = instance;
  }

  findOneById = _id =>
    this.database.get(_id);

  findOne = async (payload) => {

    const response = await this.database.find({
      selector: {
        type: "client_callback",
        ...payload
      }
    });

    if (response && response.docs && response.docs[0])
      return response.docs[0];

    return false;

  }

  findAll = async (sortColumn, sortOrder, limit, bookmark) => {

    const response = await this.database.find({
      selector: {
        type: "client_callback",
        callback_date: {
          "$gt": null,
          "$ne": ""
        }
      },
      sort: [{[sortColumn]: sortOrder}],
      bookmark: (bookmark ? bookmark : null),
      limit
    });

    if (response.docs)
      return { data: response.docs, bookmark: response.bookmark };

    return { data: [], bookmark: null };

  }

  create = (clientId, callbackDate) => {

    const newClientCallback = new ClientCallback();

    newClientCallback.setClientId(clientId);
    newClientCallback.setCallbackDate(callbackDate);

    return this.database.put(newClientCallback);

  }

  update = async (_id, payload) => {

    // CHECK IF THE _ID EXISTS
    // ---

    const clientCallbackById = await this.findOneById(_id);

    if (!clientCallbackById)
      throw Error('unknown _id');

    // CHECK IF THE PAYLOAD PROPERTIES ARE CORRECT
    // ---

    let clientCallbackPattern = new ClientCallback();
    clientCallbackPattern = clientCallbackPattern.pattern();

    let checkProperties = true;

    for (const [key] of Object.entries(payload)) {

      if (!clientCallbackPattern.includes(key))
        checkProperties = false;

    }

    if (!checkProperties)
      throw Error('property error');

    // UPDATE IN THE DATABASE
    // ---

    return this.database.put({ ...clientCallbackById, ...payload });

  }

  delete = (_id) => {

  }

}