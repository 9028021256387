import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { FormattedMessage as T } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  BoxActivationRate,
  BoxStatus,
  ExternalIconButton,
  BoxBMSBrand,
  BoxClient,
  BoxContractState,
  BoxDate,
  BoxDateTime,
  BoxDailyConsumption,
  BoxDailyProduction,
  BoxConsumptionPercentage,
  BoxFinancialCategory,
  BoxFollowupCategory,
  BoxInstallationDays,
  BoxAmountToRecover,
  LocalLink,
  BoxHub
} from "components";
import { boxType } from "types";
import { gateway, kibana, zohoContract } from "utils/externalServices";
import boxProperties from "data/installations/properties";

import { ColumnsSelectDialog } from "../";

const styles = theme => ({
  item: {
    lineHeight:'5px',
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey["100"],
    },
    "&:hover $buttonsWrapper": {
      visibility: "visible",
      transitionDelay: "1ms",
    },
  },
  bodyCell: {
    padding: 0,
    lineHeight:'13px',
    fontSize:12
  },
  headerCell: {
    padding: "4px 12px",
    lineHeight:'0.775rem',
    fontSize:'0.7rem',
    color:'grey'
  },
  selectColumnsCell: {
    padding: "4px 12px",
    textAlign: "right",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    display: "flex",
    height: "47px",
    padding: "4px 12px",
    alignItems: "center",
  },
  buttonsCell: {
    padding: "0 !important",
    textAlign: "right",
  },
  buttonsWrapper: {
    whiteSpace: "nowrap",
    visibility: "hidden",
    transition: "0s visibility",
  },
  button: {
    margin: "0 4px",
  },
});

const columns = {
  status: {
    label: "status",
    title: <T id="status" />,
    sortable: false,
    render: box => <BoxStatus box={box} icon />,
  },
  box: {
    label: "box",
    title: "Code",
    sortable: true,
    render: box => <strong>{box.contract.code}</strong>,
  },
  client: {
    label: "client",
    title: <T id="client" />,
    sortable: true,
    render: box => <BoxClient box={box} />,
  },
  type: {
    label: "bmsBrand",
    title: <T id="bmsBrand" />,
    sortable: false,
    render: box => box.kit ? <BoxBMSBrand box={box}/> : '',
  },
  expiration: {
    label: "expiration",
    title: <T id="expiration" />,
    sortable: true,
    render: box => <BoxDateTime value={box.expiration_timestamp} />,
  },
  dailyConsumption: {
    label: "dailyConsumption",
    title: <T id="dailyConsumption" />,
    sortable: true,
    render: box => <BoxDailyConsumption box={box} />,
  },
  dailyProduction: {
    label: "dailyProduction",
    title: <T id="dailyProduction" />,
    sortable: true,
    render: box => <BoxDailyProduction box={box} />,
  },
  consumptionPercentage: {
    label: "consumptionPercentage",
    title: <T id="consumptionPercentage" />,
    sortable: false,
    render: box => <BoxConsumptionPercentage box={box} />,
  },
  lastCommunication: {
    label: "lastCommunication",
    title: <T id="lastCommunication" />,
    sortable: true,
    render: box => <BoxDateTime value={box.last_communication_timestamp} />,
  },
  activationRate: {
    label: "activationRate",
    title: <T id="activationRate" />,
    sortable: false,
    render: box => <BoxActivationRate box={box} />,
  },
  lastClientCall: {
    label: "lastClientCall",
    title: <T id="lastCallDate" />,
    sortable: false,
    render: box => <BoxDateTime value={box.client.last_call} />,
  },
  clientCallback: {
    label: "clientCallback",
    title: <T id="callbackDate" />,
    sortable: false,
    render: ({ client }) => <BoxDate value={client.callback_date} />,
  },
  financialCategory: {
    label: "financialCategory",
    title: <T id="financialCategory" />,
    sortable: true,
    render: box => <BoxFinancialCategory box={box} />,
  },
  followupCategory: {
    label: "followupCategory",
    title: <T id="followupCategory" />,
    sortable: true,
    render: box => <BoxFollowupCategory box={box} />,
  },
  installationDate: {
    label: "installationDate",
    title: <T id="installationDate" />,
    sortable: true,
    render: box => <BoxDateTime value={boxProperties.installationDate.getValue(box)} />,
  },
  firstActivationDate: {
    label: "firstActivationDate",
    title: <T id="firstActivationDate" />,
    sortable: true,
    render: box => <BoxDateTime value={boxProperties.firstActivationDate.getValue(box)} />,
  },
  installationDays: {
    label: "installationDays",
    title: <T id="installationDays" />,
    sortable: true,
    render: box => <BoxInstallationDays box={box} />,
  },
  cheatScore: {
    label: "cheatScore",
    title: <T id="cheatScore" />,
    sortable: true,
    render: box => <span>{box.cheat_score}</span>,
  },
  amountToRecover: {
    label: "amountToRecover",
    title: <T id="amountToRecover" />,
    sortable: true,
    render: box => <BoxAmountToRecover box={box} />,
  },
  contractState: {
    label: "contractState",
    title: <T id="contractState" />,
    sortable: false,
    render: box => <BoxContractState box={box} />,
  },
  hub: {
    label: "hub",
    title: <T id="hub" />,
    sortable: false,
    render: box => <BoxHub box={box} />,
  },
};

class BoxTable extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      loading: false
    };

  }

  componentDidMount() {

    if (this.props.loading !== this.state.loading)
      this.setState({loading: this.props.loading});

  }

  componentDidUpdate(prevProps) {

    if (this.props.loading !== this.state.loading)
      this.setState({loading: this.props.loading});

  }

  sort = label => () => {
    this.props.sort(label);
  };

  renderRow = (box) => {

    const { classes, selectedColumns } = this.props;
    const availableColumns = selectedColumns.filter(column => columns[column]);

    return (
      <TableRow key={box.installation_id} className={classes.item}>
        {availableColumns.map(column => (
          <TableCell key={column} className={classes.bodyCell}>
            <LocalLink to={`/${box.contract.code}`} className={classes.link}>
              <div>{columns[column].render(box)}</div>
            </LocalLink>
          </TableCell>
        ))}
        <TableCell className={classes.buttonsCell}>
          <div className={classes.buttonsWrapper}>
            {(box.sc_serial) ?
            <ExternalIconButton service={gateway} args={{ scSerial: box.sc_serial  }} /> : ''}
            {(box.sc_serial)?
            <ExternalIconButton service={kibana} args={{ scSerial: box.sc_serial }} /> : ''}
            <ExternalIconButton
              service={zohoContract}
              args={{
                id: box.contract.zoho_id,
                project: box.zoho_project,
              }}
            />
          </div>
        </TableCell>
      </TableRow>
    );

  }

  render() {
    const { boxes, sortProperty, order, classes, selectedColumns } = this.props;
    const availableColumns = selectedColumns.filter(column => columns[column]);
    return (
      <div style={{display:'flex', flexDirection:'column'}}>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                {availableColumns.map(column => (
                  <TableCell
                    className={classes.headerCell}
                    key={column}
                    sortDirection={sortProperty === column ? order : false}
                  >
                    {columns[column].sortable ? (
                      <TableSortLabel
                        active={sortProperty === column}
                        direction={order}
                        onClick={this.sort(column)}
                      >
                        {columns[column].title}
                      </TableSortLabel>
                    ) : (
                      columns[column].title
                    )}
                  </TableCell>
                ))}
                <TableCell className={classes.selectColumnsCell}>
                  <ColumnsSelectDialog
                    columns={columns}
                    selectedColumns={selectedColumns}
                    onColumnsSelect={this.props.selectColumns}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{boxes.map(box => this.renderRow(box))}</TableBody>
          </Table>
        </div>
        { !this.state.loading && boxes.length === 0 ? <div style={{padding:35, textAlign:'center', fontWeight:'bold', color:'#999', fontSize:16, textTransform:'uppercase', height:50}}>aucune installation</div> : null }
        { this.state.loading ? <div style={{padding:35, textAlign:'center', height:50}}><CircularProgress /></div> : null }
      </div>
    );
  }
}

BoxTable.propTypes = {
  boxes: PropTypes.arrayOf(boxType).isRequired,
  sortProperty: PropTypes.string.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  sort: PropTypes.func.isRequired,
  selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectColumns: PropTypes.func.isRequired,
  repo: PropTypes.object
};

export default withStyles(styles)(BoxTable);
