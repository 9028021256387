import React from "react";
import jscookie from "js-cookie";
import jwt from "jsonwebtoken";
import * as Sentry from "@sentry/react";
import { getConfigValue } from "utils/envConfig";

const JWT_PUBLIC_KEY = getConfigValue('REACT_APP_JWT_PUBLIC_KEY');
const TOKEN_COOKIE_NAME = getConfigValue('REACT_APP_TOKEN_COOKIE_NAME');
const COOKIE_DOMAIN = getConfigValue('REACT_APP_COOKIE_DOMAIN');
const LOGIN_API_URL = getConfigValue('REACT_APP_LOGIN_API_URL');
const LOGIN_FRONTEND_URL = getConfigValue('REACT_APP_LOGIN_FRONTEND_URL');
const DEFAULT_LOCALE = getConfigValue('REACT_APP_DEFAULT_LOCALE');

const RENEW_INTERVAL_DELAY = 3600 * 1000; // 1 hour

let getUserFromMock = null;

// Overide the cookie if a src/auth.json file exists
try {
  getUserFromMock = require('auth.json');
} catch (e) {}

const verifyToken = token => {
  try {

    if (getUserFromMock)
      return true;

    return token && jwt.verify(token, JWT_PUBLIC_KEY);

  } catch (error) {

    throw Error("FORBIDDEN");

  }
};

const getUserFromToken = token => {

  if (getUserFromMock)
    return getUserFromMock;

  try {
    return jwt.decode(token);
  } catch (error) {
    console.warn(error);
    return null;
  }
};

const getLoginUrl = pathname => {
  if (pathname && document && document.location) {
    return `${LOGIN_FRONTEND_URL}/?r=${encodeURI(document.location.origin + pathname)}`;
  }
  return LOGIN_FRONTEND_URL;
};

const getLogoutUrl = pathname => {
  if (pathname && document && document.location) {
    return `${LOGIN_FRONTEND_URL}/?r=${encodeURI(document.location.origin + pathname)}`;
  }
  return LOGIN_FRONTEND_URL;
};

const UserContext = React.createContext();

export default UserContext;

const defaultPreferences = {
  timezone: null,
  preferedColumns: ["status", "box", "client", "expiration", "dailyConsumption"],
  language: DEFAULT_LOCALE,
};

const getPreferences = () => {
  const preferences = localStorage.getItem("preferences");
  if (preferences) {
    try {
      return JSON.parse(preferences);
    } catch (error) {
      return defaultPreferences;
    }
  }
  return defaultPreferences;
};

const setPreferences = preferences => {
  localStorage.setItem("preferences", JSON.stringify(preferences));
};

class Provider extends React.Component {

  constructor (props) {

    super(props);

    const token = jscookie.get(TOKEN_COOKIE_NAME);
    const userFromToken = getUserFromToken(token);

    if (userFromToken && userFromToken.uuid && userFromToken.metadata && userFromToken.metadata.displayed_name) {

      Sentry.setUser({
        id: userFromToken.uuid,
        username: userFromToken.metadata.displayed_name,
      });

    }

    this.state = {
      token,
      user: verifyToken(token) ? userFromToken : null,
      preferences: getPreferences(),
      setPreference: this.setPreference,
      clearSession: this.clearSession,
      getLoginUrl,
      getLogoutUrl,
    };
    
  }

  renewSession = () => {
    const { user } = this.state;

    if (getUserFromMock)
      return null;

    if (user !== null) {
      fetch(LOGIN_API_URL + "/renew-session", {
        method: "POST",
        credentials: "include",
      }).catch(console.warn);
    } else {
      clearInterval(this.renewInterval);
      delete this.renewInterval;
    }
  };

  clearSession = () => {
    jscookie.remove(TOKEN_COOKIE_NAME, {
      domain: COOKIE_DOMAIN,
    });
  };

  componentDidMount() {
    this.renewSession();
    this.renewInterval = setInterval(this.renewSession, RENEW_INTERVAL_DELAY);
  }

  componentWillUnmount() {
    if (this.renewInterval) {
      clearInterval(this.renewInterval);
    }
  }

  setPreference = key => value => {
    const { preferences } = this.state;
    const newPreferences = {
      ...preferences,
      [key]: value,
    };
    this.setState({
      preferences: newPreferences,
    });
    setPreferences(newPreferences);
  };

  render() {
    const { children } = this.props;
    return <UserContext.Provider value={this.state}>{children}</UserContext.Provider>;
  }
}

export { Provider };
