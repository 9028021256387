import React from "react";
import { Route, Switch, NavLink } from "react-router-dom";
import { withStyles, createStyles, WithStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FormattedMessage as T } from "react-intl";

import { CountryFlag } from "components";
import countries, { addToPath } from "countries";
import routes from "routes";

const capitalize = (str: string): string => str[0].toUpperCase() + str.substring(1);

const styles = createStyles({
  button: {
    boxSizing: "content-box",
    lineHeight: "2em",
    padding: "8px 0",
  },
  disabled: {
    cursor: "not-allowed",
  },
  enabled: {
    cursor: "pointer",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.05)",
    },
  },
  menuItem: {
    display: "flex",
  },
});

interface CountryTextProps {
  country?: Country;
}

const CountryText = ({ country }: CountryTextProps) => (
  <React.Fragment>
    &nbsp;{country ? capitalize(country.label) : <T id="world" />}
    <span style={{ flex: 1 }}>&nbsp;</span>
    <CountryFlag country={country} />
    &nbsp;
  </React.Fragment>
);

interface CountrySelectProps extends WithStyles<typeof styles> {
  selectedCountry: Country;
  location: Location;
  disabled: boolean;
}

interface ActualCountrySelectState {
  anchorEl?: HTMLElement | null;
}

class ActualCountrySelect extends React.PureComponent<
  CountrySelectProps,
  ActualCountrySelectState
> {
  state: ActualCountrySelectState = {
    anchorEl: null,
  };

  handleClick = (event: { currentTarget: HTMLElement }): void => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { selectedCountry, location, disabled, classes } = this.props;
    const { anchorEl } = this.state;

    return (
      <React.Fragment>
        <span
          onClick={disabled ? undefined : this.handleClick}
          className={`${classes.button} ${disabled ? classes.disabled : classes.enabled}`}
        >
          <CountryText country={selectedCountry} />
        </span>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <MenuItem
            className={classes.menuItem}
            component={props => (
              <NavLink {...props} to={addToPath(location.pathname + location.search)} />
            )}
            onClick={this.handleClose}
          >
            <CountryText />
          </MenuItem>
          {countries.map(country => (
            <MenuItem
              key={country.label}
              className={classes.menuItem}
              component={props => (
                <NavLink {...props} to={addToPath(location.pathname + location.search, country)} />
              )}
              onClick={this.handleClose}
            >
              <CountryText country={country} />
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }
}

const renderCountrySelect = (
  route: Route,
  props: CountrySelectProps,
  url: string = ""
): React.ReactNode[] => [
  <Route
    key={url + route.path}
    exact
    path={url + route.path}
    render={() => <ActualCountrySelect {...props} />}
  />,
  ...(route.children || []).map((child: Route) =>
    renderCountrySelect(child, props, url + route.path)
  ),
];

const CountrySelect = (props: CountrySelectProps) => (
  <Switch>{renderCountrySelect(routes, props)}</Switch>
);

export default withStyles(styles)(CountrySelect);
