import en from "./en";
import fr from "./fr";
import { getConfigValue } from "utils/envConfig";

const DEFAULT_LOCALE = getConfigValue('REACT_APP_DEFAULT_LOCALE');

const messages = {
  en: {
    ...fr,
    ...en,
  },
  fr: {
    ...en,
    ...fr,
  },
};

const getMessages = language => {
  return messages[language || DEFAULT_LOCALE];
};

export const availableLanguages = Object.keys(messages);

export default getMessages;
