import jwt from "jsonwebtoken";
import jscookie from "js-cookie";
import { getConfigValue } from "utils/envConfig";

const BACKEND_API_ENDPOINT = getConfigValue('REACT_APP_BACKEND_API_ENDPOINT');
const REMOTE_SERVER = getConfigValue('REACT_APP_COUCHDB_SERVER');
const TOKEN_COOKIE_NAME = getConfigValue('REACT_APP_TOKEN_COOKIE_NAME');
const JWT_PUBLIC_KEY = getConfigValue('REACT_APP_JWT_PUBLIC_KEY');

// Overide the cookie if a src/auth.json file exists
let getUserFromMock = null;
try {
  getUserFromMock = require('auth.json');
} catch (e) {}

const verifyToken = token => {

  if (getUserFromMock)
    return true;

  return token && jwt.verify(token, JWT_PUBLIC_KEY);

};

const getUserFromToken = token => {

  if (getUserFromMock)
    return getUserFromMock;

  return jwt.decode(token);

};

const safeFetch = (...args) =>
  fetch(...args).then(response => {
    const { ok, status } = response;
    if (!ok) {
      throw Error(`Request failed with status ${status}`);
    }
    return response;
  });

const logIn = (name, password) => {

  return safeFetch(REMOTE_SERVER + "_session", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, password }),
  });

}
  

const logOut = () => {

  localStorage.removeItem('couchdb-username');
  localStorage.removeItem('couchdb-password');

}


const getSession = () =>
  safeFetch(REMOTE_SERVER + "_session", {
    credentials: "include",
  }).then(_ => _.json());

const getUser = userId =>
  safeFetch(`${REMOTE_SERVER}_users/org.couchdb.user:${userId}`, {
    credentials: "include",
  }).then(_ => _.json());

const fetchCouchDBCredentials = () =>
  safeFetch(BACKEND_API_ENDPOINT + "/couchdb-credentials", {
    credentials: "include",
  })
    .then(response => response.json())
    .catch(() => null);

const getCouchDBCredentials = async () => {

  const credentials = await fetchCouchDBCredentials();

  if (credentials) {

    localStorage.setItem('couchdb-username', credentials.username);
    localStorage.setItem('couchdb-password', credentials.password);

  }

  return credentials;

};

const authenticate = async () => {

  let userData = null;

  try {
    const token = jscookie.get(TOKEN_COOKIE_NAME);
    userData = verifyToken(token) ? getUserFromToken(token) : null;
  } catch (e) {
    logOut();
    throw new Error("FORBIDDEN");
  }

  if (!userData || !userData.applications) {
    logOut();
    throw new Error("FORBIDDEN");
  }
    

  if (userData.applications && userData.applications.indexOf("Dashboard") === -1)
    throw new Error(`Cannot authenticate to "Dashboard" application. Applications allowed for the user: ${JSON.stringify(userData.applications)}`);

};

const verifyAuthData = authData => {
  if (!authData) return false;
  if (!authData["device-server-token"]) return false;
  return true;
};

const getAuthInfoFromRemote = async () => {
  const { userCtx: session } = await getSession();
  if (!session.name) return null;
  const user = await getUser(session.name);
  if (!verifyAuthData(user.auth_data)) return null;
  /*
      'user' contains user info as provided by couch-manager.
      It is used by CodeActivation to get device-server-token.
    */
  /*
      'session' contains informations regarding user's session, as provided by couchdb.
    */
  return { user, session };
};

const getAuthInfo = async database => {
  const auth = await getAuthInfoFromRemote().catch(() => database.get("_local/auth"));
  if (!auth) throw new Error("Invalid authentication");
  const localAuth = await database.get("_local/auth").catch(() => ({
    _id: "_local/auth",
  }));

  await database.put({ ...localAuth, ...auth });
  return auth;
};

export { authenticate, getAuthInfo, logOut };
