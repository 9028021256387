import ClientChat from "contexts/Repository/entity/client_chat";

export class CustomerChatRepository {

  constructor(instance) {
    this.database = instance;
  }

  findOneById = (docId) => {
    
  }

  find = async (payload) => {

    
    
  }

  findOne = async (customerId) => {


    const output = await this.database.find({
      selector: {
        client_id: customerId,
        type: 'client_chat'
      }
    });

    if (output.docs && output.docs[0])
      return output.docs[0];

    return null;

  }

  findAll = async () => {

    

  }

  create = async (clientId) => {

    const newClientChat = new ClientChat();
    newClientChat.setClientId(clientId);
    await this.database.put(newClientChat);

    return newClientChat;

  }

  update = (object) => {

  }

  delete = (object) => {

  }

}