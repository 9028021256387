import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage as T } from "react-intl";
import queryString from "query-string";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { encode } from "comma-separated-values";
import MapSearchIcon from "mdi-material-ui/MapSearch";
import { logEvent } from 'utils/googleAnalytics';
import { BoxFilter, LocalLink } from "components";
import { boxType } from "types";
import { BoxList, BoxTable } from "../";
import { boxesToRowData } from "data/installations/utils";
import { RepositoryContext, InstallationsContext, CountryContext } from "contexts";
import LinearProgress from "@material-ui/core/LinearProgress";

const rowDataToCsvFile = data => `data:text/csv;charset=utf-8,${encode(data)}`;

const scrollMargin = 200;
let defaultNbItems = 20;
if (window) {
  defaultNbItems = Math.max(defaultNbItems, Math.ceil(window.innerHeight / 50));
}
const nbNewItems = 20;

const styles = theme => ({
  sortButtons: {
    display: "flex",
  },
  card: {
    margin: "8px",
  },
  row: {
    textDecoration: "none",
    color: "inherit",
    display: "block",
    paddingTop: "8px",
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
});

class Boxes extends React.PureComponent {

  constructor(props) {

    super(props);

    this.state = {
      nbItems: defaultNbItems,
      defaultFilterInput: props.filterInput,
      exportedInstallations: [],
      exportInProgress: false
    };

  }

  componentDidMount() {
    if (window) {
      window.addEventListener("scroll", this.onSceneScroll);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("scroll", this.onSceneScroll);
    }
  }

  setSearch = newSearch => {
    const { location, history } = this.props;
    const search = queryString.parse(location.search);
    history.replace({
      search: queryString.stringify({
        ...search,
        ...newSearch,
      }),
    });
  };

  onSceneScroll = async (e) => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.offsetHeight;
    if (scrollY + windowHeight >= documentHeight - scrollMargin) {
      this.showMore();
    }
  };

  sort = property => {
    const { order, sortProperty } = this.props;
    this.resetNbItems();
    if (property === sortProperty) {
      this.invertSortOrder();
    } else {
      this.setSearch({
        sort: property,
        order,
      });
    }
    logEvent('CONTRACTS TABLE: SORT', 'APPLY SORTING OPTION FROM HEADER', `${property} ${order}`);
  };

  invertSortOrder = () => {
    const { order, sortProperty } = this.props;
    const newOrder = order === "asc" ? "desc" : "asc";
    this.resetNbItems();
    this.setSearch({
      order: newOrder,
      sort: sortProperty,
    });
  };

  showMore = async () => {
    const { filteredInstallations, columns } = this.props;
    const { nbItems } = this.state;
    this.props.loadNextPage(columns);
    if (nbItems < filteredInstallations.length) {
      const newNbItems = Math.min(this.state.nbItems + nbNewItems, filteredInstallations.length);
      this.setState({
        nbItems: newNbItems,
      });
    }
  };

  resetNbItems = () => {
    if (this.state.nbItems !== defaultNbItems) {
      this.setState({
        nbItems: defaultNbItems,
      });
    }
  };

  onFilterUpdate = filterInput => {
    this.resetNbItems();
    this.setSearch({
      search: filterInput || undefined,
    });
  };

  selectColumns = columns => {
    this.resetNbItems();
    this.props.setColumns(columns);
  };

  downloadCsv = async () => {

    if (!document)
      return;

    this.setState({exportInProgress: true});

    logEvent('EXPORT', 'DOWNLOAD CSV', this.props.filterInput);

    const filter = [this.props.filterInput];

    if (this.props.country && this.props.country.label)
      filter.push(`country:${this.props.country.label}`);

    const installationsToBeExported = await this.props.applyExport(filter, this.props.sortProperty, this.props.order);

    const { filterInput } = this.props;
    const encodedUri = encodeURI(rowDataToCsvFile(boxesToRowData(installationsToBeExported)));
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", encodeURI(filterInput || "data") + ".csv");
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);

    this.setState({exportInProgress: false});

  };

  render() {
    const {
      classes,
      order,
      sortProperty,
      filteredInstallations,
      filterInput,
      columns,
      
    } = this.props;
    const { defaultFilterInput } = this.state;
    const displayedBoxes = filteredInstallations;

    const mapLink = encodeURI(`/map${filterInput ? `?search=${filterInput}` : ""}`);

    logEvent('INSTALLATIONS TABLE', 'SHOW INSTALLATIONS TABLE', filterInput);

    return (
      <Card className={classes.card}>
        <CardContent>
          <BoxFilter
            defaultValue={defaultFilterInput}
            onUpdate={this.onFilterUpdate}
            invertSortOrder={this.invertSortOrder}
            sort={this.sort}
          />
          <span className={classes.textSecondary}>
            { this.props.filterInput && (
              <>
                <Tooltip title={<T id="showOnMap" />}>
                  <IconButton component={LocalLink} to={mapLink}>
                    <MapSearchIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={<T id="download" />}>
                  <IconButton onClick={this.downloadCsv}>
                    <CloudDownloadIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            { this.state.exportInProgress && (
              <>
                <LinearProgress />
                <span style={{ marginBottom:'30px', lineHeight:'30px' }}><T id="exportPreparation" /></span>
              </>
            )}
          </span>
          <Hidden smDown>
            <BoxTable
              boxes={displayedBoxes}
              sortProperty={sortProperty}
              order={order}
              sort={this.sort}
              selectedColumns={columns}
              selectColumns={this.selectColumns}
              loading={this.props.loading}
            />
          </Hidden>
          <Hidden mdUp>
            <div className={classes.textSecondary}>
              <T id="sortedBy" /> <T id={`sortOption.${sortProperty}`} />{" "}
              <T id={`sortOrder.${order}`} />
            </div>
            <BoxList boxes={displayedBoxes} />
          </Hidden>
          <p>
            {!window && displayedBoxes.length < filteredInstallations.length && (
              <Button onClick={this.showMore}>
                <T id="showMore" />
              </Button>
            )}
            {displayedBoxes.length === filteredInstallations.length && (
              <span className={classes.textSecondary}>
                {" ("}
                <T
                  id="installationRelativeCount"
                  values={{
                    displayedCount: displayedBoxes.length,
                    totalCount: filteredInstallations.length,
                  }}
                />
                )
              </span>
            )}
          </p>
        </CardContent>
      </Card>
    );
  }
}

Boxes.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  sortProperty: PropTypes.string.isRequired,
  filterInput: PropTypes.string.isRequired,
  filteredInstallations: PropTypes.arrayOf(boxType).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  setColumns: PropTypes.func.isRequired,
};

const BoxesWrapper = props => (
  <CountryContext.Consumer>
    {country => (
      <RepositoryContext.Consumer>{({loading, loadNextPage, applyExport}) => (
        <InstallationsContext.Consumer>
          {({ order, sortProperty, filterInput, filteredInstallations, columns, setColumns, repo }) => (
            <Boxes
              order={order}
              sortProperty={sortProperty}
              filterInput={filterInput}
              filteredInstallations={filteredInstallations}
              columns={columns}
              setColumns={setColumns}
              repo={repo}
              loading={loading}
              loadNextPage={loadNextPage}
              applyExport={applyExport}
              country={country}
              {...props}
            />
          )}
        </InstallationsContext.Consumer>)}
      </RepositoryContext.Consumer>
    )}
  </CountryContext.Consumer>
);

export default compose(withRouter, withStyles(styles))(BoxesWrapper);
