import { boxType } from "types";

const BoxHub = ({ box }) => {
  return (
    <span>
      {box.contract.hub}
    </span>
  );
};

BoxHub.propTypes = {
  box: boxType.isRequired,
};

export { BoxHub as BoxHubComponent };

export default BoxHub;
