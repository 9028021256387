export class AllInstallationsDocsRepository {

  constructor(instance) {
    this.database = instance;
  }

  findById = async (boxId) => {

    const contractData = await this.database.query('main/allInstallationsDocs', { key: boxId });

    if (contractData.rows[0] && contractData.rows[0].value)
      return contractData.rows[0].value;

    return null;

  }

  findByIds = async (boxIds) => {

    const output = [];
    const contractData = await this.database.query('main/allInstallationsDocs', { keys: boxIds });

    for ( const box of contractData.rows )
      output.push(box.value);

    return output;

  }

}