import LastClientCall from "contexts/Repository/entity/last_client_call";

export class LastClientCallRepository {

  constructor(instance) {
    this.database = instance;
  }

  findOneById = _id =>
    this.database.get(_id);

  find = async (payload) => {
    
  }

  findOne = async (customerId) => {

    const response = await this.database.find({
      selector: {
        type: "last_client_call",
        client_id: customerId
      }
    });

    if (response.docs && response.docs[0])
      return response.docs[0];

    return null;

  }

  findAll = async (sortColumn, sortOrder, limit, bookmark) => {

    const response = await this.database.find({
      selector: {
        type: "last_client_call",
        date_called: {
          "$gt": null,
          "$ne": ""
        }
      },
      sort: [{[sortColumn]: sortOrder}],
      bookmark: (bookmark ? bookmark : null),
      limit
    });

    if (response.docs)
      return { data: response.docs, bookmark: response.bookmark };

    return { data: [], bookmark: null };

  }

  create = async (clientId, dateCalled) => {

    const newLastClientCall = new LastClientCall();

    newLastClientCall.setClientId(clientId);
    newLastClientCall.setDateCalled(dateCalled);

    return await this.database.put(newLastClientCall);

  }

  update = async (_id, payload) => {
    
    // CHECK IF THE _ID EXISTS
    // ---

    const lastClientCallById = await this.findOneById(_id);

    if (!lastClientCallById)
      throw Error('unknown _id');

    // CHECK IF THE PAYLOAD PROPERTIES ARE CORRECT
    // ---

    let lastClientCallPattern = new LastClientCall();
    lastClientCallPattern = lastClientCallPattern.pattern();

    let checkProperties = true;

    for (const [key] of Object.entries(payload)) {

      if (!lastClientCallPattern.includes(key))
        checkProperties = false;

    }

    if (!checkProperties)
      throw Error('property error');

    // UPDATE IN THE DATABASE
    // ---

    await this.database.put({ ...lastClientCallById, ...payload });

  }

  delete = () => {

  }

}