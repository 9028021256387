import uuidv4 from "uuid/v4";

export default class ChatMessage {

  constructor() {

    const id = uuidv4();

    this._id = `urn.qotto.chat_message:${id}`;
    this.type = `chat_message`;
    this.message_id = id;

    this.chat_id = {}; // urn.qotto.chat:07227696-4d7d-4c6d-9059-d6fc4a272327
    this.author = {}; // { displayed_name: "John Doe", user_id: "62873bbc-4b5c-5db1-bdfd-02d4c7b0c8c5" }
    this.content = '';
    this.date_synced = 0;
    this.date_typed = 0;

  }

  setContent = (value) => {
    this.content = value;
  }

  setAuthorDisplayedName = (value) => {
    this.author.displayed_name = value;
  }

  setAuthorId = (value) => {
    this.author.user_id = value;
  }

  setChatId = (value) => {
    this.chat_id = value;
  }

  setDateTyped = (value) => {
    this.date_synced = value;
    this.date_typed = value;
  }

  get = () => {
    return {
      _id: this._id,
      type: this.type,
      message_id: this.message_id,
      author: this.author,
      content: this.content,
      date_synced: this.date_synced,
      date_typed: this.date_typed,
    }
  }

}