import ChatMessage from "contexts/Repository/entity/chat_message";

export class ChatMessageRepository {

  constructor(instance) {
    this.database = instance;
  }

  findOneById = _id =>
    this.database.get(_id);

  find = async (chatId) => {

    // GET MESSAGES FROM VIEW
    // ---

    const options = {
      key: chatId
    };

    const queryResponse = await this.database.query('main/allChatMessage', options);

    if (!queryResponse.rows)
      return [];

    let messages = queryResponse.rows.reduce(
      (acc, row) => {
        acc.push( {...row.value, id: row.id} );
        return acc;
      },
      []
    );

    // SORT MESSAGES BY DATE TYPED
    // ---

    messages = messages.sort((a,b) => a.date_typed - b.date_typed);

    return messages;
    
  }

  findOne = async (payload) => {

    return null;

  }

  findAll = async () => {

    return null;

  }

  create = async (chatId, authorDisplayedName, authorUserId, dateTyped, content) => {

    const newChatMessage = new ChatMessage();

    newChatMessage.setChatId(chatId);
    newChatMessage.setAuthorId(authorUserId);
    newChatMessage.setAuthorDisplayedName(authorDisplayedName);
    newChatMessage.setContent(content);
    newChatMessage.setDateTyped(dateTyped);

    const createdDocument = await this.database.put(newChatMessage);

    return createdDocument;

  }

  update = (object) => {

  }

  delete = async (_id) => {

    const chatMessageById = await this.findOneById(_id);

    if (!chatMessageById)
      throw Error('unknown _id');

    return await this.database.put({ ...chatMessageById, _deleted: true});

  }

}