import { getConfigValue } from "utils/envConfig"

export const meilisearch = async (index, query, filters) => {

  const output = []

  const response = await fetch(`${getConfigValue('REACT_APP_MEILISEARCH_HOST')}/indexes/${index}/search?q=${encodeURIComponent(query)}&filter=${filters}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${getConfigValue('REACT_APP_MEILISEARCH_READONLY_TOKEN')}`
    }
  })

  const responseJSON = await response.json()

  if (responseJSON.hits)
    for (const hit of responseJSON.hits)
      output.push(hit.contract)

  return output

}
