import ChatSubscription from "contexts/Repository/entity/chat_subscription";

export class ChatSubscriptionRepository {

  constructor(instance) {
    this.database = instance;
  }

  findOneById = _id =>
    this.database.get(_id);

  find = async (payload) => {
    
    return null;
    
  }

  findOne = async (payload) => {

    const output = await this.database.find({
      selector: {
        ...payload,
        type: 'chat_subscription'
      }
    });

    if (output.docs && output.docs[0])
      return output.docs[0];

    return null;

  }

  findAll = async () => {

    return null;

  }

  create = async (chatId, userId, dateSubscribed) => {

    const newChatSubscription = new ChatSubscription();

    newChatSubscription.setChatId(chatId);
    newChatSubscription.setUserId(userId);
    newChatSubscription.setDateSubscribed(dateSubscribed);

    const createdDocument = await this.database.put(newChatSubscription);

    return createdDocument;

  }

  update = async ( _id, payload ) => {

    // CHECK IF THE _ID EXISTS
    // ---

    const chatSubscriptionById = await this.findOneById(_id);

    if (!chatSubscriptionById)
      throw Error('unknown _id');

    // CHECK IF THE PAYLOAD PROPERTIES ARE CORRECT
    // ---

    let chatSubscriptionPattern = new ChatSubscription();
    chatSubscriptionPattern = chatSubscriptionPattern.pattern();

    let checkProperties = true;

    for (const [key] of Object.entries(payload)) {

      if (!chatSubscriptionPattern.includes(key))
        checkProperties = false;

    }

    if (!checkProperties)
      throw Error('property error');

    // UPDATE IN THE DATABASE
    // ---

    await this.database.put({ ...chatSubscriptionById, ...payload });

  }

}