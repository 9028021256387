import React, { useState } from "react";
import classNames from "classnames";
import { FormattedMessage as T } from "react-intl";
import { withStyles, createStyles, WithStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

import { PouchdbContext } from "contexts";
import { CustomPrimaryColor } from "components";

import allFlags, { FlagDefinition } from "flags";

const styles = createStyles({
  root: {},
  button: {
    margin: "2px",
    padding: "2px 4px",
  },
  checkbox: {
    padding: 0,
  },
  checkedCheckbox: {
    color: "white !important",
  },
});

const buildFlagId = (flag: FlagDefinition, contractCode: string): string =>
  `urn.qotto.flag:installation:${contractCode}:${flag.name}`;

const buildFlagDocument = (flag: FlagDefinition, contractCode: string): any => ({
  _id: buildFlagId(flag, contractCode),
  type: "flag",
});

interface InstallationFlagProps extends WithStyles<typeof styles> {
  contractCode: string;
  contractFlags: { [key: string]: any };
  flag: FlagDefinition;
  flagDoc: any;
  addFlag: (doc: any) => void;
  removeFlag: (doc: any) => void;
}

const InstallationFlag = (props: InstallationFlagProps) => {

  const { flag, flagDoc, contractCode, classes } = props;

  const [isUpdating, setIsUpdating] = useState(false);

  const isFlagEnabled = Boolean(flagDoc && !flagDoc._deleted);

  const updateDisabled = isUpdating || (flagDoc && flagDoc._memory_only);

  const handleFlagClick = async () => {

    if (!contractCode || updateDisabled) return;

    setIsUpdating(true);

    if (isFlagEnabled) {
      await props.removeFlag(flag.name);
    } else {
      await props.addFlag(flag.name);
    }

    setIsUpdating(false);

    return;

  };

  return (
    <CustomPrimaryColor color={flag.color}>
      <Tooltip title={<T id={`flag.${flag.name}.description`} />}>
        <span>
          <Button
            key={flag.name}
            size="small"
            color="primary"
            variant={isFlagEnabled ? "contained" : "text"}
            onClick={handleFlagClick}
            disabled={updateDisabled}
            className={classes.button}
          >
            {updateDisabled ? (
              <CircularProgress size={24} />
            ) : (
              <Checkbox
                checked={isFlagEnabled}
                className={classNames(classes.checkbox, {
                  [classes.checkedCheckbox]: isFlagEnabled,
                })}
              />
            )}
            &nbsp;
            <flag.icon />
            &nbsp;
            <T id={`flag.${flag.name}`} />
            &nbsp;
          </Button>
        </span>
      </Tooltip>
    </CustomPrimaryColor>
  );
};

interface InstallationFlagsProps extends WithStyles<typeof styles> {
  contractCode: string;
  flags: { [key: string]: any };
  docsByFlagName: { [key: string]: any };
  addFlag: (flag: any) => void;
  removeFlag: (flag: any) => void;
}

const InstallationFlags = (props: InstallationFlagsProps) => (
  <span className={props.classes.root}>
    {allFlags.map((flag: FlagDefinition) => (
      <InstallationFlag
        key={flag.name}
        flag={flag}
        contractCode={props.contractCode}
        contractFlags={props.flags}
        flagDoc={(props.flags.indexOf(flag.name) !== -1)}
        addFlag={props.addFlag}
        removeFlag={props.removeFlag}
        classes={props.classes}
      />
    ))}
  </span>
);

const InstallationFlagsWrapper = (props: InstallationFlagsProps) => (
  <InstallationFlags {...props} />
);

export default withStyles(styles)(InstallationFlagsWrapper);
