import React, { Fragment } from "react";
import { FormattedMessage as T } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import HelpIcon from "@material-ui/icons/Help";
import ReactMarkdown from "react-markdown";
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import { boxType } from "types";
import { BoxActivationRate } from "components";

import 'katex/dist/katex.min.css'

const styles = {
  helpButton: {
    padding: 0,
    verticalAlign: "top",
    fontSize: "inherit",
  },
};

const ReactMarkdownWithLatex = (props) => {
  return <ReactMarkdown
    children={props.children}
    remarkPlugins={[remarkMath]}
    rehypePlugins={[rehypeKatex]}
  />
}

class ActivationRateInfo extends React.PureComponent {

  state = {
    helpDialogOpen: false,
  };
  
  toggleDialog = () => {
    this.setState({
      helpDialogOpen: !this.state.helpDialogOpen,
    });
  };

  render() {

    const { classes, box } = this.props;
    const { helpDialogOpen } = this.state;

const explanationMarkdown = `
Le taux d’activation d’un client sur une période donnée permet d’évaluer si ce dernier est un bon ou un mauvais utilisateur.

Pour le calculer :
1. Nombre de jours sur la période = ${box.days_on_active_period}
2. Nombre de jours où le kit a été activé sur la période = ${box.active_days}

$$
TA = \\frac{Nombre~de~jours~où~le~kit~a~été~activé~sur~la~période}{Nombre~de~jours~sur~la~période~où~le~contrat~est~en~cours}
$$
`;

    let toDate = (new Date()).toLocaleString('fr-FR').substring(0,10);

    if (Date.now() > box.confirmed_expiration_timestamp)
      toDate = (new Date(box.confirmed_expiration_timestamp)).toLocaleString('fr-FR').substring(0,10);

    return (
      <Fragment>
        <Dialog open={helpDialogOpen} onClose={this.toggleDialog}>
          <DialogTitle>
            <T
              id="activationRateHelper"
              values={{
                from_date: (new Date(box.first_activation_date)).toLocaleString('fr-FR').substring(0,10),
                to_date: toDate
              }}
            />
          </DialogTitle>
          <DialogContent>
            <ReactMarkdownWithLatex linkTarget="_blank">
              {explanationMarkdown}
            </ReactMarkdownWithLatex>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleDialog}>
              <T id="close" />
            </Button>
          </DialogActions>
        </Dialog>
        <span>
          <T id="activationRateInfo" />{" "}
          <strong>
            <BoxActivationRate box={box} />
          </strong>{" "}
          <IconButton fontSize="inherit" className={classes.helpButton} onClick={this.toggleDialog}>
            <HelpIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Fragment>
    );
  }
}

ActivationRateInfo.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(ActivationRateInfo);
