import React from "react";
import { FormattedMessage as T, FormattedDate } from "react-intl";
import { withStyles, createStyles, WithStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

type BigInt = number;
declare const BigInt: typeof Number;

const DAYS_IN_A_WEEK = 7;
const WEEKS_IN_A_YEAR = 52;
const MILLIS_IN_A_WEEK = DAYS_IN_A_WEEK * 24 * 3600 * 1e3;

const byteStrToHexStr = (str: string): string => {
  const charCodes: number[] = Array.from(str).map(_ => _.charCodeAt(0));
  const hexStrings: string[] = charCodes.map(_ =>
    _.toString(16)
      .substr(-2)
      .padStart(2, "0")
  );
  return "0x" + hexStrings.join("");
};

const decodeWeeklyActivations = (encodedValue: string): string => {
  if (encodedValue === "") {
    return "";
  }
  let bigIntValue: BigInt = BigInt(byteStrToHexStr(atob(encodedValue)));
  let result: string = "";
  const chunkMaxValue: BigInt = BigInt(8);
  while (bigIntValue) {
    let chunk: BigInt = bigIntValue % chunkMaxValue;
    bigIntValue = (bigIntValue - chunk) / chunkMaxValue;
    result = chunk + result;
  }
  return result;
};

const generateWeeklyActivationsArray = (
  maturityDays: number,
  debtReimbursementDays: number,
  firstActivationDate: number,
  weeklyContributions: string,
  activeDaysWithoutDebtReduction: number,
): number[] => {
  const paidArray: number[] = Array.from(weeklyContributions).map(
    char => parseInt(char)
  );
  while (paidArray.length > 0 && paidArray[paidArray.length - 1] === 0) {
    paidArray.pop();
  }
  let paidDays = 0;
  for (let i = 0; i < paidArray.length ; i++) {
    paidDays += paidArray[i];
  }

  const startDate: Date = new Date(firstActivationDate);
  let currentDate: Date = new Date()
  const passedWeeks: number = Math.ceil((currentDate.getTime() - startDate.getTime()) / MILLIS_IN_A_WEEK);

  const extensionArray: number[] = Array(Math.max(passedWeeks - paidArray.length, 0)).fill(0);

  const debtDays = Math.max(maturityDays + activeDaysWithoutDebtReduction - paidDays, 0);
  const debtWeeks = debtDays > 0 ? Math.ceil(debtDays / DAYS_IN_A_WEEK): 0;
  const debtArray: number[] = Array(debtWeeks).fill(0);

  const activationsArray: number[] = [
    ...paidArray,
    ...(debtWeeks > 1) ? extensionArray : [],
    ...debtArray,
  ];
  if (debtReimbursementDays > 0) {
    let index = activationsArray.length - 1;
    while (debtReimbursementDays > DAYS_IN_A_WEEK){
      activationsArray[index] = DAYS_IN_A_WEEK;
      debtReimbursementDays = debtReimbursementDays - DAYS_IN_A_WEEK
      index = index - 1
    }
    activationsArray[index] = debtReimbursementDays
  }
  return activationsArray
};

const activatedDaysToColor = (activationRate: number): string =>{
  if (activationRate === 7) {
    return "#2D6C4A";
  } else if (activationRate === 6) {
    return "#429267";
  } else if (activationRate === 5) {
    return "#A1C962";
  } else if (activationRate === 4) {
    return "#FFFF5C";
  } else if (activationRate === 3) {
    return "#FFCE70";
  } else if (activationRate === 2) {
    return "#FF9F70";
  } else if (activationRate === 1) {
    return "#FF7070";
  }
  return "#FFFFFF";
}

const styles = createStyles({
  grid: {
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    display: "grid",
    gridTemplateColumns: `repeat(${WEEKS_IN_A_YEAR}, 1fr)`,
  },
  weekItem: {
    border: "black",
    height: "24px",
    borderStyle: "solid",
    borderWidth: "0.1px",
    overflow: "hidden",
  },
});

interface WeekTooltipProps {
  activationRate: number;
  weekOffset: number;
  firstActivationDate: number;
}

const WeekTooltip = (props: WeekTooltipProps) => {
  const { activationRate, weekOffset, firstActivationDate } = props;
  const startDate: Date = new Date(firstActivationDate);
  let fromDate: Date = new Date(firstActivationDate)
  fromDate.setDate(startDate.getDate() + weekOffset * DAYS_IN_A_WEEK)
  let toDate: Date = new Date(fromDate.getTime())
  toDate.setDate(fromDate.getDate() + DAYS_IN_A_WEEK - 1)
  return (
    <>
      <T
        id="weeklyActivationsTooltipRange"
        values={{
          fromDate: <FormattedDate value={fromDate} />,
          toDate: <FormattedDate value={toDate} />,
        }}
      />
      <br />
      <T
        id="weeklyActivationsTooltipActivation"
        values={{
          days: activationRate,
        }}
      />
    </>
  );
};

interface WeeklyActivationsGridProps extends WithStyles<typeof styles> {
  maturityDays: number;
  debtReimbursementDays: number;
  firstActivationDate: number;
  encodedWeeklyContributions: string;
  activeDaysWithoutDebtReduction: number;
}

const WeeklyActivationsGrid = (props: WeeklyActivationsGridProps) => {
  const {
    maturityDays,
    debtReimbursementDays,
    firstActivationDate,
    encodedWeeklyContributions,
    activeDaysWithoutDebtReduction,
    classes
  } = props;
  const decodedWeeklyContributions: string = decodeWeeklyActivations(encodedWeeklyContributions);
  const weeklyContributions: number[] = generateWeeklyActivationsArray(
    maturityDays,
    debtReimbursementDays,
    firstActivationDate,
    decodedWeeklyContributions,
    activeDaysWithoutDebtReduction,
  )
  let nbWeeksActivatedWithoutReducingDebt = Math.round(activeDaysWithoutDebtReduction / DAYS_IN_A_WEEK);
  let nbReimbursedWeeks = Math.ceil(debtReimbursementDays / DAYS_IN_A_WEEK);
  let currentDate: Date = new Date();
  let currentWeekIndex: number = Math.floor((currentDate.getTime() - firstActivationDate) / MILLIS_IN_A_WEEK);
  let maturityIndex :  number = Math.floor(maturityDays / DAYS_IN_A_WEEK);
  return (
    <div className={classes.grid} style={{gridTemplateRows:`repeat(${Math.ceil(weeklyContributions.length / WEEKS_IN_A_YEAR)}, 1fr)`}}>
      {weeklyContributions.map((activationRate, index) => (
        <Tooltip
          key={index}
          title={<WeekTooltip
            activationRate={activationRate}
            weekOffset={index}
            firstActivationDate={firstActivationDate} />}
        >
          <div className={classes.weekItem}
            style={{
              borderColor: `${index > maturityIndex+nbWeeksActivatedWithoutReducingDebt ? "red" : index > maturityIndex ? "blue" : "black"}`,
            }}
          >
            <svg x="0" y="0" height="100%" width="100%" style={{backgroundColor:`${index > (weeklyContributions.length - nbReimbursedWeeks - 1) ? "white" : activatedDaysToColor(activationRate)}`}}>
              <defs>
                <pattern id="passedHatch" width="1%" height="14%" patternTransform="rotate(-45 0 0)" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="1" style={{stroke: "black", strokeWidth: "1"}}></line>
                </pattern>
                <pattern id="reimbursedHatch" width="1%" height="14%" patternTransform="rotate(-45 0 0)" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="1" style={{stroke: "green", strokeWidth: "1"}}></line>
                </pattern>
              </defs>
              <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                stroke="black"
                strokeWidth="0.1"
                fill={index > (weeklyContributions.length - nbReimbursedWeeks - 1) ? "url(#reimbursedHatch)" : index < currentWeekIndex ? "url(#passedHatch)" : "None"}
                  >
              </rect>
            </svg>
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default withStyles(styles)(WeeklyActivationsGrid);
