import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { googleAnalyticsInit } from 'utils/googleAnalytics';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { loadConfigInLocalStorage } from "utils/envConfig";

Sentry.init({
  dsn: "https://04637f1ad9e7415fb9c9b5599b175fa3@o4504204331253760.ingest.sentry.io/4504204333481986",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0, // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring. We recommend adjusting this value in production
});

// Google Analytics
googleAnalyticsInit();

if (document.location.href.indexOf("app.qotto.net") > -1) {

    alert("Vous allez être redirigé vers la nouvelle adresse :\n \"https://aftersales.qotto.app\".");
    document.location.href = "https://aftersales.qotto.app";

} else {

    const boot = async () => {

      await loadConfigInLocalStorage();
      ReactDOM.render(<App />, document.getElementById("root"));
      registerServiceWorker();

    }

    boot();

}
