import React from "react";

import { CountryFlag, BoxFlags } from "components";

import boxProperties from "data/installations/properties";

import { boxType } from "types";
import { CountryContext } from "contexts";
import { countryFromLabel } from "countries";

const BoxClient = ({ box }) => {
  const countryLabel = boxProperties.country.getValue(box);
  const boxCountry = countryFromLabel(countryLabel);
  return (
    <span>
      <CountryContext.Consumer>
        {country => (
          <>
            <CountryFlag country={boxCountry} /> {box.client.name}{" "}
            <BoxFlags box={box} />
          </>
        )}
      </CountryContext.Consumer>
    </span>
  );
};

BoxClient.propTypes = {
  box: boxType.isRequired,
};

export { BoxClient as BoxClientComponent };

export default BoxClient;
