import { FlagRepository } from "./flagRepository";
import { AllCustomerMetadataRepository } from "./allCustomerMetadataRepository";
import { ClientCallbackRepository } from "./clientCallbackRepository";
import { LastClientCallRepository } from "./lastClientCallRepository";
import { AllInstallationsByNameRepository } from "./allInstallationsByNameRepository";
import { AllInstallationsDocsRepository } from "./allInstallationsDocsRepository";
import { installationUtils } from "utils/installation";
import { logEvent } from 'utils/googleAnalytics';
import { meilisearch } from "utils/meilisearch";

const DEFAULT_LIMIT = 20;

export class InstallationRepository {

  constructor(instance) {
    this.database = instance;
    this.limit = DEFAULT_LIMIT;
  }

  // PUBLIC METHODS
  // ---

  findOneById = (boxId) => {

    const allInstallationsDocsRepo = new AllInstallationsDocsRepository(this.database);
    return allInstallationsDocsRepo.findById(boxId);

  }

  findBy = async (payload) => {

    const output = await this.database.find({
      selector: {
        ...payload,
        type: 'installation'
      }
    });

    if (output.docs)
      return output.docs;
    
    return null;
    
  }

  findOne = async (payload) => {

    const output = await this.database.find({
      selector: {
        ...payload,
        type: 'installation'
      }
    });

    if (output.docs && output.docs[0])
      return output.docs[0];

    return null;

  }

  findAll = async () => {
    return await this.fetchPageByPage();
  }

  findAllWithFilter = async (filters, sortColumn, sortOrder, bookmark, columns) => {

    // FIND INSTALLATIONS WITH A SPECIFIC FILTER
    // ---

    this.setLimit(DEFAULT_LIMIT);

    const sortProperty = this.getDatabaseFieldFromColumn(sortColumn);

    if ( !['asc', 'desc'].includes(sortOrder) )
      sortOrder = 'asc';

    let installations = [];

    const filterSelector = this.getSelectorFromArray(filters);

    if (sortColumn === 'lastClientCall')
      installations = await this.findFromLastClientCall(sortOrder, bookmark);
    else if (sortColumn === 'clientCallback')
      installations = await this.findFromClientCallback(sortOrder, bookmark);
    else
      installations = await this.findFromInstallation(filterSelector, sortProperty, sortOrder, bookmark, columns);
    
    return installations;

  }

  // PRIVATE METHODS
  // ---

  fetchAllCustomerData = async () => {

    const clientRepo = new AllCustomerMetadataRepository(this.database);
    return clientRepo.findAll();

  }

  fetchAllFlags = async () => {

    const flagRepo = new FlagRepository(this.database);
    return flagRepo.findAll();

  }

  fetchAllPageByPage = async (filters, sortColumn, sortOrder) => {

    let output = [];
    let bookmark = null;

    this.setLimit(500);

    const metadataFromCustomer = await this.fetchAllCustomerData();
    const allFlags = await this.fetchAllFlags();

    const getData = async (resolve, reject) => {

      // FIND INSTALLATIONS WITH A SPECIFIC FILTER
      // ---

      const sortProperty = this.getDatabaseFieldFromColumn(sortColumn);

      if ( !['asc', 'desc'].includes(sortOrder) )
        sortOrder = 'asc';

      const filterSelector = this.getSelectorFromArray(filters);

      const installations = await this.findFromInstallationWithoutMetaData(filterSelector, bookmark);

      bookmark = installations.bookmark;

      if (installations.data.length > 0) {

        output = output.concat(installations.data);
        await getData(resolve, reject);

      } else {

        return resolve(output);

      }

    }

    let installations = await new Promise((r, j) => getData(r, j));

    installations = installationUtils.normalize(installations);

    for (const [key, installation] of Object.entries(installations)) {

      const customerMetadata = metadataFromCustomer.filter(box => box.customer === installation.client.zoho_id);
      let lastClientCall = customerMetadata.find(metadata => metadata.type === 'last_client_call');
      let clientCallback = customerMetadata.find(metadata => metadata.type === 'client_callback');

      const flagsRaw = allFlags.filter(metadata => metadata.installation === installation.installation_id.split('-')[0]);

      const flags = [];
      let last_client_call = null;
      let client_callback = null;

      for (const flag of flagsRaw)
        flags.push(flag.value);

      if (lastClientCall && lastClientCall.value)
        last_client_call = lastClientCall.value;

      if (clientCallback && clientCallback.value)
        client_callback = clientCallback.value;

      installations[key] = {
        ...installation,
        client: {
          ...installation.client,
          last_call: last_client_call,
          callback_date: client_callback
        },
        flags
      };

    }

    // SORT BY PROPERTY

    installations = this.sortInstallations(installations, sortColumn, sortOrder);

    return installations;

  }

  sortInstallations = (installations, sortProperty, order) => {

    const CREATED_RELEVANCE_TRESHOLD = 1528467692787; // prior to this date (June 8 2018), installation date is not relevant.
    const MILLIS_IN_A_DAY = 24 * 3600 * 1e3; //ms

    const boxBMSBrand = box => {
      try {
        return box.kit.bms.brand || null;
      } catch( TypeError ) {
        return null;
      }
    }

    const installationDate = ({ installation_date }) => {
      const date = new Date(installation_date);
      if (isPriorToTreshold(date.getTime())) {
        return null;
      }
      return date;
    };

    const boxAssociatedDate = ({ box_associated_date }) => {
      const date = new Date(box_associated_date);
      if (isPriorToTreshold(date.getTime())) {
        return null;
      }
      return date;
    };

    const installationDays = box => {
      const _installationDate = installationDate(box);
      if (_installationDate === null) {
        return null;
      }
      return (Date.now() - _installationDate.getTime()) / MILLIS_IN_A_DAY;
    };

    const boxAmountToRecover = ({ credit_info }) => {
      if (!credit_info || !credit_info.amount_to_recover) {
        return null;
      }
      const { value_cents } = credit_info.amount_to_recover;
      return value_cents / 100;
    };

    const contractState = box => {
      const finished_soon = box.contract.finished_soon;
      const fully_paid = box.contract.fully_paid;
      const finished = box.contract.finished;
      if (finished) {
        return "finished";
      }
      else if (fully_paid) {
        return "fullyPaid";
      }
      else if (finished_soon) {
        return "finishedSoon";
      }
      else {
        return ("notFinishedSoon")
      }
    }

    const isPriorToTreshold = timestamp => timestamp <= CREATED_RELEVANCE_TRESHOLD;

    const boxProperties = {
      box: {
        getValue: box => box.contract.code
      },
      client: {
        getValue: box => box.client.name.toLowerCase()
      },
      sc: {
        getValue: box => box.sc_serial
      },
      bmsBrand: {
        getValue: boxBMSBrand
      },
      country: {
        getValue: box => box.zoho_project
      },
      expiration: {
        getValue: box => box.expiration_timestamp
      },
      lastCommunication: {
        getValue: box => box.last_communication_timestamp
      },
      dailyProduction: {
        getValue: ({ avg_power_7days }) => avg_power_7days && avg_power_7days.production * 24
      },
      dailyConsumption: {
        getValue: ({ avg_power_7days }) => avg_power_7days && avg_power_7days.consumption * 24
      },
      consumptionPercentage: {
        getValue: ({ avg_power_7days }) => {
          if (!avg_power_7days || !avg_power_7days.production) {
            return null;
          }
          return ((avg_power_7days.consumption || 0) * 100) / avg_power_7days.production;
        }
      },
      activationRate: {
        getValue: ({ credit_info }) => (credit_info ? credit_info.activation_rate * 100 : null)
      },
      lastClientCall: {
        getValue: ({ client }) => (client.last_call ? new Date(client.last_call) : null)
      },
      clientCallback: {
        getValue: ({ client }) => (client.callback_date ? new Date(client.callback_date) : null)
      },
      financialCategory: {
        getValue: ({ credit_info }) => (credit_info ? credit_info.financial_category : null)
      },
      followupCategory: {
        getValue: ({ credit_info }) => (credit_info ? credit_info.followup_category : null)
      },
      installationDate: {
        getValue: installationDate
      },
      boxAssociatedDate: {
        getValue: boxAssociatedDate
      },
      firstActivationDate: {
        getValue: box => (box.first_activation_date && new Date(box.first_activation_date)) || null
      },
      installationDays: {
        getValue: installationDate
      },
      inactiveDays: {
        getValue: ({ credit_info }) =>
          credit_info && credit_info.inactive_since
            ? (Date.now() - new Date(credit_info.inactive_since).getTime()) / MILLIS_IN_A_DAY
            : null
      },
      cheatScore: {
        getValue: ({ cheat_score }) => (isNaN(cheat_score) ? null : cheat_score)
      },
      amountToRecover: {
        getValue: boxAmountToRecover
      },
      flag: {
        getValue: box => box.flags
      },
      contractState: {
        getValue: contractState
      }
    };

    if (order === 'asc')
      return installations.sort(
        (p1, p2) => (boxProperties[sortProperty].getValue(p1) > boxProperties[sortProperty].getValue(p2)) ? 1 : (boxProperties[sortProperty].getValue(p1) < boxProperties[sortProperty].getValue(p2)) ? -1 : 0);
    else
      return installations.sort(
        (p1, p2) => (boxProperties[sortProperty].getValue(p1) < boxProperties[sortProperty].getValue(p2)) ? 1 : (boxProperties[sortProperty].getValue(p1) > boxProperties[sortProperty].getValue(p2)) ? -1 : 0);

  }

  getSelectorFromArray = (filters) => {

    if (!filters)
      return { type: 'installation' };

    const output = [];

    for (const filter of filters) {

      const formatedFilter = this.getSelectorFromString(filter);

      if (formatedFilter)
        output.push( formatedFilter );

    }

    output.forEach((filter) => {
      if (Object.keys(filter)[0])
        logEvent('FILTER_TYPE', 'FILTER', Object.keys(filter)[0]);
    });

    return { '$and': [ { type: 'installation' }, ...output] };

  };

  cleanStringForRegex = (str) => {

    const regex = /[^A-zÀ-ú0-9\s]/gm;
    return str.replace(regex, '');

  }

  getSelectorFromString = (filterInput) => {

    if (!filterInput || filterInput.trim() === '')
      return null;

    let onlyIntegerStringRegex = /^\d+$/gmi;

    let freeSearch;

    if ( onlyIntegerStringRegex.test(filterInput) ) {
      freeSearch = { 'contract.code': filterInput };
    } else {
      freeSearch = { 'contract.customer.name': { '$regex': `(?i)${this.cleanStringForRegex(filterInput)}` } };
    }

    // FREE SEARCH WITH CONTRACT CODE
    
    if (onlyIntegerStringRegex.test(filterInput))
      return { 'contract.code': filterInput };

    // FORMATED FILTER

    let regex = /([a-z\.]+):([A-zÀ-ú0-9]+)/gmi;
    let regexMatchs = regex.exec(filterInput);

    if (!regexMatchs || !regexMatchs[1] || !regexMatchs[2])
      return freeSearch;

    // CLIENT:
    // ---

    if (regexMatchs[1] === 'client')
      return { 'contract.customer.name': { '$regex': `(?i)${this.cleanStringForRegex(regexMatchs[2])}` } };
    
    // BOX:
    // ---

    if (regexMatchs[1] === 'box')
      return { 'kit.bms.serial_number': regexMatchs[2] };

    // SC:
    // ---

    if (regexMatchs[1] === 'sc')
      return { 'kit.bms.smartcard.serial_number': regexMatchs[2] };

    // BMS BRAND:
    // ---

    if (regexMatchs[1] === 'bmsBrand')
      return { 'kit.bms.brand': regexMatchs[2] };

    // EXPIRATION (GREATER THAN):
    // ---

    if (regexMatchs[1] === 'expiration.gt') {

      const date = new Date(regexMatchs[2]);
      return { 'contract.activation_end_date': { '$gte': date.toISOString() } };

    }

    // EXPIRATION (LIGHTER THAN):
    // ---

    if (regexMatchs[1] === 'expiration.lt') {

      const date = new Date(regexMatchs[2]);
      return { 'contract.activation_end_date': { '$lte': date.toISOString() } };

    }

    // INACTIVE DAYS.GT:
    // ---

    if (regexMatchs[1] === 'inactiveDays.gt') {

      const expirationTimestamp = Date.now() - regexMatchs[2] * 86400000;
      const date = new Date(expirationTimestamp);
      const ISODate = date.toISOString();

      return { 'contract.credit_info.inactive_since': { '$gt': ISODate } };

    }

    if (regexMatchs[1] === 'inactiveDays.lt') {

      const expirationTimestamp = Date.now() - regexMatchs[2] * 86400000;
      const date = new Date(expirationTimestamp);
      const ISODate = date.toISOString();

      return { 'contract.credit_info.inactive_since': { '$lt': ISODate } };

    }

    // COUNTRY:
    // ---

    if (regexMatchs[1] === 'country') {

      const countryCodes = {
        "burkina": 'bf',
        "benin": 'bj'
      };

      const countryCode = countryCodes[ regexMatchs[2].toLowerCase() ];

      if (countryCode)
        return { 'contract.country_code': countryCode };

    }

    // CONTRACT STATE:
    // ---

    if (regexMatchs[1] === 'contractState') {

      if (regexMatchs[2] === 'finished')
        return { 'contract.finished': true };

      if (regexMatchs[2] === 'fullyPaid')
        return { 'contract.fully_paid': true };

      if (regexMatchs[2] === 'finishedSoon')
        return { 'contract.finished_soon': true };

      if (regexMatchs[2] === 'notFinishedSoon')
        return { 'contract.finished_soon': false };

    }

    // ACTIVATION RATE:
    // ---

    if (regexMatchs[1] === 'activationRate.gt')
      return { 'contract.credit_info.activation_rate': { '$gt': parseInt(regexMatchs[2])/100 } };

    if (regexMatchs[1] === 'activationRate.lt')
      return { 'contract.credit_info.activation_rate': { '$lt': parseInt(regexMatchs[2])/100 } };

    // HUB
    // ---

    if (regexMatchs[1] === 'hub')
      return { 'contract.hub': regexMatchs[2] };

    // FREE SEARCH

    return freeSearch;

  }

  getDatabaseFieldFromColumn = (column) => {

    const output = {
      'client': 'contract.customer.name',
      'expiration': 'contract.activation_end_date',
      'dailyConsumption': 'kit.bms.avg_power_7days.consumption',
      'dailyProduction': 'kit.bms.avg_power_7days.production',
      'box': 'contract.code',
      'installationDays': 'installation_date',
      'lastCommunication': 'kit.bms.smartcard.last_communication_timestamp',
      'financialCategory': 'contract.credit_info.financial_category',
      'followupCategory': 'contract.credit_info.followup_category',
      'installationDate': 'installation_date',
      'amountToRecover': 'contract.credit_info.amount_to_recover.value_cents',
      'firstActivationDate': 'contract.first_activation_date',
    };

    if (output[column])
      return output[column];

    return 'contract.customer.name';

  }

  findFromInstallationWithoutMetaData = async (filterSelector, bookmark) => {

    const response = await this.database.find({
      selector: filterSelector,
      limit: this.limit,
      execution_stats: true,
      bookmark: (bookmark ? bookmark : null)
    });

    if (response.docs) {
      const installations = installationUtils.normalize(response.docs);
      return { data: installations, bookmark: response.bookmark };
    }

  }

  getInstallationsIds = (installations) => {

    const installationsIds = [];

    for (const installation of installations) {
      const installationId = installation.installation_id.split('-')[0];
      installationsIds.push(installationId);
    }

    return installationsIds;

  }

  getCustomerIds = (installations) => {

    const customerIds = [];

    for (const installation of installations)
      customerIds.push(installation.client.zoho_id);

    return customerIds;

  }

  isContractCodeSearch (filterSelector) {

    if (!filterSelector['$and'])
      return false;

    if (filterSelector['$and'].length === 2
      && Object.keys(filterSelector['$and'][0])[0] === 'type'
      && Object.keys(filterSelector['$and'][1])[0] === 'contract.code'
      && filterSelector['$and'][1]['contract.code'].length === 6) {

      return true;

    }

    return false;

  }

  selectorOrViewQuery = (filterSelector) => {

    // From a couchDB query, determine if we should use a couchDB selector or a meilisearch query
    // ---

    // Remove "type" and "country" condition

    const filter = filterSelector['$and'].filter((value) => 
      !( ["type", "contract.country_code"].includes( Object.keys(value)[0]) )
    );

    if (filter && filter.length === 1 && Object.keys(filter[0])[0] === 'contract.code')
      return { type: 'meilisearch', search: filter[0]['contract.code'].replace('(?i)', '') }

    if (filter && filter.length === 1 && Object.keys(filter[0])[0] === 'contract.customer.name')
      return { type: 'meilisearch', search: filter[0]['contract.customer.name']['$regex'].replace('(?i)', '') }

    return { type: 'couchdb' };

  }

  findFromInstallation = async (filterSelector, sortProperty, sortOrder, bookmark, columns) => {

    const output = [];

    // From the filter conditions, determinate if it's more accurency to fetch the data from a selector or from a specific view
    
    const queryType = this.selectorOrViewQuery(filterSelector);

    let response = { docs: [] };

    let index = 'installation-all'

    if (filterSelector['$and'].find((filter) => filter['type'] === 'installation') && filterSelector['$and'].find((filter) => filter['contract.country_code'] === 'bf'))
        index = 'installation-bf'
    else if (filterSelector['$and'].find((filter) => filter['type'] === 'installation') && filterSelector['$and'].find((filter) => filter['contract.country_code'] === 'bj'))
        index = 'installation-bj'

    if (index !== '') {

      // REMOVE THE TYPE AND THE COUNTRY FROM THE SELECTOR

      filterSelector['$and'] = filterSelector['$and'].filter((filter) => !filter['type'] && !filter['contract.country_code'])

    }

    if ( queryType.type === 'couchdb' ) {

      const body = {
        selector: filterSelector,
        sort: [{[sortProperty]: sortOrder}],
        use_index: [index],
        limit: this.limit,
        execution_stats: true,
        bookmark: (bookmark ? bookmark : null)
      }

      if (filterSelector['$and'].length === 0)
        body.selector = {}

      response = await this.database.find(body);

    } else if ( queryType.type === 'meilisearch' ) {

      const filters = []

      if (index == 'installation-bf') {
        filters.push('country=bf')
      } else if (index == 'installation-bj') {
        filters.push('country=bj')
      }

      const contractIds = await meilisearch('installations', queryType.search, filters)

      const allInstallationsDocsRepo = new AllInstallationsDocsRepository(this.database);
      const fetchData = await allInstallationsDocsRepo.findByIds(contractIds)

      response = { docs: fetchData, bookmark: 'inop' };

    }

    if (response.docs) {

      const installations = installationUtils.normalize(response.docs);

      // FIND FLAGS, CLIENT CALLBACK AND LAST CLIENT CALL FOR EACH INSTALLATIONS
      // ---

      const flagRepo = new FlagRepository(this.database);
      const clientRepo = new AllCustomerMetadataRepository(this.database);

      let flagsFromView = [];
      let customerDataFromView = [];

      if (!columns || columns.indexOf('client') !== -1)
        flagsFromView = await flagRepo.findByIds( this.getInstallationsIds(installations) );

      if (!columns || columns.indexOf('lastClientCall') !== -1 || columns.indexOf('clientCallback') !== -1)
        customerDataFromView = await clientRepo.findByIds( this.getCustomerIds(installations) );

      for ( const installation of installations ) {

        // FLAGS
        // ---

        let flags = [];

        const regex = /([0-9]+)-[0-9]+/gm;
        const regexMatchs = regex.exec(installation.installation_id);

        if (regexMatchs && regexMatchs[1]) {

          let installationFlags = flagsFromView.filter(flag => flag.key === regexMatchs[1]);
          
          if (installationFlags.length > 0)
            flags = installationFlags.map(flag => (flag.value));

        }

        // CLIENT CALLBACK AND LAST CLIENT CALL
        // ---

        let client_callback = null;
        let last_client_call = null;

        let customerCallbackAndLastCall = customerDataFromView.filter(data => data.key === installation.client.zoho_id);

        if ( customerCallbackAndLastCall.find(data => data.value.type === 'client_callback') )
          client_callback = customerCallbackAndLastCall.find(data => data.value.type === 'client_callback').value.value;

        if ( customerCallbackAndLastCall.find(data => data.value.type === 'last_client_call') )
          last_client_call = customerCallbackAndLastCall.find(data => data.value.type === 'last_client_call').value.value;

        output.push({ ...installation, client: { ...installation.client, last_call: last_client_call, callback_date: client_callback }, flags });

      };

    }

    return { data: output, bookmark: response.bookmark };

  }

  findFromLastClientCall = async (sortOrder, bookmark) => {

    // WE WANT TO SORT BY LAST CLIENT CALL
    // ---

    let output = [];

    const LastClientCallRepo = new LastClientCallRepository(this.database);
    const allCustomerMetadataRepo = new AllCustomerMetadataRepository(this.database);
    const flagRepo = new FlagRepository(this.database);

    let currentBookmark = bookmark;

    do {

      // Make a do while loop because some customer.zoho_id get from type='date_called' are not available in type='installation'
      // Retry this loop until we get at least 20 installations

      const response = await LastClientCallRepo.findAll('date_called', sortOrder, this.limit, currentBookmark);

      for (const clientCalled of response.data) {

        // FIND THE INSTALLATION FOR THIS CUSTOMER
        // ---

        const customerInstallation = await this.database.find({
          selector: {
            type: "installation",
            "contract.customer.zoho_id": clientCalled.client_id.toString(),
          }
        });

        if (!customerInstallation.docs || customerInstallation.docs.length === 0)
          continue;

        let installation = installationUtils.normalize(customerInstallation.docs);
        installation = installation[0];

        // FLAGS
        // ---

        let flags = [];

        const regex = /([0-9]+)-[0-9]+/gm;
        const regexMatchs = regex.exec(installation.installation_id);

        if (regexMatchs && regexMatchs[1]) {

          let flagsFromRepo = await flagRepo.findById(regexMatchs[1]);
          
          if (flagsFromRepo.rows && flagsFromRepo.rows.length > 0)
            flags = flagsFromRepo.rows.map(flag => (flag.value))

        }

        // CLIENT CALLBACK AND LAST CLIENT CALL
        // ---

        let customerCallbackAndLastCall = await allCustomerMetadataRepo.findById(installation.client.zoho_id);

        let client_callback = null;
        let last_client_call = null;

        if ( customerCallbackAndLastCall.rows.find(data => data.value.type === 'client_callback') )
          client_callback = customerCallbackAndLastCall.rows.find(data => data.value.type === 'client_callback').value.value;

        if ( customerCallbackAndLastCall.rows.find(data => data.value.type === 'last_client_call') )
          last_client_call = customerCallbackAndLastCall.rows.find(data => data.value.type === 'last_client_call').value.value;

        output.push( { ...installation, client: { ...installation.client, last_call: last_client_call, callback_date: client_callback }, flags } );

      };

      currentBookmark = response.bookmark;

    } while (output.length < 20)

    return { data: output, bookmark: (currentBookmark ? currentBookmark : null) };

  }

  findFromClientCallback = async (sortOrder, bookmark) => {

    // WE WANT TO SORT BY CLIENT CALLBACK
    // ---

    let output = [];

    const clientCallbackRepo = new ClientCallbackRepository(this.database);
    const allCustomerMetadataRepo = new AllCustomerMetadataRepository(this.database);
    const flagRepo = new FlagRepository(this.database);

    let currentBookmark = bookmark;

    do {

      // Make a do while loop because some customer.zoho_id get from type='callback_date' are not available in type='installation'
      // Retry this loop until we get at least 20 installations

      const response = await clientCallbackRepo.findAll('callback_date', sortOrder, this.limit, currentBookmark);

      for (const clientCallback of response.data) {

        // FIND THE INSTALLATION FOR THIS CUSTOMER
        // ---

        const customerInstallation = await this.database.find({
          selector: {
            type: "installation",
            "contract.customer.zoho_id": clientCallback.client_id,
          }
        });

        if (!customerInstallation.docs || customerInstallation.docs.length === 0)
          continue;

        let installation = installationUtils.normalize(customerInstallation.docs);
        installation = installation[0];

        // FLAGS
        // ---

        let flags = [];

        const regex = /([0-9]+)-[0-9]+/gm;
        const regexMatchs = regex.exec(installation.installation_id);

        if (regexMatchs && regexMatchs[1]) {

          let flagsFromRepo = await flagRepo.findById(regexMatchs[1]);

          if (flagsFromRepo.rows && flagsFromRepo.rows.length > 0)
            flags = flagsFromRepo.rows.map(flag => (flag.value))

        }

        // CLIENT CALLBACK AND LAST CLIENT CALL
        // ---

        let customerCallbackAndLastCall = await allCustomerMetadataRepo.findById(installation.client.zoho_id);

        let client_callback = null;
        let last_client_call = null;

        if ( customerCallbackAndLastCall.rows.find(data => data.value.type === 'client_callback') )
          client_callback = customerCallbackAndLastCall.rows.find(data => data.value.type === 'client_callback').value.value;

        if ( customerCallbackAndLastCall.rows.find(data => data.value.type === 'last_client_call') )
          last_client_call = customerCallbackAndLastCall.rows.find(data => data.value.type === 'last_client_call').value.value;

        output.push( { ...installation, client: { ...installation.client, last_call: last_client_call, callback_date: client_callback }, flags } );

      };

      currentBookmark = response.bookmark;

    } while (output.length < 20)

    return { data: output, bookmark: (currentBookmark ? currentBookmark : null) };

  }
  
  setLimit = (limit) => {
    this.limit = limit;
  }

}
