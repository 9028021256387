import Flag from "contexts/Repository/entity/flag";

export class FlagRepository {

  constructor(instance) {
    this.database = instance;
  }

  findOneById = (docId) => 
    this.database.get(docId);

  findById = (installationId) => {

    const options = {
      key: installationId
    };

    return this.database.query('main/allInstallationsFlags', options);
    
  }

  findByIds = async (installationIds) => {

    const options = {
      keys: installationIds
    };

    const output = await this.database.query('main/allInstallationsFlags', options);

    return output.rows;

  }

  findOne = async (payload) => {

    const output = await this.database.find({
      selector: {
        ...payload,
        type: 'flag'
      }
    });

    if (output.docs && output.docs[0])
      return output.docs[0];

    return null;

  }

  findAll = () =>
    this.fetchPageByPage();

  fetchPageByPage = async () => {

    const output = [];
    let options = { limit: 500 };

    const getData = async (resolve, reject) => {

      if ( output.length > 0 )
        options = {
          ...options,
          skip: 1,
          startkey: output[ output.length - 1 ].installation
        };

      const response = await this.database.query('main/allInstallationsFlags', options);

      if (response.rows.length > 0) {

        await Promise.all(response.rows.map(async (doc) => {
          output.push({_id: doc.id, installation: doc.key, value: doc.value});
        }));

        await getData(resolve, reject);

      } else {

        return resolve(output);

      }

    }

    return new Promise((r, j) => getData(r, j));

  }

  create = (installationId, flag) => {

    const newFlag = new Flag(installationId, flag);
    return this.database.put(newFlag);

  }

  update = (object) => {

  }

  delete = async (installationId, flag) => {

    const flagId = `urn.qotto.flag:installation:${installationId}:${flag}`;
    const flagById = await this.findOneById(flagId);

    if (!flagById)
      throw Error('unknown _id');

    return await this.database.put({ ...flagById, _deleted: true});

  }

}