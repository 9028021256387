import React from "react";
import { compose } from "redux";
import { FormattedMessage as T } from "react-intl";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SendIcon from "@material-ui/icons/Send";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { UserContext, DateContext, RepositoryContext } from "contexts";

import { ChatMessage } from "./components";

import { CustomerChatRepository } from "contexts/Repository/adaptor/pouchDB/customerChatRepository";
import { ChatMessageRepository } from "contexts/Repository/adaptor/pouchDB/chatMessageRepository";
import { ChatSubscriptionRepository } from "contexts/Repository/adaptor/pouchDB/chatSubscriptionRepository";

const SYNCED_SORT_THRESHOLD = 1e3; // 1 second

const styles = {
  form: {
    display: "flex",
    alignItems: "flex-end",
  },
  messageInput: {
    flex: 1,
    marginRight: "12px",
  },
  messagesContainer: {
    maxHeight: "400px",
    overflow: "auto",
    paddingBottom: "24px",
  },
};

class ClientChat extends React.PureComponent {
  state = {
    loadingMessages: false,
    messageContent: "",
    isSubscribed: this.props.box.chatSubscription
  };

  handleChange = e => {
    this.setState({
      messageContent: e.target.value,
    });
  };

  getClientChat = async () => {

    const customerChatRepo = new CustomerChatRepository(this.props.repo.instance.remote);
    const customerChat = await customerChatRepo.findOne(this.props.client.zoho_id);

    if (customerChat)
      return customerChat;

    return customerChatRepo.create(this.props.client.zoho_id);

  };

  onEnterPress = (user, getDate) => e => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      this.onMessageAdd(user, getDate);
    }
  };

  onSubmit = (user, getDate) => async e => {
    e.preventDefault();
    this.onMessageAdd(user, getDate);
    return false;
  };

  onMessageAdd = async (user, getDate) => {

    let { messageContent } = this.state;

    messageContent = messageContent.trim();

    this.setState({
      loadingMessages: true,
    });

    if (messageContent) {

      const clientChat = await this.getClientChat();

      const chatMessageRepo = new ChatMessageRepository(this.props.repo.instance.remote);

      const now = getDate().getTime();

      await chatMessageRepo.create(clientChat.chat_id, user.metadata.displayed_name, user.uuid, now, messageContent);
      await this.props.refreshComments();

      this.setState({
        loadingMessages: false,
        messageContent: "",
      });

    }
  };

  onMessageDelete = message => async () => {

    const chatMessageRepo = new ChatMessageRepository(this.props.repo.instance.remote);
    await chatMessageRepo.delete(message.id);
    this.props.refreshComments();

  };

  unsubscribe = async () => {

    const clientChat = await this.getClientChat();

    const chatSubscriptionRepo = new ChatSubscriptionRepository(this.props.repo.instance.remote);

    const chatSubscription = await chatSubscriptionRepo.findOne( { chat_id: clientChat.chat_id, user_id: this.props.user.uuid } );

    if (chatSubscription && chatSubscription.active) {

      await chatSubscriptionRepo.update( chatSubscription._id, { 'active': false } );
      this.setState({isSubscribed: false});

    }

  };

  subscribe = async () => {

    const clientChat = await this.getClientChat();

    const chatSubscriptionRepo = new ChatSubscriptionRepository(this.props.repo.instance.remote);
    const chatSubscription = await chatSubscriptionRepo.findOne( { chat_id: clientChat.chat_id, user_id: this.props.user.uuid } );

    if ( chatSubscription && !chatSubscription.active )
      await chatSubscriptionRepo.update( chatSubscription._id, { 'active': true } );
    else if (!chatSubscription)
      await chatSubscriptionRepo.create( clientChat.chat_id, this.props.user.uuid, Date.now() );      

    this.setState({isSubscribed: true});

  };

  scrollToBottom = () => {
    if (this.messagesElt) {
      this.messagesElt.scrollTop = this.messagesElt.scrollHeight;
    }
  };

  scrollToMessage = messageId => {
    const element = document.getElementById(messageId);
    if (this.messagesElt && element) {
      this.messagesElt.scrollTop = element.offsetTop - 250;
    }
  };

  componentDidMount() {

    if (!this.props.location.hash) {
      this.scrollToBottom();
    } else {
      const id = this.props.location.hash.substr(1);
      this.scrollToMessage(id);
    }

  }

  componentDidUpdate(prevProps) {

    if (prevProps.messages.length < this.props.messages.length) {
      this.scrollToBottom();
    } else {
      if (prevProps.location.hash !== this.props.location.hash) {
        const id = this.props.location.hash.substr(1);
        this.scrollToMessage(id);
      }
    }

  }

  render() {
    const { classes, messages, client, location, user, chatSubscription } = this.props;
    const { messageContent } = this.state;

    const id = location.hash.substr(1);
    const selectedMessage = this.props.box.chat.find(message => message.id === id);

    const noConversation = this.props.box.chat.length === 0;
    const isSubscribed = this.state.isSubscribed;

    return (
      <DateContext.Consumer>
        {({ getDate }) => (
          <div className={classes.root}>
            {noConversation && (
              <Typography color="textSecondary">
                <T id="noMessage" values={{ client: client.name }} />
              </Typography>
            )}
            {isSubscribed ? (
              <Typography color="textSecondary">
                <T id="userIsSubscribed" />{" "}
                <Button size="small" onClick={this.unsubscribe}>
                  <T id="unsubscribe" />
                </Button>
              </Typography>
            ) : (
              <Typography color="textSecondary">
                <T id="userIsUnsubscribed" />{" "}
                <Button size="small" onClick={this.subscribe}>
                  <T id="subscribe" />
                </Button>
              </Typography>
            )}
            
            {!noConversation && (
              !this.state.loadingMessages ? (
                <div className={classes.messagesContainer} ref={elt => (this.messagesElt = elt)}>
                  {messages.map((message, index) => (
                    <ChatMessage
                      key={message._id}
                      message={message}
                      selected={message === selectedMessage}
                      lastMessage={messages[index - 1]}
                      location={location}
                      canDelete={message.author.user_id === user.uuid}
                      onDelete={this.onMessageDelete(message)}
                    />
                  ))}
                </div>) : <div style={{textAlign:'center', marginTop:25, marginBottom:25}}><CircularProgress /></div>
            )}
            <form className={classes.form} onSubmit={this.onSubmit(user, getDate)}>
              <TextField
                label={<T id={noConversation ? "startConversation" : "clientNewMessage"} />}
                className={classes.messageInput}
                value={messageContent}
                onChange={this.handleChange}
                onKeyDown={this.onEnterPress(user, getDate)}
                multiline
                disabled={this.state.loadingMessages}
                minRows="2"
              />
              <Button disabled={!messageContent.trim()} type="submit">
                <SendIcon />
              </Button>
            </form>
          </div>
        )}
      </DateContext.Consumer>
    );
  }
}

const cmp = (a, b) => (a > b ? 1 : a < b ? -1 : 0);

const cmpByTimestamp = (messageA, messageB) => {
  if (messageA.date_synced && messageB.date_synced) {
    const diff = Math.abs(messageA.date_synced - messageB.date_synced);
    if (diff > SYNCED_SORT_THRESHOLD) {
      return cmp(messageA.date_typed, messageB.date_typed);
    }
  }
  return cmp(messageA.date_typed, messageB.date_typed);
};

ClientChat.propTypes = {
  client: PropTypes.shape({
    zoho_id: PropTypes.string.isRequired,
  }),
};

const ClientChatWrapper = props => (
  <UserContext.Consumer>
    {({ user }) => 
      <RepositoryContext.Consumer>
        {( repo ) => {
          return (
            <ClientChat
              user={user}
              repo={repo}
              messages={props.box.chat}
              {...props}
            />
          );

        }}
      </RepositoryContext.Consumer>
    }
  </UserContext.Consumer>
);

export default compose(withStyles(styles), withRouter)(ClientChatWrapper);
