export default {
  appTitle: "Qotto",
  world: "World",
  home: "Home",
  map: "Map",
  help: "Help",
  contact: "Contact us",
  profile: "Profile",
  share: "Share",
  installations: "Contracts",
  installation: "Contract",
  installationBreadcrumb: "Contract {code}",
  noData: "Missing data",
  pleaseLogin: "Please login in order to access content",
  unauthorized:
    "You don't have any access to the dashboard content. Please sign in again, and contact your supervisor if it still doesn't work.",
  searchBar: "Search...",
  on: "On",
  off: "Off",
  from: "From",
  to: "To",
  timezone: "Timezone {timezone}",
  cancel: "Cancel",
  close: "Close",
  signin: "Sign in",
  signout: "Sign out",
  signinAgain: "Sign in again",
  username: "Username",
  password: "Password",
  updateAvailable: "New update available !",
  quotaTooSmall:
    "You don't have enough available storage to run the app. Please free some space and try again.",
  usageTooHigh: "You are running out of available storage. Please free some space.",
  serviceWorkerNotSupported:
    "The dash doesn't support your browser. Please use the last version of Google Chrome.",
  unknownCouchdbError:
    "An error occured during data fetching. Please report the issue by giving the following details: {errorMessage}",
  reload: "Reload",
  download: "Download",
  copy: "Copy",
  copyMessage: "Copied to the clipboard",
  send: "Send",
  save: "Save",
  delete: "Delete",
  validate: "Validate",
  subscribe: "Subscribe",
  unsubscribe: "Unsubscribe",

  runningInstallationPrefix: "expires",
  expiredInstallationPrefix: "expired",
  expirationDeterminant: "on",
  boxInstalledPrefix: "Box changed on",
  firstActivatedPrefix: "First activated on",
  installationDate: "Installation date",
  firstActivationDate: "First activation date",
  installationDays: "Installation age",
  cheatScore: "Cheat score",
  installationDatePrefix: "Installation created on",
  lastCommunicationPrefix: "Last contact with smartcard on",
  consumption: "consumption",
  columnsOptionsTitle: "Columns selection",
  selectColumns: "Select columns",
  sortOptionsTitle: "Sort options",
  sortBy: "Sort by",
  sortedBy: "Sorted by",
  "sortOrder.asc": "in ascending order",
  "sortOrder.desc": "in descending order",
  "sortOption.client": "client name",
  "sortOption.box": "bms number",
  "sortOption.expiration": "expiration date",
  "sortOption.lastCommunication": "last smartcard contact date",
  "sortOption.dailyProduction": "energy production",
  "sortOption.dailyConsumption": "energy consumption",
  "sortOption.consumptionPercentage": "consumption percentage",
  "sortOption.activationRate": "activation rate",
  "sortOption.lastClientCall": "last client call date",
  "sortOption.clientCallback": "planned client callback",
  "sortOption.financialCategory": "financial category",
  "sortOption.followupCategory": "followup category",
  "sortOption.installationDate": "installation date",
  "sortOption.installationDays": "installation age",
  "sortOption.cheatScore": "cheat score",
  filterOptionsTitle: "Add filter",
  "filterOption.bmsBrand:Biolite": "Show Biolite kits",
  "filterOption.bmsBrand:Victron": "Show Victron kits",
  "filterOption.client": "Filter client names",
  "filterOption.box": "Filter bms numbers",
  "filterOption.sc": "Filter smartcard numbers",
  "filterOption.expiration.gt": "Filter the expiration dates greater than YYYY-MM-DD",
  "filterOption.expiration.lt": "Filter the expiration dates lighter than YYYY-MM-DD",
  "filterOption.inactiveDays.gt": "Filter number of inactive days",
  "filterOption.activationRate.gt": "Activation rate greater than",
  "filterOption.activationRate.lt": "Activation rate lighter than",
  "filterOption.country": "Filter countries",
  "filterOption.status:on": "Show active",
  "filterOption.status:off": "Show inactive",
  "filterOption.status:unsynchronized": "Show unsynchronized",
  "filterOption.status:permanent": "Show permanently actived",
  "filterOption.contractState:finished": "Show finished contracts",
  "filterOption.contractState:fullyPaid": "Show fully paid contracts but not finished",
  "filterOption.contractState:finishedSoon": "Show contracts with less than 2 months to pay",
  "filterOption.contractState:notFinishedSoon": "Show contracts with more than 2 months to pay",

  "filterOption.hub:ALIBORI": "Show contracts for the hub Alibori (Benin)",
  "filterOption.hub:ATLANTIQUE": "Show contracts for the hub Atlantique (Benin)",
  "filterOption.hub:BORGOU": "Show contracts for the hub Borgou (Benin)",
  "filterOption.hub:COLLINES": "Show contracts for the hub Collines (Benin)",
  "filterOption.hub:DONGA": "Show contracts for the hub Donga (Benin)",
  "filterOption.hub:MONO": "Show contracts for the hub Mono (Benin)",
  "filterOption.hub:OUEME": "Show contracts for the hub Oueme (Benin)",
  "filterOption.hub:ZOU": "Show contracts for the hub Zou (Benin)",

  "filterOption.hub:Banfora": "Show contracts for the hub hub Banfora (Burkina)",
  "filterOption.hub:Bobo": "Show contracts for the hub Bobo (Burkina)",
  "filterOption.hub:Dédougou": "Show contracts for the hub Dédougou (Burkina)",
  "filterOption.hub:Fada": "Show contracts for the hub Fada (Burkina)",
  "filterOption.hub:Gaoua": "Show contracts for the hub Gaoua (Burkina)",
  "filterOption.hub:Kaya": "Show contracts for the hub Kaya (Burkina)",
  "filterOption.hub:Koudougou": "Show contracts for the hub Koudougou (Burkina)",
  "filterOption.hub:Koupela": "Show contracts for the hub Koupela (Burkina)",
  "filterOption.hub:Léo": "Show contracts for the hub Léo (Burkina)",
  "filterOption.hub:Manga": "Show contracts for the hub Manga (Burkina)",
  "filterOption.hub:Ouagadougou": "Show contracts for the hub Ouagadougou (Burkina)",
  "filterOption.hub:Po": "Show contracts for the hub Po (Burkina)",
  "filterOption.hub:Yako": "Show contracts for the hub Yako (Burkina)",

  filterOptions: "Filters",
  sortOptions: "Sort options",
  invertSortOrder: "Invert sort order",
  installationTotalCount: `{count, plural,
        =0 {No installation}
        one {# installation}
        other {# installations}
    }`,
  exportPreparation: 'export in progress...',
  installationRelativeCount: "{displayedCount} displayed",
  showMore: "Show more",
  noInstallationFound: "No installation found",
  externalLinks: "External links",
  showOnMap: "Show on map",
  showOnList: "Show list",
  fitMapToInstallations: "Fit map to installations",

  label: "Number",
  client: "Client",
  status: "Status",
  contract: "Contract",
  bmsBrand: "BMS Brand",
  expiration: "Expiration date",
  lastCommunication: "Last smartcard contact",
  dailyProduction: "Daily production",
  dailyConsumption: "Daily consumption",
  consumptionPercentage: "Consumption percentage",
  financialCategory: "Financial category",
  followupCategory: "Followup category",
  financialCategoryInfo: "Client financial category:",
  followupCategoryInfo: "Client followup category:",
  amountToRecover: "Amount to recover",
  amountToRecoverInfo: "Amount to recover on the first of {month}:",
  amountToRecoverExplanation:
    "The amount to recover is set the first day of each month, at midnight UTC. The current value was set on {date}.",
  activationRate: "Activation rate",
  activationRateHelper: "Activation rate",
  activationRateInfo: "Activation rate:",
  weeklyActivationsGrid: "Successful activations grid",
  weeklyActivationsGridInfo: "Weekly contributions grid {helpButton}:",
  weeklyActivationsTooltipRange: "From {fromDate} to {toDate}",
  weeklyActivationsTooltipActivation: `Activated {days, plural,
      one {# day}
      other {# days}
    } out of 7`,
  cheatScoreInfo: "Cheat score:",
  unsynchronized: "unsynchronized",
  energyAvgProduction:
    "Daily production for the last 7 days: {production}, consumption: {consumption} ({percentage}%)",
  last7Days: "7 days",
  last30Days: "30 days",
  last6Months: "6 months",
  invalidDate: "Invalid date",
  signinDialogTitle: "Signin",
  replicatingDatabase: "Data retrieval...",
  codeActivation: "Code activation",
  RefreshCplus: "Resend Canal+ activation",
  generateCode: "Generate activation code",
  requestCode: "Request code",
  activationCode: "Activation code",
  refreshContract: "Refresh contract",
  CanalPlusActivationSent:"The activation has been sent to the Canal+ decoder successfully.",
  refreshContractSent:"The contract has been successfully refreshed in the core.",
  bmsDoesntExist: "This BMS does not seem to be registered in our manufacturer's database. Check if the serial number is correct.",
  decoderDoesntExist: "This Canal+ does not seem to be registered for this contract in our database. Check if the serial number is correct.",
  contractDoesntExist:"Could not find a kit/contract with the provided contract code.",
  deviceServerDoesntRespond: "The activation service doesn't respond, please try again later.",
  invalidRequest:"The request is not valid, please ensure that your information are correct and your contract has not expired.",
  invalidRequestSolaris:"The request is not valid, please ensure that your information including the demand code are correct and your contract has not expired.",
  invalidAuth:"The request is not authenticated, please check your connection.",
  paymentSummary: "Payment summary",
  notesOnClient: "Additional information on client",
  clientNewMessage: "Send a message",
  startConversation: "Start a conversation",
  noMessage: "No message for client {client}.",
  userIsSubscribed: "You are subscribed to chat notifications",
  userIsUnsubscribed: "You are not subscribed to chat notifications",
  flags: "Flags:",
  "flag.no-gsm": "Off grid",
  "flag.no-gsm.description":
    "Flag attributed to installations not covered by the GSM network, therefore always appearing as unsynchronized",
  "flag.cheating": "Cheating",
  "flag.cheating.description": "Flag attributed when the owner appears to be cheating",
  "flag.uninstall-refused": "Client refused",
  "flag.uninstall-refused.description": "Flag attributed when owner is opposing uninstall",
  "flag.vip": "VIP",
  "flag.vip.description":
    "Flag attributed to installations whose owner is a public figure (polititian, influencer…)",
  "flag.push-button": "Push button",
  "flag.push-button.description": "The BMS is equipped with a push button",
  "contractState": "State of the contract",
  "endOfContract": "End of the contract",
  "contract-flags": "End of contract flags (unchangeable) :",
  "flag.contract-finished-soon": "Finished soon",
  "flag.contract-finished-soon.description": "Flag attributed to contracts which are finished soon (less than 2 months to pay)",
  "flag.contract-fully-paid": "Fully paid",
  "flag.contract-fully-paid.description": "Flag attributed to contracts that the client has finished paying",
  "flag.contract-finished": "Finished",
  "flag.contract-finished.description": "Flag attributed to contracts that the client has finished paying and arrived to their expiration date",
  "flag.contract-unfinished": "Unfinished",
  "status:unsynchronized":"Unsynchronized",
  "status:permanent":"Permanently actived",

  showInfos: "Details",
  showKibana: "Telemetry",
  showComments: "Interactions",
  showZohoClient: "Zoho client",
  showZohoContract: "Zoho contract",
  showZohoInstallation: "Zoho installation",
  zohoEditMode: "Switch to edit mode",
  zohoViewMode: "Switch back to view mode",

  changeTimezone: "Change timezone",
  searchTimezone: "Search a timezone",
  currentlyOnTimezone: "Currently on",
  newTimezone: "New timezone:",
  tzSnackbar:
    "Your local timezone changed to {clientTimezone}. Dashboard is now displaying dates in {timezone} timezone.",
  offsetSnackbar: "Your local clock is {offset}. Please fix it.",
  positiveOffset: "ahead",
  negativeOffset: "late",

  lastCallDate: "Last client call date",
  callbackDate: "Client planned callback date",
  now: "Now",

  hub: "Hub",

  reportTitle: "An error occured!",
  reportError: "To help us solve it, could you please tell us what you were trying to achieve ?",
  sendReport: "Report feedback",
  thankYouTitle: "Thank you!",
  thankYouContent: "Thank you for helping us solve this issue.",

  language: "Language:",
  "language.en": "English",
  "language.fr": "French",
  changeLanguage: "Change language",
  changeLanguageTo: "Change language to:",
  currentlyOnLanguage: "Dashboard is currently using",
  openHelp: "Open help",

  "formatted-duration.year": "{value, plural, one {# year} other {# years}}",
  "formatted-duration.month": "{value, plural, one {# month} other {# months}}",
  "formatted-duration.day": "{value, plural, one {# day} other {# days}}",
  "formatted-duration.hour": "{value, plural, one {# hour} other {# hours}}",
  "formatted-duration.minute": "{value, plural, one {# minute} other {# minutes}}",
  "formatted-duration.second": "{value, plural, one {# second} other {# seconds}}",
};
