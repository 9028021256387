import ReactGA from 'react-ga';
import * as Sentry from "@sentry/react";
import { getConfigValue } from "utils/envConfig";

const GOOGLE_ANALYTICS_TRACKER_ID = getConfigValue('REACT_APP_GOOGLE_ANALYTICS_TRACKER_ID');
const DEBUG_MODE = false;

export const googleAnalyticsInit = () => {
  ReactGA.initialize(GOOGLE_ANALYTICS_TRACKER_ID, {
    debug: DEBUG_MODE,
    gaOptions: {
      siteSpeedSampleRate: 100
    }
  });
}

export const logDebugTiming = (className, methodName, delay) => {
  ReactGA.timing({
    category: 'Debug',
    variable: `${className}::${methodName}`,
    value: delay,
    label: process.env.NODE_ENV
  });
};

export const logUXTiming = (name, delay) => {
  ReactGA.timing({
    category: 'User Experience',
    variable: name,
    value: delay,
    label: process.env.NODE_ENV
  });
};

export const logEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label
  });
}

export const logException = (error) => {
  
  Sentry.captureException(error);
  console.error(error);

}

export const logPageView = (path) => {
  ReactGA.pageview(`/${path}`);
}
