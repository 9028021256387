import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { injectIntl } from "react-intl";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import SortIcon from "@material-ui/icons/Sort";
import FilterIcon from "mdi-material-ui/Filter";
import { FormattedMessage as T } from "react-intl";
import { logEvent } from 'utils/googleAnalytics';

const styles = theme => ({
  root: {
    display: "flex",
  },
  inputWrapper: {
    position: "relative",
    minWidth: 0,
    flex: 1,
  },
  tipContainer: {
    position: "absolute",
    top: 6,
    left: 0,
    right: 0,
    opacity: 0.3,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  hiddenTip: {
    visibility: "hidden",
  },
  tip: {
    cursor: "pointer",
  },
});

class Filter extends React.PureComponent {
  constructor(props) {
    super(props);

    const defaultInput = props.defaultValue || "";

    this.state = {
      inputValue: defaultInput,
      sortDialogOpen: false,
      filterDialogOpen: false,
      tips: props.getTips(defaultInput),
    };
  }

  componentDidMount() {
    if (navigator.maxTouchPoints === 0 && window) {
      setTimeout(() => {
        // backward scroll preservation + input focus
        const x = window.scrollX,
          y = window.scrollY;
        this.focusInput();
        window.scrollTo(x, y);
      });
    }
    const { onMountRedirect } = this.props;
    if (onMountRedirect) {
      const { inputValue } = this.state;
      const redirectedInput = onMountRedirect(inputValue);
      if (redirectedInput !== inputValue) {
        this.handleInput(redirectedInput);
      }
    }
  }

  onKeyDown = e => {

    if (e.keyCode === 13) { // PRESS ENTER
      this.props.onUpdate(this.state.inputValue);
      return false;
    }

    if (e.keyCode === 9 && this.state.tips.length) {
      e.stopPropagation();
      e.preventDefault();
      this.handleTip();
      return false;
    }
  };

  selectTip = e => {
    e.stopPropagation();
    e.preventDefault();
    this.handleTip();
    this.focusInput();
    return false;
  };

  handleTip = () => {
    const { inputValue, tips } = this.state;
    this.handleInput(inputValue + tips[0]);
  };

  handleUpdate = inputValue => {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }

    this.updateTimeout = setTimeout(() => {
      this.props.onUpdate(inputValue);
      delete this.updateTimeout;
    }, 2500);
  };

  handleInput = inputValue => {
    let tips = this.props.getTips(inputValue);
    this.setState({ inputValue, tips });
    this.handleUpdate(inputValue);
  };

  onInputChange = event => {
    const { onInputRedirect } = this.props;
    let inputValue = event.target.value;
    if (onInputRedirect) {
      inputValue = onInputRedirect(inputValue);
    }
    this.handleInput(inputValue);
  };

  toggleSortDialog = () => {

    if (!this.state.sortDialogOpen)
      logEvent('CONTRACTS TABLE: SORT', 'SHOW SORTING MODAL');

    this.setState({
      sortDialogOpen: !this.state.sortDialogOpen,
    });
  };

  toggleFilterDialog = () => {

    if (!this.state.filterDialogOpen)
      logEvent('CONTRACTS TABLE: FILTER', 'SHOW FILTER MODAL');

    this.setState({
      filterDialogOpen: !this.state.filterDialogOpen,
    });

  };

  sort = sortOption => () => {
    this.props.sort(sortOption);
    this.toggleSortDialog();
    logEvent('CONTRACTS TABLE: SORT', 'APPLY SORTING OPTION FROM MODAL', sortOption);
  };

  focusInput = () => {
    if (this.inputElt) {
      this.inputElt.focus();
      this.inputElt.selectionStart = this.inputElt.value.length;
      this.inputElt.selectionEnd = this.inputElt.value.length;
    }
  };

  onInputFocus = () => {
    this.setState({
      focus: true,
    });
  };

  onInputBlur = () => {
    this.setState({
      focus: false,
    });
  };

  addFilter = filterOption => () => {

    const isPartial = filterOption.indexOf(":") === -1;
    const filter = filterOption + (isPartial ? ":" : "");
    const newInputValue = filter;

    if (isPartial) {
      setTimeout(this.focusInput, 400);
    }

    logEvent('CONTRACTS TABLE: FILTER', 'SELECT FILTER', filter);
    
    this.setState({
      filterDialogOpen: false,
      inputValue: newInputValue,
      tips: this.props.getTips(newInputValue),
    });

    if (!isPartial) {
      this.props.onUpdate(newInputValue);
    }

  };

  render() {
    const { inputValue, sortDialogOpen, filterDialogOpen, tips, focus } = this.state;
    const { classes, filterOptions, sortOptions } = this.props;
    const t = this.props.intl.formatMessage;

    return (
      <div className={classes.root}>
        <div className={classes.inputWrapper}>
          <TextField
            inputRef={elt => (this.inputElt = elt)}
            name="filter"
            inputProps={{
              autoCapitalize: "none",
            }}
            value={inputValue}
            onChange={this.onInputChange}
            className={classes.input}
            fullWidth
            placeholder={t({ id: "searchBar" })}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
            onKeyDown={this.onKeyDown}
            disabled={this.props.disabled}
          />
          {focus && (
            <div className={classes.tipContainer}>
              <span className={classes.hiddenTip}>{inputValue}</span>
              <span className={classes.tip} onMouseDown={this.selectTip}>
                {tips[0] || ""}
              </span>
            </div>
          )}
        </div>
        <Tooltip title={<T id="filterOptions" />}>
          <IconButton aria-label="Filter" onClick={this.toggleFilterDialog}>
            <FilterIcon />
          </IconButton>
        </Tooltip>
        {this.props.sort && (
          <Tooltip title={<T id="sortOptions" />}>
            <IconButton aria-label="sort" onClick={this.toggleSortDialog}>
              <SortIcon />
            </IconButton>
          </Tooltip>
        )}
        {this.props.invertSortOrder && (
          <Tooltip title={<T id="invertSortOrder" />}>
            <IconButton aria-label="invert-sort-order" onClick={this.props.invertSortOrder}>
              <SortByAlphaIcon />
            </IconButton>
          </Tooltip>
        )}
        <Dialog onClose={this.toggleFilterDialog} open={filterDialogOpen}>
          <DialogTitle>
            <T id="filterOptionsTitle" />
          </DialogTitle>
          <List>
            {filterOptions.map(filterOption => (
              <ListItem key={filterOption} button onClick={this.addFilter(filterOption)}>
                <ListItemText primary={<T id={`filterOption.${filterOption}`} />} />
              </ListItem>
            ))}
          </List>
        </Dialog>
        <Dialog onClose={this.toggleSortDialog} open={sortDialogOpen}>
          <DialogTitle>
            <T id="sortOptionsTitle" />
          </DialogTitle>
          <List>
            {sortOptions.map(sortOption => (
              <ListItem key={sortOption} button onClick={this.sort(sortOption)}>
                <ListItemText
                  primary={
                    <>
                      <T id="sortBy" /> <T id={`sortOption.${sortOption}`} />
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Dialog>
      </div>
    );
  }
}

Filter.propTypes = {
  defaultValue: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  invertSortOrder: PropTypes.func,
  sort: PropTypes.func,
  filterOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  getTips: PropTypes.func.isRequired,
  onInputRedirect: PropTypes.func,
  onMountRedirect: PropTypes.func,
};

export { Filter as FilterComponent };

export default compose(injectIntl, withStyles(styles))(Filter);
