import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { FormattedMessage as T } from "react-intl";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";
import PowerPlugOffIcon from "mdi-material-ui/PowerPlugOff";
import LightbulbOnIcon from "mdi-material-ui/LightbulbOn";

import { boxType } from "types";
import boxProperties from "data/installations/properties";

const BoxStatusIcon = ({ box, ...props }) => {
  switch (boxProperties.status.getValue(box)) {
    case "on":
      return <Tooltip title={<T id={"on"}/>}>
        <LightbulbOnIcon htmlColor={"green"} {...props} />
        </Tooltip>;
    case "off":
      return <Tooltip title={<T id={"off"}/>}>
      <PowerPlugOffIcon htmlColor={"red"} {...props} />
      </Tooltip>;
    case "permanent":
      return <Tooltip title={<T id={"status:permanent"}/>}>
      <LightbulbOnIcon htmlColor={"lightGreen"} {...props} />
      </Tooltip>;
    default:
      return <Tooltip title={<T id={"status:unsynchronized"}/>}>
      <SyncProblemIcon {...props} />
      </Tooltip>;
  }
};

BoxStatusIcon.propTypes = {
  box: boxType.isRequired,
};

export default BoxStatusIcon;
