import React from "react";
import { FormattedMessage as T } from "react-intl";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

import { LoadingButton } from "components";

import { RepositoryContext } from "contexts";
import { ClientNoteRepository } from "contexts/Repository/adaptor/pouchDB/clientNoteRepository";

const styles = {
  form: {
    position: "relative",
    marginTop: "8px",
  },
  submitLine: {
    position: "absolute",
    bottom: "12px",
    right: "12px",
  },
  progressWrapper: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

class ClientNote extends React.Component {

  constructor (props) {

    super(props);

    this.state = {
      defaultContent: props.note.content,
      content: props.note.content,
      processing: false
    }

  }

  handleChange = e => {
    this.setState({
      content: e.target.value,
    });
  };

  cancelChanges = () => {
    this.setState({
      content: this.state.defaultContent,
    });
  };

  onSubmit = async e => {

    e.preventDefault();

    this.setState({ processing: true });

    const { note, client } = this.props;

    const { content, defaultContent } = this.state;

    const hasChanged = content !== defaultContent;

    if (!hasChanged)
      return null;

    const clientNoteRepository = new ClientNoteRepository(this.props.repository.database);

    if (content.trim() === '') {
      await clientNoteRepository.delete(client.zoho_id);
    } else {
      await clientNoteRepository.create(client.zoho_id, content.trim());
    }

    this.setState({
      processing: false,
      defaultContent: this.state.content
    });

    return null;

  };

  render() {

    const { classes, loading } = this.props;
    const { content, defaultContent, processing } = this.state;

    const hasChanged = content !== defaultContent;

    return (
      <form className={classes.form} onSubmit={this.onSubmit}>
        <TextField
          label={<T id="notesOnClient" />}
          fullWidth
          variant="outlined"
          value={content}
          onChange={this.handleChange}
          disabled={loading}
          multiline
          minRows="4"
        />
        {loading && (
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        )}
        {hasChanged && (
          <div className={classes.submitLine}>
            <LoadingButton
              type="submit"
              size="small"
              variant="contained"
              loading={processing}
              disabled={processing}
              color="primary"
            >
              <SaveIcon /> <T id="save" />
            </LoadingButton>
            <Button size="small" disabled={processing} onClick={this.cancelChanges}>
              <T id="cancel" />
            </Button>
          </div>
        )}
      </form>
    );
  }
}

ClientNote.propTypes = {
  client: PropTypes.shape({
    zoho_id: PropTypes.string.isRequired,
  }),
};

const ClientNoteWrapper = props => (
  <RepositoryContext.Consumer>
    {( repository ) => (
      <ClientNote
        repository={repository}
        {...props}
      />
    )}
  </RepositoryContext.Consumer>
);

export default withStyles(styles)(ClientNoteWrapper);
