import React, { Fragment } from "react";
import { FormattedMessage as T } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import HelpIcon from "@material-ui/icons/Help";
import ReactMarkdown from "react-markdown";

import { boxType } from "types";

const styles = {
  helpButton: {
    padding: 0,
    verticalAlign: "top",
    fontSize: "inherit",
  },
};

const explanationMarkdown = `
L'indice de triche va de -100 à 100. Plus il est élevé, plus on est certain que le client triche.

- **S'il est proche de 100** : Le client est de toute évidence un tricheur.
- **S'il est autour de 0** : On ne sait pas s'il triche, ou s'il ne triche pas.
- **S'il est proche de -100** : Le client ne triche pas.
- **S'il n'est pas défini**: On ne dispose pas assez d'information pour émettre une conclusion.
`;

class CheatScoreInfo extends React.PureComponent {
  state = {
    helpDialogOpen: false,
  };

  toggleDialog = () => {
    this.setState({
      helpDialogOpen: !this.state.helpDialogOpen,
    });
  };

  render() {
    const { classes, box } = this.props;
    const { helpDialogOpen } = this.state;

    return (
      <Fragment>
        <Dialog open={helpDialogOpen} onClose={this.toggleDialog}>
          <DialogTitle>
            <T id="cheatScore" />
          </DialogTitle>
          <DialogContent>
            <ReactMarkdown linkTarget="_blank" children={explanationMarkdown} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleDialog}>
              <T id="close" />
            </Button>
          </DialogActions>
        </Dialog>
        <span>
          <T id="cheatScoreInfo" /> <strong>{box.cheat_score}</strong>{" "}
          <IconButton fontSize="inherit" className={classes.helpButton} onClick={this.toggleDialog}>
            <HelpIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Fragment>
    );
  }
}

CheatScoreInfo.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(CheatScoreInfo);
