import { PureComponent, Fragment } from "react";
import { FormattedMessage as T } from "react-intl";
import { logEvent, logException } from 'utils/googleAnalytics';
import RefreshIcon from '@material-ui/icons/Refresh';
import { getConfigValue } from "utils/envConfig";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { boxType } from "types";

const BACKEND_API_ENDPOINT = getConfigValue('REACT_APP_BACKEND_API_ENDPOINT');

const styles = {
  error: {
    color: "red",
  },
};

const RefreshContractError = ({ error }) => {
  switch (error) {
    case "API error (INVALID_REQUEST_FORMAT)": //400 response from the backend
      return <T id="invalidRequest"/>;
    case "API error (COULD_NOT_AUTHENTICATE)": //401 response from the backend
      return <T id="invalidAuth"/>;
    case "API error (DOES_NOT_EXIST)": //404 response from the backend
      return <T id="contractDoesntExist" />;
    case "API error (BACKEND_ERROR)": //503 response from the backend
      return <T id="deviceServerDoesntRespond"/>;
    default:
      return error;
  }
};

class RefreshContract extends PureComponent {

  state = {
    dialogOpen: false,
    loading: false,
    error: null,
  };

  toggleDialog = () => {
    this.setState({
      dialogOpen: !this.state.dialogOpen,
    });
  };

  onRequestRefresh = () => async e => {

    e.preventDefault();

    const { box } = this.props;

    logEvent('REFRESH_CONTRACT', 'REFRESH_CONTRACT', box.contract.code);

    this.setState({
      loading: true,
      error: null,
      dialogOpen: !this.state.dialogOpen,
    });

    const request_url = (
      `${BACKEND_API_ENDPOINT}/contract/${box.contract.code}/refresh`
    );

    try {
      
      const request_response = await fetch(request_url, {
        method: "GET",
      });

      if (request_response.status !== 200) {

        const request_response_text = await request_response.text();

          switch (request_response.status) {
            case 400:
              throw Error("API error (INVALID_REQUEST_FORMAT)");
            case 401:
              throw Error("API error (COULD_NOT_AUTHENTICATE)");
            case 404:
              throw Error("API error (DOES_NOT_EXIST)");
            case 503:
              throw Error("API error (BACKEND_ERROR)");
            default:
              throw Error(request_response_text);
          }
      
      } else {

        this.setState({
          loading: false,
        });

        return true;

      }

    } catch (error) {

      logException(error);

      this.setState({
        error: error.message || String(error),
        loading: false,
      });

    }

    return false;

  }

  render = () => {

    const { classes, box } = this.props;
    const { dialogOpen, error, loading } = this.state;

    if (box.contract.fully_paid)
      return null;

    return (
      <Fragment>
        <Button color="primary" onClick={this.onRequestRefresh()} disabled={loading}>
          <RefreshIcon /> <T id="refreshContract" />
        </Button>
        <Dialog onClose={this.toggleDialog} open={dialogOpen}>
          <DialogTitle>
            <T id="refreshContract" />
          </DialogTitle>
          <DialogContent>
            {loading && <LinearProgress />}
            {!loading && !error&& (
              <p>
                <T id="refreshContractSent" />
              </p>
            )}
            {error && (
              <p className={classes.error}>
                <RefreshContractError error={error} />
              </p>
            )}
          </DialogContent>
        </Dialog>
      </Fragment>
    );

  }

}

RefreshContract.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(RefreshContract);