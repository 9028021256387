import React, { Fragment } from "react";
import { FormattedMessage as T } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import HelpIcon from "@material-ui/icons/Help";
import ReactMarkdown from "react-markdown";

import { boxType } from "types";
import { BoxFinancialCategory } from "components";

const styles = {
  helpButton: {
    padding: 0,
    verticalAlign: "top",
    fontSize: "inherit",
  },
};

const explanationMarkdown = `
Il y a cinq catégories financières différentes, *τ* étant le taux d'activation, *age* étant l'âge de l'installation :

- **A** : 75% ≤ *τ*
- **B** : 60% ≤ *τ* < 75%
- **C** : 40% ≤ *τ* < 60%
- **D** : *τ* < 40% et *age* ≤ 90 jours
- **E** : *τ* < 40% et *age* > 90 jours
`;

class FinancialCategoryInfo extends React.PureComponent {
  state = {
    helpDialogOpen: false,
  };

  toggleDialog = () => {
    this.setState({
      helpDialogOpen: !this.state.helpDialogOpen,
    });
  };

  render() {
    const { classes, box } = this.props;
    const { helpDialogOpen } = this.state;

    return (
      <Fragment>
        <Dialog open={helpDialogOpen} onClose={this.toggleDialog}>
          <DialogTitle>
            <T id="financialCategory" />
          </DialogTitle>
          <DialogContent>
            <ReactMarkdown linkTarget="_blank" children={explanationMarkdown} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleDialog}>
              <T id="close" />
            </Button>
          </DialogActions>
        </Dialog>
        <span>
          <T id="financialCategoryInfo" />{" "}
          <strong>
            <BoxFinancialCategory box={box} />
          </strong>{" "}
          <IconButton fontSize="inherit" className={classes.helpButton} onClick={this.toggleDialog}>
            <HelpIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Fragment>
    );
  }
}

FinancialCategoryInfo.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(FinancialCategoryInfo);
