export default class Flag {

  // _id = urn.qotto.flag:installation:[INSTALLATION_ID]:[FLAG]
  // _id = urn.qotto.flag:installation:000226:cheating

  static type = 'flag';

  constructor (installationId, flag) {

    this._id = `urn.qotto.flag:installation:${installationId}:${flag}`;
    this.type = 'flag';

  }

}