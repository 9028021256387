export const loadConfigInLocalStorage = async () => {

  const replaceNL = str => 
    str.replace(/\\n/g, "\n")

  const checkReactApp = str => {
    const regex = /^REACT_APP_[A-Z0-9_]*/gm;
    return regex.exec(str);
  }

  let reload_is_required = false;
  const jsonConfig = {};

  let config_path = '/config/env.conf';

  if (process.env.NODE_ENV === 'development')
    config_path = '/config/env.local.conf';
  
  const config = await fetch(config_path);
  const textConfig = await config.text();
  const configAsArray = textConfig.split("\n");

  configAsArray.forEach((line) => {
    const splitted = line.split(/=(.*)/s);
    jsonConfig[ splitted[0] ] = splitted[1];
  });

  Object.keys(jsonConfig).forEach(key => {

    if (typeof window !== 'undefined' && key.trim() !== '' && jsonConfig && jsonConfig[key] && jsonConfig[key].trim() !== '' && jsonConfig[key] && localStorage.getItem(key) !== replaceNL(jsonConfig[key])) {
      if (checkReactApp(key)) {
        localStorage.setItem(key, replaceNL(jsonConfig[key]));
        reload_is_required = true;
      }
    }

  });

  if (reload_is_required)
    window.location.reload();

}

export const getConfigValue = key => {

  let value = '';

  if (typeof window !== 'undefined')
    value = localStorage.getItem(key);

  if (value)
    return value;

  if (process && process.env[key])
    return process.env[key];

  return '';

}
