import PropTypes from "prop-types";

import { Filter } from "components";
import boxProperties, { redirect } from "data/installations/properties";
import { getTips, filterRedirect, operatorRedirect } from "utils/filter";

const hubOptions = [
  {
    "country": 'bj',
    "countryTxt": 'Benin',
    "hubs": [
      'ALIBORI',
      'ATLANTIQUE',
      'BORGOU',
      'COLLINES',
      'DONGA',
      'MONO',
      'OUEME',
      'ZOU'
    ]    
  },
  {
    "country": 'bf',
    "countryTxt": 'Burkina',
    "hubs": [
      'Banfora',
      'Bobo',
      'Dédougou',
      'Fada',
      'Gaoua',
      'Kaya',
      'Koudougou',
      'Koupela',
      'Léo',
      'Manga',
      'Ouagadougou',
      'Po',
      'Yako'
    ]     
  },
]

const sortOptions = [
  "box",
  "client",
  "expiration",
  "firstActivationDate",
  "lastCommunication",
  "dailyProduction",
  "dailyConsumption",
  "consumptionPercentage",
  "activationRate",
  "lastClientCall",
  "clientCallback",
  "financialCategory",
  "followupCategory",
  "installationDate",
  "installationDays",
  "cheatScore",
];

const filterOptions = [
  "client",
  "box",
  "sc",
  "bmsBrand:Biolite",
  "bmsBrand:Victron",
  "expiration.gt",
  "expiration.lt",
  "inactiveDays.gt",
  "country",
  "activationRate.gt",
  "activationRate.lt",
  // "status:on",
  // "status:off",
  // "status:unsynchronized",
  // "status:permanent",
  "contractState:finished",
  "contractState:fullyPaid",
  "contractState:finishedSoon",
  "contractState:notFinishedSoon",
];

for (const countryHubs of hubOptions) {

  for (const hub of countryHubs.hubs)
    filterOptions.push(`hub:${hub}`);

}

const BoxFilter = props => (
  <Filter
    filterOptions={filterOptions}
    sortOptions={sortOptions}
    getTips={getTips(boxProperties)}
    onInputRedirect={filterRedirect(redirect)}
    onMountRedirect={inputValue => operatorRedirect(filterRedirect(redirect)(inputValue))}
    disabled={props.disabled}
    {...props}
  />
);

BoxFilter.propTypes = {
  defaultValue: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  invertSortOrder: PropTypes.func,
  sort: PropTypes.func,
  disabled: PropTypes.bool,
};

export default BoxFilter;
