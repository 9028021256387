import React, { useRef, useEffect } from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

const CustomPrimaryColor = ({ color, children }: { color: any; children: any }) => {
  const ref: any = useRef(null);
  let theme = ref.current;
  if (theme === null) {
    // before first render
    theme = createTheme({
      palette: { primary: color },
      typography: {},
    });
  }

  useEffect(() => {
    // persist theme for future renders
    ref.current = theme;
  }, []);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomPrimaryColor;
