import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { injectIntl, FormattedMessage as T } from "react-intl";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ReactMarkdown from "react-markdown";
import Slide from "@material-ui/core/Slide";
import withWidth from "@material-ui/core/withWidth";

class HelpDialog extends React.PureComponent {
  state = {
    content: null,
  };

  componentDidMount() {
    const { intl, route } = this.props;
    const fileUrl = intl.formatMessage({
      id: `_docs.${route.label}`,
    });
    fetch(fileUrl)
      .then(_ => _.text())
      .then(content => {
        this.setState({ content });
      });
  }

  render() {
    const { onClose, open, fullScreen } = this.props;
    const { content } = this.state;
    return (
      <Dialog onClose={onClose} open={open} fullScreen={fullScreen} TransitionComponent={Slide}>
        <DialogContent>
          {content && <ReactMarkdown linkTarget="_blank" children={content} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <T id="close" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

HelpDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  route: PropTypes.object.isRequired,
};

export default injectIntl(withWidth()(HelpDialog));
