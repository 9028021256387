import React, { Fragment } from "react";
import { FormattedMessage as T } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import HelpIcon from "@material-ui/icons/Help";
import ReactMarkdown from "react-markdown";

import { WeeklyActivationsGrid } from "components";

const styles = {
  helpButton: {
    padding: 0,
    verticalAlign: "top",
    fontSize: "inherit",
  },
};

const explanationMarkdown = `
Cette grille affiche les données d’activations par semaine du client depuis le début de son contrat. Chaque cellule représente une semaine et est coloriée en fonction du nombre de jours actifs de la semaine suivant le code couleur suivant :

![A medium cat image](img/grid_info.png)
`;

class WeeklyActivationsGridInfo extends React.PureComponent {
  state = {
    helpDialogOpen: false,
  };

  toggleDialog = () => {
    this.setState({
      helpDialogOpen: !this.state.helpDialogOpen,
    });
  };

  render() {
    const { classes, box } = this.props;
    const { helpDialogOpen } = this.state;

    return (
      <Fragment>
        <Dialog open={helpDialogOpen} onClose={this.toggleDialog}>
          <DialogTitle>
            <T id="weeklyActivationsGrid" />
          </DialogTitle>
          <DialogContent>
            <ReactMarkdown
              linkTarget="_blank"
              components={{
                img: ({node, ...props}) => <img style={{width:'100%'}} {...props} />
              }}
              children={explanationMarkdown}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleDialog}>
              <T id="close" />
            </Button>
          </DialogActions>
        </Dialog>
        <T
          id="weeklyActivationsGridInfo"
          values={{
            helpButton: (
              <IconButton
                fontSize="inherit"
                className={classes.helpButton}
                onClick={this.toggleDialog}
              >
                <HelpIcon fontSize="inherit" />
              </IconButton>
            ),
          }}
        />
        <WeeklyActivationsGrid
          maturityDays={box.activations_summary.maturity_days}
          debtReimbursementDays={box.activations_summary.debt_reimbursement_days}
          firstActivationDate={box.first_activation_date}
          encodedWeeklyContributions={box.activations_summary.weekly_contributions}
          activeDaysWithoutDebtReduction={box.activations_summary.active_days_without_debt_reduction}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(WeeklyActivationsGridInfo);
