import React from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import { FormattedMessage as T, injectIntl } from "react-intl";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import queryString from "query-string";
import DocumentTitle from "react-document-title";
import { logEvent, logUXTiming } from 'utils/googleAnalytics';
import CircularProgress from "@material-ui/core/CircularProgress";
import { setStateAsync } from 'utils/common';
import { BoxClient, LocalLink } from "components";
import PropTypes from "prop-types";
import { zohoClient, zohoContract, zohoInstallation } from "utils/externalServices";
import { installationUtils } from "utils/installation";
import { InfoTab, ZohoTab, CommentsTab, TelemetryTab } from "../";
import { pouchDB } from "contexts/Repository/adaptor/pouchDB/"
import { InstallationRepository } from "contexts/Repository/adaptor/pouchDB/installationRepository";
import { ClientNoteRepository } from "contexts/Repository/adaptor/pouchDB/clientNoteRepository";
import { CustomerChatRepository } from "contexts/Repository/adaptor/pouchDB/customerChatRepository";
import { AllCustomerMetadataRepository } from "contexts/Repository/adaptor/pouchDB/allCustomerMetadataRepository";
import { ChatMessageRepository } from "contexts/Repository/adaptor/pouchDB/chatMessageRepository";
import { FlagRepository } from "contexts/Repository/adaptor/pouchDB/flagRepository";
import { ChatSubscriptionRepository } from "contexts/Repository/adaptor/pouchDB/chatSubscriptionRepository";

const styles = theme => ({
  card: {
    margin: "8px",
  },
  contractCode: {
    color: theme.palette.text.secondary,
  },
});

const defaultTab = "infos";

const tabValues = {
  infos: 0,
  comments: 1,
  kibana: 2,
  zoho_client: 3,
  zoho_contract: 4,
  zoho_installation: 5,
};

class Installation extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      boxId: props.boxId,
      box: {
        note: {
          content: ''
        },
        chat: [],
        client_callback: null,
        last_client_call: null
      },
      loading: true,
      location: props.location,
      search: queryString.parse(props.location.search)
    };

    this.loadInstallationDetails();

  }

  componentDidUpdate = (prevProps) => {

    if (prevProps.location.search === this.props.location.search)
      return null;

    this.setState({
      search: queryString.parse(this.props.location.search)
    });

  }

  loadInstallationDetails = async () => {

    const timingStart = Date.now();
    const database = await pouchDB.createRemoteInstance();

    // 1. LOAD INSTALLATION
    // ---

    let installation = null;

    const installationRepository = new InstallationRepository(database);

    try {

      installation = await installationRepository.findOneById(this.state.boxId);
      installation = installationUtils.normalizeOneInstallation(installation);

    } catch (e) {}

    if (!installation) {
      this.setState({loading: false});
      return null;
    }

    let newState = this.state;

    newState = {
      box: {
        ...this.state.box,
        ...installation
      }
    };

    // 2. LOAD FLAGS
    // ---

    const flagRepo = new FlagRepository(database);

    let flags = [];

    let flagsFromRepo = await flagRepo.findById(this.state.boxId);
        
    if (flagsFromRepo.rows && flagsFromRepo.rows.length > 0)
      flags = flagsFromRepo.rows.map(flag => (flag.value))

    newState = {
      box: {
        ...newState.box,
        flags
      }
    };

    // 3. LOAD CUSTOMER NOTE
    // ---

    const clientNoteRepository = new ClientNoteRepository(database);

    const customerNote = await clientNoteRepository.findOne(installation.client.zoho_id);

    if (customerNote && customerNote.content) {

      newState = {
        box: {
          ...newState.box,
          note: {
            content: customerNote.content
          }
        }
      };

    }

    // 4. LOAD CHAT & CHAT SUBSCRIPTION
    // ---

    const customerChatRepository = new CustomerChatRepository(database);
    const customerChat = await customerChatRepository.findOne(installation.client.zoho_id);

    let chatMessages = [];
    let chatSubscription = false;

    if (customerChat && customerChat.chat_id) {

      const chatSubscriptionRepo = new ChatSubscriptionRepository(database);
      const chatSubscriptionFromDb = await chatSubscriptionRepo.findOne( { chat_id: customerChat.chat_id, user_id: this.props.user.uuid } );

      if (chatSubscriptionFromDb && chatSubscriptionFromDb.active)
        chatSubscription = true;
  
      const chatMessageRepository = new ChatMessageRepository(database);
      chatMessages = await chatMessageRepository.find(customerChat.chat_id);

    }

    if (chatMessages.length > 0) {

      newState = {
        box: {
          ...newState.box,
          chat: chatMessages,
          chatSubscription
        }
      }

    }

    // 5. LOAD CLIENT CALLBACK DATETIME & LAST CLIENT CALL
    // ---

    const clientCallbackRepository = new AllCustomerMetadataRepository(database);
    const customerData = await clientCallbackRepository.findById(installation.client.zoho_id);

    let clientCallback = customerData.rows.find(row => row.value.type === 'client_callback');
    let lastClientCall = customerData.rows.find(row => row.value.type === 'last_client_call');

    if (clientCallback) {

      clientCallback = clientCallback.value.value;

      newState = {
        box: {
          ...newState.box,
          client_callback: clientCallback
        }
      }

    }

    if (lastClientCall) {

      lastClientCall = lastClientCall.value.value;

      newState = {
        box: {
          ...newState.box,
          last_client_call: lastClientCall
        }
      }

    }

    logUXTiming(`load installation details`, (Date.now() - timingStart));

    this.setState({...newState, loading: false});

  }

  refreshComments = async () => {

    const database = await pouchDB.createRemoteInstance();

    const customerChatRepository = new CustomerChatRepository(database);
    const customerChat = await customerChatRepository.findOne(this.state.box.client.zoho_id);

    let chatMessages = [];

    if (customerChat && customerChat.chat_id) {

      const chatMessageRepository = new ChatMessageRepository(database);
      chatMessages = await chatMessageRepository.find(customerChat.chat_id);

    }

    if (chatMessages)
      await setStateAsync({ box: { ...this.state.box, chat: chatMessages } }, this);

    return null;

  }

  addFlag = async (flag) => {

    const flagRepo = new FlagRepository(this.props.repo.instance.remote);
    const creationResponse = await flagRepo.create(this.state.boxId, flag);

    if (creationResponse && creationResponse.ok) {

      this.setState({
        box: {
          ...this.state.box,
          flags:[
            ...this.state.box.flags,
            flag
          ]
        }
      });

    }

    return null;

  }

  removeFlag = async (flag) => {
    
    const flagRepo = new FlagRepository(this.props.repo.instance.remote);
    const deletionResponse = await flagRepo.delete(this.state.boxId, flag);

    if (deletionResponse && deletionResponse.ok) {

      this.setState({
        box: {
          ...this.state.box,
          flags: this.state.box.flags.filter(thisFlag => thisFlag !== flag)
        }
      });

    }

    return null;

  }

  render = () => {

    const { classes } = this.props;

    const t = this.props.intl.formatMessage;
    const tab = this.state.search.show || defaultTab;

    if (this.state.loading)
      return (<div style={{padding:35, textAlign:'center', height:50}}><CircularProgress /></div>);

    if (!this.state.box || !this.state.box.contract)
      return (<div style={{padding:35, textAlign:'center', height:50}}>une erreur s'est produite lors du chargement de l'installation</div>);

    if (tab.toUpperCase() === 'INFOS')
      logEvent('CONTRACT', 'SELECT CONTRACT', this.state.box.contract.code);
    else
      logEvent('CONTRACT', `SELECT ${tab.toUpperCase()} TAB`, `${this.state.box.contract.code}::${tab.toUpperCase()}`);

    return (
      <Card className={classes.card}>
        <DocumentTitle
          title={`${t({ id: "installationBreadcrumb" }, this.state.box.contract)} - ${t({
            id: "appTitle",
          })}`}
        />
        <CardHeader
          title={
            this.state.box.kit
              ? (
                <span>
                  <BoxClient box={this.state.box} />
                  {" - Contrat #" + this.state.box.contract.code + " "}
                  <span className={classes.contractCode}>
                    ({this.state.box.kit.bms.brand}{this.state.box.kit.canalplus_decoder ? ' avec Canal+': ''})
                  </span>
                </span>
              )
              : (
                <span>
                  <BoxClient box={this.state.box} />
                  {` - Contrat #${this.state.box.contract.code} `}
                  <span className={classes.contractCode}>
                    (BMS {this.state.box.bms_label}{"/"}{this.state.box.sc_label})
                  </span>
                </span>
              )
          }
        />
        <Tabs
          value={tabValues[tab]}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={<T id="showInfos" />} component={LocalLink} to={this.state.location.pathname} />
          <Tab
            label={<T id="showComments" />}
            component={LocalLink}
            to={this.state.location.pathname + "?show=comments"}
          />
          <Tab
            label={<T id="showKibana" />}
            component={LocalLink}
            to={this.state.location.pathname + "?show=kibana"}
          />
          <Tab
            label={<T id="showZohoClient" />}
            component={LocalLink}
            to={this.state.location.pathname + "?show=zoho_client"}
          />
          <Tab
            label={<T id="showZohoContract" />}
            component={LocalLink}
            to={this.state.location.pathname + "?show=zoho_contract"}
          />
          {this.state.box.zoho_id && (
            <Tab
              label={<T id="showZohoInstallation" />}
              component={LocalLink}
              to={this.state.location.pathname + "?show=zoho_installation"}
            />
          )}
        </Tabs>
        <Divider />
        <CardContent>
          {tab === "infos" ? (
            <InfoTab addFlag={this.addFlag} removeFlag={this.removeFlag} box={this.state.box} />
          ) : tab === "comments" ? (
            <CommentsTab box={this.state.box} refreshComments={this.refreshComments} />
          ) : tab === "kibana" ? (
            <TelemetryTab box={this.state.box} />
          ) : tab === "zoho_client" ? (
            <ZohoTab
              zohoService={zohoClient}
              zohoArgs={{
                id: this.state.box.client.zoho_id,
                project: this.state.box.zoho_project,
              }}
            />
          ) : tab === "zoho_contract" ? (
            <ZohoTab
              zohoService={zohoContract}
              zohoArgs={{
                id: this.state.box.contract.zoho_id,
                project: this.state.box.zoho_project,
              }}
            />
          ) : tab === "zoho_installation" && this.state.box.zoho_id ? (
            <ZohoTab
              zohoService={zohoInstallation}
              zohoArgs={{
                id: this.state.box.zoho_id,
                project: this.state.box.zoho_project,
              }}
            />
          ) : null}
        </CardContent>
      </Card>
    );
  }
}

Installation.propTypes = {
  boxId: PropTypes.number.isRequired
};

export default compose(withStyles(styles), injectIntl, withRouter)(Installation);
