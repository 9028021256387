import React from "react";
import { FormattedMessage as T } from "react-intl";
import Tooltip from "@material-ui/core/Tooltip";

import { CustomPrimaryColor } from "components";
import allFlags from "flags";

interface BoxFlagsProps {
  box: any;
}

const BoxFlags = (props: BoxFlagsProps) => {

  const { box } = props;

  if (!box.flags || box.flags.length === 0) return null;

  return box.flags.map((flag: string) => {

    const flagDetails = allFlags.find(thisFlag => thisFlag.name === flag);

    if (flagDetails)
      return (
        <CustomPrimaryColor key={flagDetails.name} color={flagDetails.color}>
          <Tooltip title={<T id={`flag.${flagDetails.name}`} />}>
            <flagDetails.icon fontSize="inherit" color="primary" />
          </Tooltip>
        </CustomPrimaryColor>
      );

  });
  
};

export default BoxFlags;
