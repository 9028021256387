import uuidv4 from "uuid/v4";

export default class ChatSubscription {

  constructor () {

    this._id = `urn.qotto.chat_subscription:${uuidv4()}`;
    this.type = 'chat_subscription';
    this.chat_id = '';
    this.user_id = '';
    this.active = true;
    this.date_subscribed = 0;

  }

  pattern = () => {

    // The pattern is used for the updating action.
    // Only the fields in the following array are accepted for updating

    return [
      'chat_id',
      'user_id',
      'active',
      'date_subscribed'
    ];

  }

  setChatId = (value) => {
    this.chat_id = value;
  }

  setUserId = (value) => {
    this.user_id = value
  }

  setDateSubscribed = (value) => {
    this.date_subscribed = value;
  }

  get = () => {

    return {
      _id: this._id,
      type: this.type,
      chat_id: this.chat_id,
      user_id: this.user_id,
      active: this.active,
      date_subscribed: this.date_subscribed
    }

  }

}