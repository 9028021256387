import React from "react";
import uuidv4 from "uuid/v4";
import { FormattedMessage as T } from "react-intl";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { withStyles } from "@material-ui/core/styles";

import { LoadingButton } from "components";

import { DateContext, RepositoryContext } from "contexts";

import { LastClientCallRepository } from "contexts/Repository/adaptor/pouchDB/lastClientCallRepository";

const styles = {
  form: {
    position: "relative",
    marginTop: "8px",
    marginBottom: "24px",
  },
  inputLine: {
    display: "flex",
    alignItems: "flex-end",
  },
  datetimeInput: {
    minWidth: "138px",
  },
  submitLine: {
    marginTop: "8px",
  },
};

const stripSeconds = date => {
  const res = new Date(date.getTime());
  res.setSeconds(0, 0);
  return res;
};

const toInput = timestamp => {
  if (!timestamp) {
    return "";
  }
  let date = new Date(timestamp);
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1e3);
  date = stripSeconds(date);
  return date.toISOString().split("Z")[0];
};

const fromInput = isoString => {
  const date = new Date(isoString);

  return date.getTime() || null;
};

class LastClientCall extends React.PureComponent {

  state = {};

  static getDerivedStateFromProps = (props, state) => {

    let defaultCallDate;

    if (!state.defaultCallDate)
      defaultCallDate = props.box.last_client_call;
    else
      defaultCallDate = state.defaultCallDate;

    if (defaultCallDate !== state.defaultCallDate) {

      return {
        defaultCallDate,
        date_called: props.box.last_client_call,
        loading: false,
      };

    }

    return state;

  };

  handleChange = e => {
    this.setState({
      date_called: fromInput(e.target.value),
    });
  };

  cancelChanges = () => {
    this.setState({
      date_called: this.state.defaultCallDate,
    });
  };

  setToNow = () => {
    const now = this.props.getDate();
    this.setState({
      date_called: now.getTime(),
    });
  };

  onSubmit = async e => {

    e.preventDefault();

    this.setState({ loading: true });

    const { client } = this.props;
    const { date_called, defaultCallDate } = this.state;
    
    const hasChanged = (date_called && date_called !== defaultCallDate);

    if (!hasChanged)
      return null;

    const lastClientCallRepo = new LastClientCallRepository(this.props.repo.instance.remote);

    const lastCallDoc = await lastClientCallRepo.findOne(client.zoho_id);

    if (lastCallDoc && lastCallDoc._id)
      await lastClientCallRepo.update(lastCallDoc._id, { date_called });
    else
      await lastClientCallRepo.create(client.zoho_id, date_called);

    this.setState({ loading: false, date_called, defaultCallDate: date_called });

    return null;

  };

  render () {

    const { classes } = this.props;
    const { loading } = this.state;

    let date_called = null;
    let defaultCallDate = null;

    if (this.state.date_called)
      date_called = new Date(this.state.date_called).toISOString().split('T')[0];

    if (this.state.defaultCallDate)
      defaultCallDate = new Date(this.state.defaultCallDate).toISOString().split('T')[0];

    const hasChanged = date_called !== defaultCallDate;

    return (
      <form className={classes.form} onSubmit={this.onSubmit}>
        <div className={classes.inputLine}>
          <TextField
            label={<T id="lastCallDate" />}
            name="lastCallDate"
            type="datetime-local"
            value={toInput(date_called)}
            onChange={this.handleChange}
            className={classes.datetimeInput}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button size="small" onClick={this.setToNow}>
            <T id="now" />
          </Button>
        </div>
        {hasChanged && (
          <div className={classes.submitLine}>
            <LoadingButton
              type="submit"
              size="small"
              loading={loading}
              disabled={loading}
              className={classes.saveButton}
              variant="contained"
              color="primary"
            >
              <SaveIcon /> <T id="save" />
            </LoadingButton>{" "}
            <Button size="small" disabled={loading} onClick={this.cancelChanges}>
              <T id="cancel" />
            </Button>
          </div>
        )}
      </form>
    );
  }
}

LastClientCall.propTypes = {
  client: PropTypes.shape({
    zoho_id: PropTypes.string.isRequired,
  }),
};

const LastClientCallWrapper = props => (
  <DateContext.Consumer>
    {({ getDate }) => (
      <RepositoryContext.Consumer>
        {( repo ) => (
          <LastClientCall
            getDate={getDate}
            repo={repo}
            {...props}
          />
        )}
      </RepositoryContext.Consumer>
    )}
  </DateContext.Consumer>
);

export default withStyles(styles)(LastClientCallWrapper);
