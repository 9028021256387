import React from "react";
import { pouchDB } from "./adaptor/pouchDB/"
import { InstallationRepository } from "./adaptor/pouchDB/installationRepository";
import { InstallationsMapRepository } from "./adaptor/pouchDB/installationsMapRepository";
import { logEvent, logUXTiming, logException } from 'utils/googleAnalytics';
import * as Sentry from "@sentry/react";

const RepositoryContext = React.createContext();

export default RepositoryContext;

class Provider extends React.Component {

  constructor(props) {

    super(props);

    this.installationRepo = null;
    this.flagsRepo = null;

    this.state = {
      instance: {
        local: null,
        remote: null
      },
      database: null,
      installations: [],
      installationsExport: {
        installations: [],
        dataFromCustomerId: [],
        dataFromInstallationId: []
      },
      selectedInstallation: {},
      selectedSortingColumn: '',
      selectedSortingOrder: '',
      selectedFilter: [],
      createDatabases: this.createDatabases,
      applyFilter: this.applyFilter,
      applyExport: this.applyExport,
      loadNextPage: this.loadNextPage,
      setLoader: this.setLoader,
      loadInstallationsMap: this.loadInstallationsMap,
      allIsLoaded: false,
      bookmark: '',
      loading: false,
      error: null
    };

  }

  setLoader = async (bool) => {

    await this.asyncSetState(state => ({
      loading: bool
    }));

  }

  applyExport = async (filter, sortProperty, order) => {

    try {

      Sentry.addBreadcrumb({
        category: "model",
        message: `applyExport('${JSON.stringify(filter)}', '${sortProperty}', '${order}')`,
        level: "info",
      });

      const timingStart = Date.now();
      logEvent('EXPORT', 'APPLY EXPORT (MAP/CSV)', JSON.stringify(filter));

      if (!this.state.instance.remote)
        await this.createDatabases();

      if (!this.installationRepo)
        this.installationRepo = new InstallationRepository(this.state.instance.remote);

      const output = await this.installationRepo.fetchAllPageByPage(filter, sortProperty, order);

      logUXTiming(`apply export loading (map/csv)`, (Date.now() - timingStart));

      return output;

    } catch (e) {

      if (!this.state.error) {

        logException(e);

        this.setState({
          error: 'DATABASE_QUERY_ERROR (APPLY_EXPORT)'
        });

      }

    }

  }

  splitAndCondition = (filters) => {

    // If the filter is "expiration.gt:2022-10-12 and expiration.lt:2022-12-30", this function splite the string as an array ["expiration.gt:2022-10-12", "expiration.lt:2022-12-30"]

    if (!filters)
      return [];

    let output = [];

    filters.forEach((filter) => {

      if (filter.includes(' and '))
        output = output.concat( filter.split(' and '));
      else
        output.push(filter);

    });

    return output;

  }

  applyFilter = async (filter, sortProperty, order, columns) => {

    filter = this.splitAndCondition(filter);

    Sentry.addBreadcrumb({
      category: "model",
      message: `applyFilter('${JSON.stringify(filter)}', '${sortProperty}', '${order}', '${columns}')`,
      level: "info",
    });

    if (this.state.loading)
      return null;

    try {

      const timingStart = Date.now();
      logEvent('FILTERS', 'APPLY FILTERS (TABLE/MAP)', JSON.stringify(filter));

      await this.asyncSetState(state => ({
        loading: true,
        allIsLoaded: false,
        installations: [],
        selectedSortingColumn: sortProperty,
        selectedSortingOrder: order,
        selectedFilter: filter
      }));

      if (!this.state.instance.remote)
        await this.createDatabases();

      if (!this.installationRepo)
        this.installationRepo = new InstallationRepository(this.state.instance.remote);

      const installations = await this.installationRepo.findAllWithFilter(filter, sortProperty, order, null, columns);

      this.setState({
        installations: installations.data,
        bookmark: installations.bookmark,
        loading: false
      });

      logUXTiming(`apply filter loading`, (Date.now() - timingStart));

    } catch (e) {

      if (!this.state.error) {

        logException(e);

        this.setState({
          error: 'DATABASE_QUERY_ERROR (APPLY_FILTER)'
        });

      }

    }

  }

  loadNextPage = async (columns) => {

    if (this.state.loading || this.state.allIsLoaded)
      return null;

    if (this.state.bookmark === 'inop') {

      this.setState( {
        allIsLoaded: true
      });

      return null;

    }
      
    try {

      Sentry.addBreadcrumb({
        category: "model",
        message: `loadNextPage()`,
        level: "info",
      });

      const timingStart = Date.now();

      await this.asyncSetState(state => ({
        loading: true
      }));

      const installations = await this.installationRepo.findAllWithFilter(this.state.selectedFilter, this.state.selectedSortingColumn, this.state.selectedSortingOrder, this.state.bookmark, columns);

      if (installations && installations.data && installations.data.length > 0) {

        this.setState( {
          installations: this.state.installations.concat( installations.data ),
          bookmark: installations.bookmark,
          loading: false
        });

      } else {

        this.setState( {
          allIsLoaded: true,
          loading: false
        });

      }

      logUXTiming(`next page loading`, (Date.now() - timingStart));

    } catch (e) {

      if (!this.state.error) {

        logException(e);

        this.setState({
          error: 'DATABASE_QUERY_ERROR (NEXT_PAGE)'
        });

      }

    }

  }

  asyncSetState = async nextState => {
    await new Promise(resolve => this.setState(nextState, resolve));
  };

  createDatabases = async () => {

    try {

      const remote = await pouchDB.createRemoteInstance();

      await this.asyncSetState(state => ({
        instance: {
          remote
        },
        database: remote
      }));

      return true;      

    } catch (e) {

      logException(e);
    
      await this.asyncSetState(state => ({
        error: e.message
      }));

    }

  }

  loadInstallationsMap = async (country) => {

    try {

      const timingStart = Date.now();

      await this.createDatabases();
      const installationsMapRepo = new InstallationsMapRepository(this.state.instance.remote);
      const output = await installationsMapRepo.findAll(country);

      logUXTiming(`load installation map`, (Date.now() - timingStart));

      return output;

    } catch (e) {

      if (!this.state.error) {

        logException(e);

        this.setState({
          error: 'DATABASE_QUERY_ERROR (LOAD_INSTALLATIONS_MAP)'
        });

      }

    }

  }

  render = () => {
    return (
      <RepositoryContext.Provider value={this.state}>
        {this.props.children}
      </RepositoryContext.Provider>
    );
  }

}

export { Provider };