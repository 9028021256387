import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import "typeface-roboto";
import theme from "./mui-theme";
import { Layout, ScrollToTop, I18nProvider } from "scenes";
import "./app.css";
import { checkEnvKeys } from "./env";
import {
  DateProvider,
  SWProvider,
  PouchdbProvider,
  InstallationsProvider,
  CountryProvider,
  UserProvider,
  RepositoryProvider
} from "contexts";

checkEnvKeys();

const App = () => (
  <UserProvider>
    <DateProvider>
      <I18nProvider>
        <BrowserRouter>
          <CountryProvider>
            <SWProvider>
              <RepositoryProvider>
                <PouchdbProvider>
                  <InstallationsProvider>
                    <ThemeProvider theme={theme}>
                      <ScrollToTop>
                        <Layout />
                      </ScrollToTop>
                    </ThemeProvider>
                  </InstallationsProvider>
                </PouchdbProvider>
              </RepositoryProvider>
            </SWProvider>
          </CountryProvider>
        </BrowserRouter>
      </I18nProvider>
    </DateProvider>
  </UserProvider>
);

export default App;
