import PropTypes from "prop-types";
import { compose } from "redux";
import { FormattedMessage as T } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Installation } from "./components";
import { CouchAuth } from "components";
import { RepositoryContext, UserContext } from "contexts";

const styles = {
  noInstallationCard: {
    margin: "8px",
    padding: "16px 8px",
  },
};

const InstallationScene = ({ match, classes }) => {
  return (
    <CouchAuth>
      <UserContext.Consumer>
        {({user}) => (
          <RepositoryContext.Consumer>
            {(repo) => {
              return match.params.contract_code ? (
                <Installation user={user} repo={repo} boxId={match.params.contract_code} />
              ) : (
                <Card className={classes.noInstallationCard}>
                  <CardContent>
                    <T id="noInstallationFound" tagName="div" />
                  </CardContent>
                </Card>
              );
            }}
          </RepositoryContext.Consumer>
        )}
      </UserContext.Consumer>
    </CouchAuth>
  )

};

InstallationScene.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      contract_code: PropTypes.string,
      bms_label: PropTypes.string,
    }),
  }).isRequired,
};

export default compose(withStyles(styles))(InstallationScene);

//Breadcrumb component
const Breadcrumb = ({ match }) => (
  <RepositoryContext.Consumer>
    {(repo) => {
      return (match && match.params && match.params.contract_code) ? <T id="installationBreadcrumb" values={{code: match.params.contract_code}} /> : "Contrat";
    }}
  </RepositoryContext.Consumer>
);

export { Breadcrumb };
