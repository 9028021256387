import React, { PureComponent, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { FormattedMessage as T } from "react-intl";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import RefreshIcon from '@material-ui/icons/Refresh';
import { boxType } from "types";
import { getConfigValue } from "utils/envConfig";

const BACKEND_API_ENDPOINT = getConfigValue('REACT_APP_BACKEND_API_ENDPOINT');

const styles = {
  error: {
    color: "red",
  },
};

const RefreshCplusError = ({ error }) => {
  switch (error) {
    case "API error (INVALID_REQUEST_FORMAT)": //400 response from the backend
      return <T id="invalidRequest"/>;
    case "API error (COULD_NOT_AUTHENTICATE)": //401 response from the backend
      return <T id="invalidAuth"/>;
    case "API error (DOES_NOT_EXIST)": //404 response from the backend
      return <T id="decoderDoesntExist" />;
    case "API error (BACKEND_ERROR)": //503 response from the backend
      return <T id="deviceServerDoesntRespond"/>;
    default:
      return error;
  }
};

class RefreshCplus extends PureComponent {
  state = {
    dialogOpen: false,
    loading: false,
    error: null,
  };

  toggleDialog = () => {
    this.setState({
      dialogOpen: !this.state.dialogOpen,
    });
  };

  handleChange = name => e => {
    this.setState({
      [name]: e.target.value,
    });
  };

  onRequestRefresh = () => e => {
    e.preventDefault();
    const { box } = this.props;

    this.setState({
      loading: true,
      error: null,
      dialogOpen: !this.state.dialogOpen,
    });
    var request_url;
    request_url = (
        `${BACKEND_API_ENDPOINT}/contract/${box.contract.code}/canalplusdecoder/${box.kit.canalplus_decoder.serial_number}/refresh_access_rights`
    );
    fetch(request_url, {
      method: "GET",
    })
      .then(res => {
        if (res.status !== 200) {
          return res.text().then(error => {
            switch (res.status){
              case 400:
                throw Error("API error (INVALID_REQUEST_FORMAT)");
              case 401:
                throw Error("API error (COULD_NOT_AUTHENTICATE)");
              case 404:
                throw Error("API error (DOES_NOT_EXIST)");
              case 503:
                throw Error("API error (BACKEND_ERROR)");
              default:
                throw Error(error);
            }
          });
        } else {
          this.setState({
            loading: false,
          })
          return true;
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({
          error: error.message || String(error),
          loading: false,
        });
      });
    this.setState({
      loadin: false,
    })
    return false;
  }

  render() {
    const { classes, box } = this.props;
    const { dialogOpen, error, loading } = this.state;
    try {
      var enable = box.kit.canalplus_decoder ? true : false;
    } catch (TypeError) {
      return (
        <Fragment>

        </Fragment>
      );
    }
    if (enable === true){
      return (
        <Fragment>
          <Button color="primary" onClick={this.onRequestRefresh()} disabled={!enable || loading}>
            <RefreshIcon /> <T id="RefreshCplus" />
          </Button>
          <Dialog onClose={this.toggleDialog} open={dialogOpen}>
            <DialogTitle>
              <T id="RefreshCplus" />
            </DialogTitle>
            <DialogContent>
              {loading && <LinearProgress />}
              {!loading && !error&& (
                <p>
                  <T id="CanalPlusActivationSent" />
                </p>
              )}
              {error && (
                <p className={classes.error}>
                  <RefreshCplusError error={error} />
                </p>
              )}
            </DialogContent>
          </Dialog>
        </Fragment>
      );
    }
    return (
        <Fragment>

        </Fragment>
      );
  }

}

RefreshCplus.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(RefreshCplus);
