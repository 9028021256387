export class InstallationsMapRepository {

  constructor(instance) {
    this.database = instance;
  }

  findAll = async (country) => {

    let options = {};

    if (country)
      options = {
        key: country
      };

    let queryOutput = await this.database.query('main/installationsMap', options);

    if (!queryOutput.rows)
      return [];

    return queryOutput.rows.map(row => 
      ({...row.value, _id: row.id})
    );
    
  }

}