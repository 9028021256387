export default class ClientNote {

  constructor (customerId) {

    this._id = `urn.qotto.client_note:${customerId}`;
    this.type = 'client_note';
    this.note_id = customerId;

  }

  pattern = () => {
    return [
      'content'
    ];
  }

  setContent = (value) => {
    this.content = value;
  }

  get = () => {

    return {
      _id: this._id,
      type: this.type,
      note_id: this.note_id,
      content: this.content
    }

  }

}