import ClientNote from "contexts/Repository/entity/client_note";

export class ClientNoteRepository {

  constructor(instance) {
    this.database = instance;
  }

  findOneById = (docId) => 
    this.database.get(docId);

  find = async (payload) => {

    const output = await this.database.find({
      selector: {
        ...payload,
        type: 'client_note'
      }
    });

    if (output.docs)
      return output.docs;
    
    return null;
    
  }

  findOne = async (zohoCustomerId) => {

    const output = await this.database.find({
      selector: {
        _id: `urn.qotto.client_note:${zohoCustomerId}`
      }
    });

    if (output.docs && output.docs[0])
      return output.docs[0];
    
    return null;

  }

  findAll = async () => {

    return null;

  }

  create = async (customerId, content) => {

    const newClientNote = new ClientNote(customerId);

    // CHECK IF THE _ID EXISTS
    // ---

    let clientNoteById;

    try {
      clientNoteById = await this.findOneById( newClientNote.get()._id );
    } catch (error) {
      console.error(error);
    }

    if ( clientNoteById ) {

      // THIS CLIENT NOTE ALREADY EXISTS, UPDATE IT INSTEAD
      return this.update(customerId, content);

    }

    newClientNote.setContent(content);

    return await this.database.put(newClientNote);

  }

  update = async (customerId, content) => {

    // CHECK IF THE _ID EXISTS
    // ---

    let clientNoteById;

    try {
      clientNoteById = await this.findOneById( `urn.qotto.client_note:${customerId}` );
    } catch (error) {
      throw Error('unknown _id');
    }
    
    // UPDATE IN THE DATABASE
    // ---

    return this.database.put({ ...clientNoteById, content });

  }

  delete = async (customerId) => {

    const noteId = `urn.qotto.client_note:${customerId}`;
    const noteById = await this.findOneById(noteId);

    if (!noteById)
      throw Error('unknown _id');

    return this.database.put({ ...noteById, _deleted: true});

  }

}