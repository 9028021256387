const installationUtils = {};

installationUtils.normalize = (installations) =>
  installations.map(row => translateDoc(row));

installationUtils.normalizeOneInstallation = (installation) => translateDoc(installation);

const translateDoc = doc => {

  if (doc.type !== "installation")
    return doc;

  if (!doc.version || doc.version === 1)
    return doc;

  const translated_doc = {};

  translated_doc._id = doc._id;
  translated_doc.type = doc.type;
  translated_doc.zoho_id = doc.zoho_id;
  translated_doc.installation_date = doc.installation_date;
  translated_doc.box_associated_date = null;

  if (doc.kit_associated_date)
    translated_doc.box_associated_date = doc.kit_associated_date;
  else
    translated_doc.box_associated_date = doc.installation_date;

  //I'm not sure about the following field, it's not used besides tests and its not genrated in the aidp
  translated_doc.created_timestamp = Date.now();
  translated_doc.gps_coordinates = null;
  if (doc.geolocation && doc.geolocation.lat && doc.geolocation.lon){
    translated_doc.gps_coordinates = {
      lat: doc.geolocation.lat,
      lon: doc.geolocation.lon,
    }
  }
  translated_doc.installation_id = null;
  translated_doc.expiration_timestamp = null;
  translated_doc.zoho_project = null;
  translated_doc.client = {};
  translated_doc.contract = {};
  translated_doc.credit_info = null;
  translated_doc.first_activation_date = null;
  if (doc.contract){
    translated_doc.installation_id = doc.contract.code + "-0000";
    translated_doc.expiration_timestamp = doc.contract.activation_end_date ? iso2timestamp(doc.contract.activation_end_date) : null;
    if (doc.contract.country_code === 'bf'){
      translated_doc.zoho_project = "burkina";
    }
    else if (doc.contract.country_code === 'bj'){
      translated_doc.zoho_project = "benin";
    }
    translated_doc.contract.code = doc.contract.code;
    translated_doc.contract.zoho_id = doc.contract.zoho_id;
    translated_doc.contract.finished_soon = doc.contract.finished_soon;
    translated_doc.contract.fully_paid = doc.contract.fully_paid;
    translated_doc.contract.finished = doc.contract.finished;
    translated_doc.contract.hub = doc.contract.hub;

    if (doc.contract.customer){
      translated_doc.client = doc.contract.customer;
    }
    if (doc.contract.credit_info){
      translated_doc.credit_info = doc.contract.credit_info;
    }
    if (doc.contract.activations_summary){
      translated_doc.activations_summary = doc.contract.activations_summary;
    }
    translated_doc.first_activation_date = doc.contract.first_activation_date;
  }
  translated_doc.cheat_score = doc.cheat_score;
  translated_doc.kit = {};
  translated_doc.confirmed_expiration_timestamp = null;
  translated_doc.avg_power_7days = {};
  translated_doc.bms_label = null;
  translated_doc.sc_label = null;
  translated_doc.sc_serial = null;
  translated_doc.last_communication_timestamp = null;

  if (doc.kit) {

    translated_doc.kit = doc.kit;
   
    //Since the event to produce confirmed expiration date is not available yet, we're using this field.
    if (doc.kit.activation_end_date)
      translated_doc.confirmed_expiration_timestamp = iso2timestamp(doc.kit.activation_end_date);
    
    if (doc.kit.bms) {

      translated_doc.avg_power_7days = doc.kit.bms.avg_power_7days;
      translated_doc.bms_label = doc.kit.bms.serial_number;

      if (doc.kit.bms.smartcard) {
        translated_doc.sc_label = doc.kit.bms.smartcard.serial_number.slice(-3);
        translated_doc.sc_serial = doc.kit.bms.smartcard.serial_number;
        translated_doc.last_communication_timestamp = doc.kit.bms.smartcard.last_communication_timestamp;
      }

    }

  }

  translated_doc.days_on_active_period = 1;
  translated_doc.active_days = 1;
  
  if (translated_doc.confirmed_expiration_timestamp && translated_doc.first_activation_date) {

    if (Date.now() < translated_doc.confirmed_expiration_timestamp)
      translated_doc.days_on_active_period = Math.floor((Date.now() - translated_doc.first_activation_date) / 86400000);
    else
      translated_doc.days_on_active_period = Math.floor((translated_doc.confirmed_expiration_timestamp - translated_doc.first_activation_date) / 86400000);

  }

  if (doc.contract && doc.contract.activations_summary && doc.contract.activations_summary.active_days)
    translated_doc.active_days = doc.contract.activations_summary.active_days;

  translated_doc.flags = [];

  return translated_doc;

};

const iso2timestamp = (iso_date) => {
  const native_date = new Date(iso_date);
  const ts_date = native_date.getTime();
  return ts_date;
};

export { installationUtils };
