import React from "react";
import queryString from "query-string";
import { withRouter, matchPath } from "react-router-dom";

import { PouchdbContext, CountryContext, UserContext, RepositoryContext } from "../";

import { filter, sort } from "utils/filter";
import boxProperties, { defaultProperty } from "data/installations/properties";

const InstallationsContext = React.createContext();

export default InstallationsContext;

const addLastClientCall = lastClientCalls => installation => {
  const lastClientCall = lastClientCalls[installation.client.zoho_id];
  return {
    ...installation,
    client: {
      ...installation.client,
      last_call: lastClientCall ? lastClientCall.date_called : null,
    },
  };
};

const migrateClientCallback = callback_date => {
  if (callback_date && !isNaN(callback_date)) {
    return new Date(callback_date).toISOString().substr(0, 10);
  }
  return callback_date;
};

const addClientCallback = clientCallbacks => installation => {
  const clientCallback = clientCallbacks[installation.client.zoho_id];
  return {
    ...installation,
    client: {
      ...installation.client,
      callback_date: clientCallback ? migrateClientCallback(clientCallback.callback_date) : null,
    },
  };
};

const boxFilter = filter(boxProperties, defaultProperty);

const boxSort = sort(boxProperties);

// const boxCountryFilter = country => installation =>
//   boxProperties.country.operator.equals(
//     country.label,
//     boxProperties.country.getValue(installation)
//   );

// const countryFilter = filterCountry => installations =>
//   filterCountry ? installations.filter(boxCountryFilter(filterCountry)) : installations;

const installationMatch = ({ pathname }) =>
  matchPath(pathname, {
    path: "/:country?/",
    exact: true,
  }) ||
  matchPath(pathname, {
    path: "/:country?/map",
    exact: true,
  });

const typeToCollection = type => {
  switch (type) {
    case "chat_message":
      return "messages";
    case "client_chat":
      return "clientChats";
    case "chat_subscription":
      return "chatSubscriptions";
    case "client_note":
      return "notes";
    case "last_client_call":
      return "lastClientCalls";
    case "client_callback":
      return "clientCallbacks";
    case "installation":
      return "installations";
    case "flag":
      return "flags";
    default:
      return null;
  }
};

const reduceBy = (array, lambda) => {
  const res = {};
  for (const elt of array) {
    res[lambda(elt)] = elt;
  }
  return res;
};

const getContractCodeFromFlagDoc = flagDoc => {
  const contractCode = flagDoc._id.split(":")[2];
  return contractCode;
};

const getFlagNameFromFlagDoc = flagDoc => {
  const flagName = flagDoc._id.split(":")[3];
  return flagName;
};

const reduceFlagsByContractCode = flags => {
  const res = {};
  for (const flagDoc of flags) {
    const contractCode = getContractCodeFromFlagDoc(flagDoc);
    if (!res[contractCode]) {
      res[contractCode] = [];
    }
    res[contractCode].push(flagDoc);
  }
  return res;
};

const addFlags = flagsByContractCode => installation => {
  const flagDocs = flagsByContractCode[installation.contract.code] || [];
  return {
    ...installation,
    flags: flagDocs.reduce(
      (flags, flagDoc) => ({
        ...flags,
        [getFlagNameFromFlagDoc(flagDoc)]: true,
      }),
      {}
    ),
  };
};

const getDerivedStateFromDocs = (docs, state) => {

  const { filterCountry, sortProperty, order, filterInput } = state;

  const collections = {
    messages: [],
    clientChats: [],
    chatSubscriptions: [],
    notes: [],
    lastClientCalls: [],
    clientCallbacks: [],
    installations: [],
  };

  // Object.values(docs).forEach(doc => {
  //   if (!doc || doc._deleted || !doc.type) return;
  //   const collection = collections[typeToCollection(doc.type)];
  //   if (collection) {
  //     collection.push(doc);
  //   }
  // });

  // collections.lastClientCalls = reduceBy(collections.lastClientCalls, _ => _.client_id);

  // collections.clientCallbacks = reduceBy(collections.clientCallbacks, _ => _.client_id);

  // collections.flags = reduceFlagsByContractCode(collections.flags);

  // collections.installations = collections.installations
  //   .map(addLastClientCall(collections.lastClientCalls))
  //   .map(addClientCallback(collections.clientCallbacks))
  //   .map(addFlags(collections.flags));

  // const installationsById = reduceBy(collections.installations, _ => _.installation_id);

  // const localizedInstallations = countryFilter(filterCountry)(collections.installations);

  // const filteredInstallations = localizedInstallations.filter(boxFilter(filterInput));

  // const sortedInstallations = filteredInstallations.sort(boxSort(sortProperty, order));

  // const installations = sortedInstallations;
  const installations = docs;
  
  return {
    ...collections,
    // installationsById,
    // localizedInstallations,
    // sortedInstallations,
    filteredInstallations: installations,
    installations
  };

};

const getDerivedStateFromLocation = (location, filterCountry, state) => {

  const searchObject = queryString.parse(location.search);
  const sortProperty = searchObject.sort || state.sortProperty;
  const order = searchObject.order || state.order;
  const filterInput = searchObject.search || "";

  if (filterCountry !== state.filterCountry) {
    return {
      order,
      filterInput,
      sortProperty,
      filterCountry
    }; 
  }

  if (filterCountry !== state.filterCountry) {
    // const localizedInstallations = countryFilter(filterCountry)(state.installations);
    // const sortedInstallations = localizedInstallations.sort(boxSort(sortProperty, order));
    // const filteredInstallations = sortedInstallations.filter(boxFilter(filterInput));
    return {
      order,
      filterInput,
      sortProperty,
      filterCountry,
      // localizedInstallations,
      // sortedInstallations,
      // filteredInstallations,
    };
  } else if (sortProperty !== state.sortProperty) {

    let filteredInstallations = []

    if (state.filteredInstallations.length > 0) {
      filteredInstallations = state.filteredInstallations
    }

    // const sortedInstallations = state.localizedInstallations.sort(boxSort(sortProperty, order));
    // const filteredInstallations = sortedInstallations.filter(boxFilter(filterInput));
    const columns =
      state.columns.indexOf(sortProperty) > -1 ? state.columns : [...state.columns, sortProperty];

    return {
      order,
      columns,
      filterInput,
      sortProperty,
      // sortedInstallations,
      // filteredInstallations,
    };

  } else if (order !== state.order) {

    // const sortedInstallations = state.sortedInstallations.slice().reverse();
    const filteredInstallations = state.filteredInstallations;
    return {
      order,
      filterInput,
      // sortedInstallations,
      // filteredInstallations: state.filteredInstallations,
    };
  } else if (filterInput !== state.filterInput) {

    const filteredInstallations = state.sortedInstallations.filter(boxFilter(filterInput));
    return {
      filterInput,
      // filteredInstallations,
    };
  }
};

class Provider extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      order: "asc",
      sortProperty: null,
      columns: props.preferedColumns,
      filterInput: "",
      filterCountry: null,
      messages: [],
      clientChats: [],
      chatSubscriptions: [],
      lastClientCalls: {},
      clientCallbacks: {},
      notes: [],
      selectedInstallation: {},
      installationsById: {},
      localizedInstallations: [],
      sortedInstallations: [],
      filteredInstallations: props.repo.installations,
      installationsMap: [],
      lastProps: {},
      setColumns: this.setColumns,
      loadInstallationsMap: this.loadInstallationsMap,
      loading: props.repo.loading,
      initialLoad: false
    };

  }

  static getDerivedStateFromProps = (props, state) => {

    const { location, country } = props;

    const { lastProps } = state;

    const searchObject = queryString.parse(location.search);
    const sortProperty = searchObject.sort || state.sortProperty;
    const order = searchObject.order || state.order;
    const search = searchObject.search || state.filterInput;

    let newState = {
      ...state,
      lastProps: props,
    };

    const filters = [];

    if (search)
      filters.push(search);

    if (country && country.label)
      filters.push(`country:${country.label}`);

    const searchObjectSearchBool = searchObject.search === undefined ? false : true
    const newStateFilterInputBool = newState.filterInput === undefined ? false : true

    if (!searchObject.search && searchObjectSearchBool !== newStateFilterInputBool) {

        newState = {
          ...newState,
          filterInput: undefined
        }

        if (country && country.label)
          props.repo.applyFilter([`country:${country.label}`], sortProperty, order, state.columns);
        else
          props.repo.applyFilter(null, sortProperty, order, state.columns);

    } else if (search !== newState.filterInput || sortProperty !== newState.sortProperty || order !== newState.order) {

      props.repo.applyFilter(filters, sortProperty, order, state.columns);

    } else if (searchObject.search && newState.filterInput.length > 0 && searchObject.search !== newState.filterInput) {
      
      let countryFilter = null;

      if (country && country.label)
        countryFilter = [`country:${country.label}`];
      
      props.repo.applyFilter(countryFilter, sortProperty, order, state.columns);

    } else if ((props.country ? props.country.code : null) !== (newState.country ? newState.country.code : null)) {
      
      props.repo.applyFilter(filters, sortProperty, order, state.columns);

      newState = {
        ...newState,
        country: props.country
      }

    } else if (!newState.initialLoad) {

      props.repo.applyFilter(filters, sortProperty, order, state.columns);

      newState = {
        ...newState,
        initialLoad: true
      }

    }

    if (lastProps.repo && props.repo.installations !== lastProps.repo.installations) {
      newState = {
        ...newState,
        filteredInstallations: props.repo.installations
      }
    }

    if (lastProps.repo && props.repo.selectedInstallation !== lastProps.repo.selectedInstallation) {
      newState = {
        ...newState,
        selectedInstallation: props.repo.selectedInstallation
      }
    }

    if (props.location !== lastProps.location && installationMatch(props.location)) {
      //location changed, equivalent to @@router/LOCATION_CHANGE
      const { location, country } = props;
      newState = {
        ...newState,
        ...getDerivedStateFromLocation(location, country, newState),
      };
    }

    return newState;

  };

  setColumns = columns => {
    this.setState({ columns });
    this.props.setColumns(columns);
  };

  loadInstallationsMap = async (country) => {

    const installationsMap = await this.props.repo.loadInstallationsMap(country);

    this.setState({installationsMap: installationsMap});

  }

  render() {

    return (
      <InstallationsContext.Provider value={this.state}>
        {this.props.children}
      </InstallationsContext.Provider>
    );
  }

}

Provider = withRouter(Provider);

const ProviderWrapper = props => (
  <UserContext.Consumer>
    {({ preferences, setPreference }) => (
      <PouchdbContext.Consumer>
        {({ docs }) => (
          <CountryContext.Consumer>
            {country => (
              <RepositoryContext.Consumer>
                {repo => (
                  <Provider
                    {...props}
                    preferedColumns={preferences.preferedColumns}
                    setColumns={setPreference("preferedColumns")}
                    docs={docs}
                    country={country}
                    repo={repo}
                  />
                )}
              </RepositoryContext.Consumer>
            )}
          </CountryContext.Consumer>
        )}
      </PouchdbContext.Consumer>
    )}
  </UserContext.Consumer>
);

export { ProviderWrapper as Provider };
