import React, { Fragment } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ShareIcon from "@material-ui/icons/Share";
import LockIcon from "@material-ui/icons/Lock";
import LogoutIcon from "mdi-material-ui/Logout";
import { FormattedMessage as T } from "react-intl";

import { StatusBadge } from "components";
import { PouchdbContext, UserContext, SWContext } from "contexts";
import { MuiShellRoutesContext as RoutesContext } from "components";

const getUserName = user => {
  return user ? user.metadata.displayed_name : "";
}

const share = () => {
  if (navigator.share) {
    navigator.share({
      title: document.title,
      url: window.location.href,
    });
  }
};

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    color: "inherit",
  },
  name: {
    flex: 1,
    textAlign: "right",
    color: "inherit",
  },
};

class AppBarMenu extends React.PureComponent {
  state = {
    anchorEl: null,
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  signout = async () => {
    const {
      clearSession,
      getLogoutUrl,
      pouchdbSignout,
      pushUnsubscribe,
      reloadAllOtherClients,
    } = this.props;
    this.handleClose();
    await Promise.all([pouchdbSignout(), pushUnsubscribe()]);
    clearSession();
    reloadAllOtherClients();
    window && window.open(getLogoutUrl(), "_self");
  };

  render() {
    const {
      location,
      user,
      classes,
      getLoginUrl,
      pouchAuth,
      rwSyncing,
      roSyncing,
      currentRoute,
    } = this.props;
    const { anchorEl } = this.state;

    return (
      <Fragment>
        <Typography variant="subtitle1" title={getUserName(user)} noWrap className={classes.name}>
          {getUserName(user)}
        </Typography>
        <span>
          {pouchAuth ? (
            <React.Fragment>
              <IconButton
                aria-owns={anchorEl ? "simple-menu" : null}
                aria-haspopup="true"
                aria-label="appbar-menu"
                onClick={this.handleClick}
                className={classes.icon}
              >
                <StatusBadge rwSyncing={rwSyncing} roSyncing={roSyncing}>
                  <MoreVertIcon />
                </StatusBadge>
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem button onClick={this.signout} aria-label="signout">
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary={<T id="signout" />} />
                </MenuItem>
                {currentRoute && navigator.share && currentRoute.sharable && (
                  <MenuItem button onClick={share} aria-label="share">
                    <ListItemIcon>
                      <ShareIcon />
                    </ListItemIcon>
                    <ListItemText primary={<T id="share" />} />
                  </MenuItem>
                )}
              </Menu>
            </React.Fragment>
          ) : (
            <IconButton
              aria-label="signin"
              href={getLoginUrl(location.pathname)}
              className={classes.icon}
            >
              <StatusBadge rwSyncing={rwSyncing} roSyncing={roSyncing}>
                <LockIcon />
              </StatusBadge>
            </IconButton>
          )}
        </span>
      </Fragment>
    );
  }
}

const AppBarMenuWrapper = props => (
  <UserContext.Consumer>
    {({ user, getLoginUrl, getLogoutUrl, clearSession }) => (
      <SWContext.Consumer>
        {({ unsubscribe, reloadAllOtherClients }) => (
          <PouchdbContext.Consumer>
            {({ auth, rwSyncing, roSyncing, signout }) => (
              <RoutesContext.Consumer>
                {({ currentRoute }) => (
                  <AppBarMenu
                    {...props}
                    user={user}
                    getLoginUrl={getLoginUrl}
                    getLogoutUrl={getLogoutUrl}
                    clearSession={clearSession}
                    pushUnsubscribe={unsubscribe}
                    reloadAllOtherClients={reloadAllOtherClients}
                    pouchAuth={auth}
                    rwSyncing={rwSyncing}
                    roSyncing={roSyncing}
                    pouchdbSignout={signout}
                    currentRoute={currentRoute}
                  />
                )}
              </RoutesContext.Consumer>
            )}
          </PouchdbContext.Consumer>
        )}
      </SWContext.Consumer>
    )}
  </UserContext.Consumer>
);

export default compose(withStyles(styles), withRouter)(AppBarMenuWrapper);
