import uuidv4 from "uuid/v4";

export default class LastClientCall {

  constructor () {

    this._id = `urn.qotto.last_client_call:${uuidv4()}`;
    this.type = 'last_client_call';
    this.client_id = '';
    this.date_called = 0;

  }

  pattern = () => ([
    'client_id',
    'date_called'
  ])

  setClientId = (value) => {
    this.client_id = value;
  }

  setDateCalled = (value) => {
    this.date_called = value;
  }

  get = () => ({
    '_id': this._id,
    'type': this.type,
    'client_id': this.client_id,
    'date_called': this.date_called
  })

}