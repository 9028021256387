import React, { PureComponent, Fragment } from "react";
import { FormattedMessage as T } from "react-intl";
import Button from "@material-ui/core/Button";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { boxType } from "types";
import { getConfigValue } from "utils/envConfig";

const RECEIPT_API_ENDPOINT = getConfigValue('REACT_APP_RECEIPT_API_ENDPOINT');

class Receipt extends PureComponent {
  render() {
    const { box } = this.props;

    return (
      <Fragment>
        <Button
          color="primary"
          href={RECEIPT_API_ENDPOINT + "/" + box.contract.code}
          target="_blank"
        >
          <ReceiptIcon />
          <T id="paymentSummary" />
        </Button>
      </Fragment>
    );
  }
}

Receipt.propTypes = {
  box: boxType.isRequired,
};

export default Receipt;
