import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
import * as conf from "contexts/Pouchdb/config";
import { authenticate } from "contexts/Pouchdb/auth.js";
import { getConfigValue } from "utils/envConfig";

PouchDB.plugin(PouchDBFind);

const LOCAL_DB_NAME = getConfigValue('REACT_APP_COUCHDB_DB_NAME');
const REMOTE_SERVER = getConfigValue('REACT_APP_COUCHDB_SERVER');
const BACKEND_API_ENDPOINT = getConfigValue('REACT_APP_BACKEND_API_ENDPOINT');
let REMOTE_DB_PATH = REMOTE_SERVER + LOCAL_DB_NAME;

let getCouchDBCredentialsFromMock = null;

const local = () =>
  new PouchDB(LOCAL_DB_NAME, conf.localDatabaseOpts);

const safeFetch = (...args) =>
  fetch(...args).then(response => {
    const { ok, status } = response;
    if (!ok) {
      throw Error(`Request failed with status ${status}`);
    }
    return response;
  });

const fetchCouchDBCredentials = () =>
  safeFetch(BACKEND_API_ENDPOINT + "/couchdb-credentials", {
    credentials: "include",
  })
    .then(response => response.json())
    .catch(() => null);

const getCouchDBCredentials = async () => {

  const credentials = await fetchCouchDBCredentials();

  if (credentials) {
    localStorage.setItem('couchdb-username', credentials.username);
    localStorage.setItem('couchdb-password', credentials.password);
  }

  return credentials;

};

const remote = async () => {

  let NAME = localStorage.getItem('couchdb-username');
  let PASSWORD = localStorage.getItem('couchdb-password');

  try {
    getCouchDBCredentialsFromMock = require('couchdb.json');
    NAME = getCouchDBCredentialsFromMock.username;
    PASSWORD = getCouchDBCredentialsFromMock.password;
  } catch (e) {}

  if (!NAME || !PASSWORD) {
    await authenticate();
    await getCouchDBCredentials();
    NAME = localStorage.getItem('couchdb-username');
    PASSWORD = localStorage.getItem('couchdb-password');
  }

  if (!NAME || !PASSWORD)
    throw new Error("DATABASE_ACCESS_ERROR");

  return new PouchDB(REMOTE_DB_PATH.replace("://", `://${NAME}:${PASSWORD}@`), conf.remoteDatabaseOpts);

};

const pouchDB = {
  createLocalInstance: local,
  createRemoteInstance: remote
};

export { pouchDB };
