import uuidv4 from "uuid/v4";

export default class ClientCallback {

  constructor () {

    this._id = `urn.qotto.client_callback:${uuidv4()}`;
    this.type = 'client_callback';
    this.client_id = '';
    this.callback_date = '';

  }

  pattern = () => ([
    'client_id',
    'callback_date'
  ])

  setClientId = (value) => {
    this.client_id = value;
  }

  setCallbackDate = (value) => {
    this.callback_date = value;
  }

  get = () => ({
    _id: this._id,
    type: this.type,
    client_id: this.client_id,
    callback_date: this.callback_date
  })

}