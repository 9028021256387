import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ClientChat, LastClientCall, ClientCallback } from "components";
import { boxType } from "types";

const styles = {
  progressWrapper: {
    textAlign: "center",
  },
  progress: {
    margin: "100px 0",
  },
};

const CommentsTab = ({ classes, loading, box, refreshComments }) => (
  <div>
    {loading ? (
      <div className={classes.progressWrapper}>
        <CircularProgress className={classes.progress} />
      </div>
    ) : (
      <>
        <LastClientCall client={box.client} box={box} />
        <ClientCallback client={box.client} box={box} />
        <ClientChat client={box.client} box={box} refreshComments={refreshComments} />
      </>
    )}
  </div>
);

CommentsTab.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(CommentsTab);
